import { ClassApi } from 'api/class.api';
import { MessagesApi } from 'api/messages.api';
import { UserApi } from 'api/user.api';
import { AutocompleteInput } from 'common/components/inputs/AutocompleteInput';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import dayjs from 'dayjs';
import {
  convertToHTML,
  getStringValuesOfEnum,
  isObjectValid,
} from 'library/helpers/helper';
import { CollectionClass } from 'library/types/Class';
import {
  AutocompleteOption,
  CollectionApiResponse,
  SelectOption,
} from 'library/types/Common';
import {
  BulkRecipientsTypeExtended,
  BulkRecipientsTypeLimited,
  MessagePayload,
  SendMessageType,
} from 'library/types/Message';
import { CollectionUser, UserRoles } from 'library/types/User';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const SendMessageModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const params = useParams();
  const navigate = useNavigate();

  const { sendMessage, sendBulkMessages } = MessagesApi();
  const { getClass } = ClassApi();
  const { getUser } = UserApi();

  const { sendMessageType } = params as {
    sendMessageType: SendMessageType;
  };
  const userContext = useContext(UserContext);
  const account = userContext.state.account;

  const [users, setUsers] = useState<AutocompleteOption[]>([]);
  const [autocompleteInputValues, setAutocompleteInputValues] = useState<{
    recipient: string;
  }>({
    recipient: '',
  });

  const [classes, setClasses] = useState<SelectOption[]>([]);
  const [selectedClass, setSelectedClass] = useState<number | string>('');

  const [message, setMessage] = useState<{
    messageBody: string;
    subject: string;
    recipient: AutocompleteOption | null;
    bulkRecipient: BulkRecipientsTypeExtended | BulkRecipientsTypeLimited;
  }>({
    messageBody: '',
    subject: '',
    bulkRecipient: [
      UserRoles.ROLE_DIRIGINTE,
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_PROFESOR,
    ].includes(userContext.state.account!.selectedView)
      ? BulkRecipientsTypeLimited.CLASA_ELEVI
      : BulkRecipientsTypeExtended.ALL_USERS,
    recipient: null,
  });

  useEffect(() => {
    if (
      ![SendMessageType.Bulk, SendMessageType.Single].includes(
        sendMessageType
      ) ||
      ![
        UserRoles.ROLE_DIRECTOR,
        UserRoles.ROLE_SCHOOL_ADMIN,
        UserRoles.ROLE_DIRIGINTE,
        UserRoles.ROLE_INVATATOR,
        UserRoles.ROLE_PROFESOR,
      ].includes(userContext.state.account!.selectedView)
    )
      return navigate('/home');

    (async () => {
      if (sendMessageType === SendMessageType.Bulk) {
        const classes =
          (await getClass()) as CollectionApiResponse<CollectionClass>;
        return setClasses(
          classes['hydra:member'].map((e) => ({
            value: e.id,
            label: e.codClasa,
          }))
        );
      } else {
        const users =
          (await getUser()) as CollectionApiResponse<CollectionUser>;
        setUsers(
          users['hydra:member'].map((e) => ({
            nume: e.nume + ' ' + e.prenume,
            id: e.id,
          }))
        );
      }
    })();
  }, []);

  const isSentToClass = () => {
    if (
      [
        BulkRecipientsTypeLimited.CLASA_ELEVI,
        BulkRecipientsTypeLimited.CLASA_PARINTI,
        BulkRecipientsTypeExtended.CLASA_PROFESORI,
      ].includes(message.bulkRecipient)
    )
      return true;

    return false;
  };

  const sendMessageAction = async () => {
    const {
      bulkRecipient: bulkRecepient,
      messageBody,
      subject,
      recipient,
    } = message;

    if (
      (sendMessageType === SendMessageType.Bulk &&
        !isObjectValid({
          bulkRecepient,
          messageBody,
          subject,
        })) ||
      (sendMessageType === SendMessageType.Single &&
        !isObjectValid({
          recipient,
          messageBody,
          subject,
        }))
    ) {
      return toast.error(tModules('message.sendMessageValidationError'));
    }

    const endOfMessageText = `message-details|sender-id:${
      account!.user.id
    }|sender-name:${
      account!.user.nume + ' ' + account!.user.prenume
    }|date:${dayjs()}|end-message-line`;

    const messagePayload: MessagePayload =
      sendMessageType === SendMessageType.Bulk
        ? {
            receptori: bulkRecepient,
            clasa: isSentToClass() ? '/api/clase/' + selectedClass : null,
            corpMesaj: convertToHTML(messageBody) + endOfMessageText,
            subiect: subject,
          }
        : {
            receptor: '/api/users/' + recipient?.id,
            subiect: subject,
            corpMesaj: convertToHTML(messageBody) + endOfMessageText,
          };

    sendMessageType === SendMessageType.Bulk
      ? await sendBulkMessages(messagePayload)
      : await sendMessage(messagePayload);
    navigate('/messages/outbox');
  };

  return (
    <div className="page-container">
      <div className="flex max-h-full max-w-full w-full h-full justify-center items-center">
        <section
          style={{ maxWidth: '80%' }}
          className="p-9 w-3/5 flex flex-col bg-white rounded-3xl shadow-lg rounded-3xl"
        >
          {sendMessageType === SendMessageType.Single && (
            <AutocompleteInput
              autocompleteProps={{
                options: users,
                value: message.recipient,
                onChange: (
                  event: any,
                  newValue: {
                    nume: string;
                    id: number;
                  } | null
                ) => {
                  setMessage((prevValue) => {
                    return {
                      ...prevValue,
                      recipient: newValue ? newValue : null,
                    };
                  });
                },
                inputValue: autocompleteInputValues.recipient,
                onInputChange: (event, newInputValue) => {
                  setAutocompleteInputValues((prevValue) => {
                    return {
                      recipient: newInputValue,
                    };
                  });
                },
              }}
              textFieldProps={{
                fullWidth: true,
                label: tModules('message.to'),
              }}
            />
          )}
          {sendMessageType === SendMessageType.Bulk && (
            <RegularSelect
              options={
                [
                  UserRoles.ROLE_DIRIGINTE,
                  UserRoles.ROLE_INVATATOR,
                  UserRoles.ROLE_PROFESOR,
                ].includes(userContext.state.account!.selectedView)
                  ? getStringValuesOfEnum(BulkRecipientsTypeLimited).map(
                      (e) => ({
                        value: e as string,
                        label: e as string,
                      })
                    )
                  : [
                      ...getStringValuesOfEnum(BulkRecipientsTypeExtended),
                      ...getStringValuesOfEnum(BulkRecipientsTypeLimited),
                    ].map((e) => ({
                      value: e as string,
                      label: e as string,
                    }))
              }
              onChange={(e) => {
                setMessage((mess) => ({
                  ...mess,
                  bulkRecipient: e,
                }));
              }}
              selectProps={{
                label: tModules('message.to'),
                value: message.bulkRecipient,
              }}
            />
          )}
          {sendMessageType === SendMessageType.Bulk && (
            <RegularSelect
              options={classes}
              onChange={setSelectedClass}
              selectProps={{
                disabled:
                  message.bulkRecipient && isSentToClass() ? false : true,
                label: tModules('message.class'),
                value: selectedClass,
              }}
            />
          )}
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('message.subject'),
              value: message?.subject,
              onChange: (e) =>
                setMessage((mess) => ({
                  ...mess,
                  subject: e.target.value,
                })),
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              rows: 7,
              multiline: true,
              label: tModules('message.body'),
              value: message?.messageBody,
              onChange: (e) =>
                setMessage((mess) => ({
                  ...mess,
                  messageBody: e.target.value,
                })),
            }}
          />
          <ButtonComp
            onButtonClick={sendMessageAction}
            buttonText={tCommon('inputs.sendButton')}
            buttonProps={{
              variant: 'contained',
            }}
            tailwindContainerClasses="flex justify-end"
          />
        </section>
      </div>
    </div>
  );
};
