import { Typography } from '@mui/material';
import { AverageApi } from 'api/average.api';
import { SubjectApi } from 'api/subject.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { Calificative } from 'library/types/Average';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const ManageCorigentaModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as {
    classId: number;
    isPrimaryGrade: boolean;
    codClasa: string;
  };

  const { classId, isPrimaryGrade, codClasa } = state || {};

  const { getSubjectsForClass } = SubjectApi();
  const { getStudentsCorigenta, setAbsenceCorigenta, setGradeCorigenta } =
    AverageApi();

  const [studentsBySubject, setStudentsBySubject] = useState<{
    [subjectName: string]: Array<{
      averageId: number;
      numeElev: string;
      medieIncheiata: boolean;
      medieMaterie: string;
      notaCorigenta: string;
    }>;
  }>();
  const [noStudents, setNoStudents] = useState<boolean | null>(null);
  const [disableButtonsForStudents, setDisableButtonsForStudents] = useState<
    number[]
  >([]);

  useEffect(() => {
    console.log('loooooooog,', classId, isPrimaryGrade, codClasa);
    if (
      isPrimaryGrade === undefined ||
      classId === undefined ||
      codClasa === undefined
    )
      return navigate('/');

    let noStudents = true;
    (async () => {
      const subjectConfigs = await getSubjectsForClass(classId);
      console.log(subjectConfigs);
      for (const subjectConfig of subjectConfigs) {
        console.log(subjectConfig);
        const students = await getStudentsCorigenta(subjectConfig.id);
        if (students.length) noStudents = false;
        setStudentsBySubject((prev) => {
          return {
            ...(prev ? prev : {}),
            [subjectConfig.tipMaterie as string]: students.map((e) => ({
              averageId: parseInt(e['@medie'].split('/').pop()!),
              numeElev: e.numeElev,
              medieIncheiata: e.medieIncheiata,
              medieMaterie: e.medieMaterie,
              notaCorigenta: '',
            })),
          };
        });
      }
      setNoStudents(noStudents);
    })();
  }, []);

  const setAbsenceCorigentaAction = async (averageId: number) => {
    await setAbsenceCorigenta(averageId);

    setDisableButtonsForStudents([...disableButtonsForStudents, averageId]);
  };

  const setGradeOrCalificativCorigentaAction = async (
    averageId: number,
    gradeOrCalificativ: number | string
  ) => {
    await setGradeCorigenta(averageId, gradeOrCalificativ, isPrimaryGrade);

    setDisableButtonsForStudents([...disableButtonsForStudents, averageId]);
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '750px',
          }}
          className="flex flex-col"
        >
          <div className="flex justify-center">
            <Typography variant="h4">Clasa: {codClasa}</Typography>
          </div>
          <div className="p-4">
            {studentsBySubject &&
              !noStudents &&
              Object.entries(studentsBySubject).map(([subject, students]) => (
                <div key={subject} className="mb-6">
                  <hr className="mt-3 mb-5 h-0.5 border-t-0 bg-neutral-300 dark:bg-white/10" />
                  <div className="flex justify-center mb-3">
                    <Typography variant="h5">{subject}</Typography>
                  </div>
                  <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
                    <thead>
                      <tr className="bg-gray-800 text-white uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left">Name</th>
                        <th className="py-3 px-6 text-left">Final Grade</th>
                        <th className="py-3 px-6 text-center">Average</th>
                        <th className="py-3 px-6 text-center">Actiuni</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-700">
                      {students.map((student) => (
                        <tr
                          key={student.averageId}
                          className="border-b border-gray-200 hover:bg-gray-100"
                        >
                          <td className="py-3 px-6 text-left">
                            {student.numeElev}
                          </td>
                          <td className="py-3 px-6 text-center">
                            {student.medieIncheiata ? 'Yes' : 'No'}
                          </td>
                          <td className="py-3 px-6 text-center">
                            {student.medieMaterie}
                          </td>
                          <td className="py-3 px-6 text-left">
                            <div className="flex flex-col justify-center">
                              <ButtonComp
                                onButtonClick={() => {
                                  setAbsenceCorigentaAction(student.averageId);
                                }}
                                buttonText={tCommon(
                                  'inputs.setAbsenceCorigenta'
                                )}
                                buttonProps={{
                                  variant: 'contained',
                                  fullWidth: true,
                                  disabled: disableButtonsForStudents.includes(
                                    student.averageId
                                  ),
                                }}
                                tailwindContainerClasses="flex justify-center"
                              />
                              <div className="flex gap-2">
                                {/* <RegularInput
                                  noOptionalOrMandatory={true}
                                  textFieldProps={{
                                    value: student.notaCorigenta,
                                    label: tModules(
                                      isPrimaryGrade
                                        ? 'average.calificativCorigenta'
                                        : 'average.gradeCorigenta'
                                    ),
                                    onChange: (e) =>
                                      setStudentsBySubject((prev) => {
                                        return {
                                          ...prev,
                                          [subject]: prev![subject].map((s) => {
                                            if (
                                              s.averageId === student.averageId
                                            ) {
                                              return {
                                                ...s,
                                                notaCorigenta: e.target.value,
                                              };
                                            }
                                            return s;
                                          }),
                                        };
                                      }),
                                  }}
                                ></RegularInput> */}
                                <div className="w-full">
                                  <RegularSelect
                                    noOptionalOrMandatory={true}
                                    options={(isPrimaryGrade
                                      ? [
                                          Calificative.Excelent,
                                          Calificative.FoarteBine,
                                          Calificative.Bine,
                                          Calificative.Suficient,
                                          Calificative.Insuficient,
                                        ]
                                      : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                                    ).map((e) => ({
                                      value: e,
                                      label: e.toString(),
                                    }))}
                                    onChange={(e) => {
                                      setStudentsBySubject((prev) => {
                                        return {
                                          ...prev,
                                          [subject]: prev![subject].map((s) => {
                                            if (
                                              s.averageId === student.averageId
                                            ) {
                                              return {
                                                ...s,
                                                notaCorigenta: e,
                                              };
                                            }
                                            return s;
                                          }),
                                        };
                                      });
                                    }}
                                    selectProps={{
                                      disabled:
                                        disableButtonsForStudents.includes(
                                          student.averageId
                                        ),
                                      fullWidth: true,
                                      label: tModules(
                                        isPrimaryGrade
                                          ? 'average.calificativCorigenta'
                                          : 'average.gradeCorigenta'
                                      ),
                                      value: student.notaCorigenta,
                                      autoWidth: true,
                                    }}
                                  />
                                </div>
                                <ButtonComp
                                  onButtonClick={() => {
                                    setGradeOrCalificativCorigentaAction(
                                      student.averageId,
                                      student.notaCorigenta
                                    );
                                  }}
                                  buttonText={tCommon('inputs.sendButton')}
                                  buttonProps={{
                                    fullWidth: true,
                                    variant: 'contained',
                                    disabled:
                                      disableButtonsForStudents.includes(
                                        student.averageId
                                      ),
                                  }}
                                  tailwindContainerClasses="flex justify-center"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            {noStudents && (
              <div className="mt-10">
                <Typography variant="h3" className="text-center">
                  În această clasă niciun student nu se află în stare de
                  corigență.
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
