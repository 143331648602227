import { Typography } from '@mui/material';
import { ClassApi } from 'api/class.api';
import { SubjectApi } from 'api/subject.api';
import { TestApi } from 'api/test.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { TimePickerInput } from 'common/components/inputs/TimePickerInput';
import { UserContext } from 'context/UserContext';
import { UtilityContext } from 'context/UtilityContext';
import dayjs from 'dayjs';
import { extractIdFromIri } from 'library/helpers/helper';
import { CollectionApiResponse, IRI, SelectOption } from 'library/types/Common';
import { SubjectType } from 'library/types/Subject';
import {
  StudentTest,
  Test,
  TestPayload,
  TestQuestionAnswer,
  TestQuestionAnswerPayload,
  TestQuestionToTest,
} from 'library/types/Test';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const AnswerTestModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const utilityContext = useContext(UtilityContext);
  const userContext = useContext(UserContext);

  const state = location.state as {
    testId: number;
  };

  const { testId } = state || {};

  const [testData, setTestData] = useState<{
    titlu: string;
    clasa: string;
    materie: string;
    intrebari: Array<{
      '@id': IRI;
      intrebare: string;
      raspunsuri: {
        1: string;
        2: string;
        3?: string;
        4?: string;
        5?: string;
        6?: string;
      };
      raspunsAles: string;
    }>;
  }>();

  const { answerTestQuestion, submitTestQuestionAnswers, getProfessorsTest } =
    TestApi();

  useEffect(() => {
    if (!testId) navigate('/home');

    (async () => {
      const test = (await getProfessorsTest({
        id: testId,
      })) as StudentTest;

      if (!test) return;
      setTestData({
        titlu: test.titlu,
        clasa: test.clasa.codClasa,
        materie: (test.materie.tipMaterie as SubjectType).tipMaterie,
        intrebari: test.intrebariTest.map((e) => ({
          '@id': e['@id'],
          intrebare: e.intrebare.intrebare,
          raspunsuri: {
            1: e.intrebare.raspuns1,
            2: e.intrebare.raspuns2,
            ...(e.intrebare.raspuns3 ? { 3: e.intrebare.raspuns3 } : undefined),
            ...(e.intrebare.raspuns4 ? { 4: e.intrebare.raspuns4 } : undefined),
            ...(e.intrebare.raspuns5 ? { 5: e.intrebare.raspuns5 } : undefined),
            ...(e.intrebare.raspuns6 ? { 6: e.intrebare.raspuns6 } : undefined),
          },
          raspunsAles: '',
        })),
      });
    })();
  }, []);

  const submitFormAction = async () => {
    if (!testId || !testData || !userContext.state.account) return;

    for (let i = 0; i < testData.intrebari.length; i++) {
      const payload: TestQuestionAnswerPayload = {
        intrebareTest: testData.intrebari[i]['@id'],
        raspunsElev: parseInt(testData.intrebari[i].raspunsAles),
        user: '/api/users/' + userContext.state.account?.user.id,
      };

      const response = await answerTestQuestion(payload);
    }

    const response = await submitTestQuestionAnswers(testId);
    navigate('/table-test/students');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <Typography variant="h4"></Typography>

          <div className="flex flex-col gap-4">
            {testData?.intrebari.map((e, index) => {
              return (
                <div className="flex flex-col">
                  <Typography variant="h5">{e.intrebare}</Typography>
                  <RegularSelect
                    options={Object.entries(e.raspunsuri).map((el) => ({
                      label: el[1],
                      value: el[0],
                    }))}
                    onChange={(e: string) => {
                      setTestData((prev) => {
                        if (!prev) return undefined;
                        const newTestData = { ...prev };
                        newTestData.intrebari[index].raspunsAles = e;
                        return newTestData;
                      });
                    }}
                    selectProps={{
                      label: tModules('test.answer'),
                      value: e.raspunsAles,
                      fullWidth: true,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <ButtonComp
          onButtonClick={submitFormAction}
          buttonText={tCommon('inputs.sendTestAnswers')}
          buttonProps={{
            variant: 'contained',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
