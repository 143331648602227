import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import { Catalog, CatalogPayload } from 'library/types/Catalog';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import { TimeTable } from 'library/types/TimeTable';

export function CatalogApi() {
  const { apiHandler } = JWTInterceptor();

  const getCalificative = async (): Promise<Array<string>> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/ratings`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const getCatalog = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Catalog | CollectionApiResponse<Catalog>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/catalog/${id}` : `${serverUri()}/catalog/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createCatalogEntry = async (
    catalogInfo: CatalogPayload
  ): Promise<Catalog> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/catalog`,
      data: catalogInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'The catalog entry was successfully created!',
    });

    return response.data;
  };

  const motiveazaAbsenta = async (catalogId: number): Promise<TimeTable> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/catalog/${catalogId}`,
      data: {
        motivat: true,
      },
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'Absenta a fost motivata cu succes!',
    });

    return response.data;
  };

  return {
    getCalificative,
    motiveazaAbsenta,
    createCatalogEntry,
    getCatalog,
  };
}
