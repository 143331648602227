import { SchoolYearApi } from 'api/schoolYear.api';
import { TermAkaModulApi } from 'api/termAkaModul.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { DatePickerInput } from 'common/components/inputs/DatePickerInput';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import dayjs from 'dayjs';
import { isObjectValid } from 'library/helpers/helper';
import { CollectionApiResponse } from 'library/types/Common';
import { CollectionSchoolYear } from 'library/types/SchoolYear';
import {
  TermAkaModul,
  TermAkaModulData,
  TermAkaModulPayload,
} from 'library/types/TermAkaModul';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const TermAkaModulModule = (): JSX.Element => {
  const navigate = useNavigate();
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const { createTermAkaModul, getTermAkaModul, editTermAkaModul } =
    TermAkaModulApi();
  const { getSchoolYear } = SchoolYearApi();

  const location = useLocation();
  const state = location.state as {
    editId: number;
  };
  const { editId } = state || {};

  const [termAkaModulData, setTermAkaModulData] = useState<TermAkaModulData>({
    anScolar: null,
    ordine: '',
    dataInceput: dayjs(),
    dataSfarsit: dayjs().add(1, 'day'),
    activ: false,
    inceputEvaluareModul: dayjs(),
    sfarsitEvaluareModul: dayjs(),
    tipModul: '',
  });

  const [schoolYears, setSchoolYears] = useState<Array<CollectionSchoolYear>>(
    []
  );

  useEffect(() => {
    (async () => {
      const schoolYears =
        (await getSchoolYear()) as CollectionApiResponse<CollectionSchoolYear>;

      let termAkaModul: TermAkaModul | undefined;
      if (editId) {
        termAkaModul = (await getTermAkaModul({ id: editId })) as TermAkaModul;
        setTermAkaModulData({
          anScolar: termAkaModul.anScolar,
          ordine: termAkaModul.ordine.toString(),
          dataInceput: dayjs(termAkaModul.dataInceput),
          dataSfarsit: dayjs(termAkaModul.dataSfarsit),
          activ: termAkaModul.activ,
          inceputEvaluareModul: dayjs(termAkaModul.inceputEvaluareModul),
          sfarsitEvaluareModul: dayjs(termAkaModul.sfarsitEvaluareModul),
          tipModul: termAkaModul.tipModul,
        });
      }

      setSchoolYears(schoolYears['hydra:member']);
    })();
  }, []);

  const isActionValid = () => {
    if (!termAkaModulData) return false;

    const { dataInceput, dataSfarsit } = termAkaModulData;

    if (!isObjectValid(termAkaModulData)) {
      toast.error(
        tModules('schoolAdmin.termAkaModul.submitTermAkaModulValidationError')
      );
      return false;
    }

    if (dataInceput!.valueOf() > dataSfarsit!.valueOf()) {
      toast.error(
        tModules('schoolAdmin.termAkaModul.endDateAfterStartDateError')
      );
      return false;
    }
    return true;
  };

  const termAkaModulAction = async (id?: number) => {
    if (!isActionValid()) return;

    const {
      anScolar,
      ordine,
      dataInceput,
      dataSfarsit,
      sfarsitEvaluareModul,
      inceputEvaluareModul,
      activ,
      tipModul,
    } = termAkaModulData!;

    const payloadObject: TermAkaModulPayload = {
      anScolar: `/api/aniScolari/${anScolar!.id}`,
      ordine: parseInt(ordine as string),
      activ,
      dataSfarsit: dataSfarsit!,
      dataInceput: dataInceput!,
      sfarsitEvaluareModul: sfarsitEvaluareModul!,
      inceputEvaluareModul: inceputEvaluareModul!,
      tipModul: tipModul!,
    };
    editId
      ? await editTermAkaModul(editId, payloadObject)
      : await createTermAkaModul(payloadObject);
    navigate('/terms');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.termAkaModul.order'),
              value: termAkaModulData?.ordine,
              onChange: (e) => {
                if (
                  e.target.value === '' ||
                  /^[-+]?[1-9]\d*$/.test(e.target.value)
                )
                  setTermAkaModulData((prev) => ({
                    ...prev,
                    ordine: e.target.value,
                  }));
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('schoolAdmin.termAkaModul.termAkaModulStartDate'),
              value: termAkaModulData.dataInceput,
              onChange: (e) => {
                setTermAkaModulData((prev) => ({
                  ...prev,
                  dataInceput: e,
                }));
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              minDate: termAkaModulData.dataInceput?.add(1, 'day') || undefined,
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('schoolAdmin.termAkaModul.termAkaModulEndDate'),
              value: termAkaModulData.dataSfarsit,
              onChange: (e) => {
                setTermAkaModulData((prev) => ({
                  ...prev,
                  dataSfarsit: e,
                }));
              },
            }}
          />
          <RegularSelect
            options={schoolYears.map((e) => ({
              value: e.id,
              label: e.anInceput.toString(),
            }))}
            onChange={(e) => {
              setTermAkaModulData((prev) => ({
                ...prev,
                anScolar: schoolYears.find((el) => el.id === e)!,
              }));
            }}
            selectProps={{
              label: tModules('schoolAdmin.termAkaModul.schoolYear'),
              value: termAkaModulData.anScolar?.id ?? '',
            }}
          />
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('schoolAdmin.termAkaModul.evaluationStartDate'),
              value: termAkaModulData.inceputEvaluareModul,
              onChange: (e) => {
                setTermAkaModulData((prev) => ({
                  ...prev,
                  inceputEvaluareModul: e,
                }));
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('schoolAdmin.termAkaModul.evaluationEndDate'),
              value: termAkaModulData.sfarsitEvaluareModul,
              onChange: (e) => {
                setTermAkaModulData((prev) => ({
                  ...prev,
                  sfarsitEvaluareModul: e,
                }));
              },
            }}
          />
          <RegularSelect
            options={[
              {
                label: tCommon('basic.yes'),
                value: true,
              },
              {
                label: tCommon('basic.no'),
                value: false,
              },
            ]}
            onChange={(e) => {
              setTermAkaModulData((prevValue) => {
                return {
                  ...prevValue,
                  activ: e,
                };
              });
            }}
            selectProps={{
              label: tModules('test.activeSelect'),
              value: termAkaModulData.activ,
              fullWidth: true,
            }}
          />
          <RegularSelect
            options={[
              {
                label: 'Predare',
                value: 'Predare',
              },
              {
                label: 'Corigenta',
                value: 'Corigență',
              },
            ]}
            onChange={(e) => {
              setTermAkaModulData((prevValue) => {
                return {
                  ...prevValue,
                  tipModul: e,
                };
              });
            }}
            selectProps={{
              label: 'Tip Modul',
              value: termAkaModulData.tipModul,
              fullWidth: true,
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={termAkaModulAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Login',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
