export enum ViewMessagesType {
  Inbox = 'inbox',
  Outbox = 'outbox',
}

export enum SendMessageType {
  Bulk = 'bulk',
  Single = 'single',
}

export enum BulkRecipientsTypeExtended {
  ALL_USERS = 'Toti utilizatorii',
  PROFESORI = 'Profesori scoala',
  DIRIGINTI = 'Diriginti scoala',
  INVATATORI = 'Invatatori scoala',
  PARINTI = 'Parinti scoala',
  ELEVI = 'Elevi scoala',
  CLASA_PROFESORI = 'Profesori clasa',
}

export enum BulkRecipientsTypeLimited {
  CLASA_ELEVI = 'Elevi clasa',
  CLASA_PARINTI = 'Parinti clasa',
}

export interface Message {
  id: number;
  emitator: {
    '@id': string;
    nume: string;
    prenume: string;
  };
  receptor: {
    '@id': string;
    nume: string;
    prenume: string;
  };
  subiect: string;
  fisier: string | null;
  corpMesaj: string;
  etichetaTimp: string;
  mesajCitit: boolean;
  thread: string;
  bulk: string;
  receptori: string;
  clasa: null | string;
}

export interface MessagePayload {
  receptor?: string;
  subiect: string;
  corpMesaj: string;
  fisier?: string;
  receptori?: string;
  clasa?: string | null;
  thread?: string;
}
