import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import { GenericResources, RequiredResources } from 'library/types/Common';

export function CommonApi() {
  const { apiHandler } = JWTInterceptor();

  const getResources = async (requiredResources: {
    [key in RequiredResources]?: 1 | 0;
  }): Promise<Partial<GenericResources>> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/resurse`,
      axiosConfig: {
        params: {
          resurse: requiredResources,
        },
      },
    });

    return response.data;
  };

  return {
    getResources,
  };
}
