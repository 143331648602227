import Button, { ButtonProps } from '@mui/material/Button';

interface ButtonCompProps {
  buttonProps?: Partial<ButtonProps>;
  buttonText: string;
  tailwindContainerClasses?: string;
  onButtonClick: () => void;
}

export const ButtonComp = (props: ButtonCompProps): JSX.Element => {
  const { buttonProps, buttonText, tailwindContainerClasses, onButtonClick } =
    props;

  return (
    <div className={`py-3 ${tailwindContainerClasses || ''}`}>
      <Button
        onClick={() => {
          onButtonClick();
        }}
        {...buttonProps}
      >
        {buttonText}
      </Button>
    </div>
  );
};
