import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import { AcademicRecord } from 'library/types/AcademicRecords';
import { apiCallConfigTypes } from 'library/types/Common';

export function AcademicRecordsApi() {
  const { apiHandler } = JWTInterceptor();

  const getAcademicRecord = async (id: number): Promise<AcademicRecord> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/users/foaie-matricola/${id}`,
      axiosConfig: apiCallConfigTypes.get,
    });

    return response.data;
  };

  return {
    getAcademicRecord,
  };
}
