import dayjs, { Dayjs } from 'dayjs';
import {
  AcademicRecordType,
  MateriiEntry,
  MediiEntry,
  MediiGeneraleEntry,
  SchoolSituationJSON,
} from 'library/types/AcademicRecords';
import {
  CadruDisciplina,
  CatalogReportObjectResponse,
  MateriiInfo,
  StudentCatalog,
} from 'library/types/Reports';
import { Days, DaySchedule, WeeklySchedule } from 'library/types/TimeTable';
import { toast } from 'react-toastify';
import _ from 'lodash';

export const getViewTypeString = (route: string) => {
  let string = '';

  switch (true) {
    case !!route.includes('create'):
      return 'create';
    case !!route.includes('edit'):
      return 'edit';
    case !!route.includes('view'):
      return 'view';
    default:
      return 'default';
  }
};

export const getStringValuesOfEnum = (enumType: any) => {
  return Object.values(enumType).filter((v) => {
    return isNaN(Number(v));
  });
};

export const extractIdFromIri = (iri: string) => {
  const id = parseInt(iri.replace(/\D+/g, ''));
  return id;
};

export const convertToHTML = (inputText: string) => {
  let output = inputText.replace(/ /g, '&nbsp;');
  output = output.replace(/\n/g, '<br>');
  return output;
};

export const isObjectValid = (
  json: { [key: string]: any },
  exceptionFields?: string[]
) => {
  for (const property in json) {
    if (
      exceptionFields &&
      !exceptionFields.includes(property) &&
      !json[property] &&
      json[property] !== false &&
      json[property] !== 0 &&
      property !== 'id'
    ) {
      console.log('property', property);
      console.log('json[property]', json[property]);
      return false;
    }
  }

  return true;
};

export const isNumberWithOptionalLengthTest = (
  text: string,
  length?: number
) => {
  if (text !== '' && !/^[0-9]*$/.test(text)) return false;

  if (length && text.toString().length > length) return false;

  return true;
};

export const getUniqueValuesForKey = (
  arrayOfObjects: Array<any>,
  key: string
) => {
  const values = arrayOfObjects.map((obj) => obj[key]);
  const uniqueValues = [...new Set(values)];
  return uniqueValues;
};

export const changeDayStringToDayNumber = (day: Days): number => {
  switch (day) {
    case Days.Luni:
      return 1;
    case Days.Marti:
      return 2;
    case Days.Miercuri:
      return 3;
    case Days.Joi:
      return 4;
    case Days.Vineri:
      return 5;
    case Days.Sambata:
      return 6;
    default:
      throw new Error(`Invalid day: ${day}`);
  }
};

export const changeDayNumberToDayString = (dayNumber: number): string => {
  switch (dayNumber) {
    case 1:
      return Days.Luni;
    case 2:
      return Days.Marti;
    case 3:
      return Days.Miercuri;
    case 4:
      return Days.Joi;
    case 5:
      return Days.Vineri;
    case 6:
      return Days.Sambata;
    default:
      throw new Error(`Invalid day number: ${dayNumber}`);
  }
};

export const parseHourToTimezone = (hour: string, dayToConsider?: Dayjs) => {
  let parsedDate = dayjs(hour).utc();

  const currentDate = (dayToConsider ? dayjs(dayToConsider) : dayjs()).tz(
    'Europe/Bucharest'
  );

  parsedDate = parsedDate.set('year', currentDate.year());
  parsedDate = parsedDate.set('month', currentDate.month());
  parsedDate = parsedDate.set('day', currentDate.day());

  return parsedDate.tz().format('HH:mm');
};

const isTruthy = (value: any): boolean => {
  if (typeof value === 'object' && value !== null) {
    return Object.values(value).every(isTruthy);
  }
  return Boolean(value);
};

export const checkFields = (
  t: (key: string) => string,
  errorKey: string,
  obj: { [key: string]: any },
  exceptions: string[] = []
): boolean => {
  const isValid = (obj: { [key: string]: any }, path: string = ''): boolean => {
    return Object.keys(obj).every((key) => {
      const value = obj[key];
      const currentPath = path ? `${path}.${key}` : key;

      if (exceptions.includes(currentPath)) {
        return true;
      }

      if (typeof value === 'object' && value !== null) {
        return isValid(value, currentPath);
      }

      return isTruthy(value);
    });
  };

  if (!isValid(obj)) {
    const message = t(errorKey);
    toast.error(message);
    return false;
  } else {
    return true;
  }
};

const romanNumerals: { [key: string]: number } = {
  M: 1000,
  CM: 900,
  D: 500,
  CD: 400,
  C: 100,
  XC: 90,
  L: 50,
  XL: 40,
  X: 10,
  IX: 9,
  V: 5,
  IV: 4,
  I: 1,
};

export const convertToRoman = (num: number): string => {
  let str = '';

  for (const key of Object.keys(romanNumerals)) {
    const value = romanNumerals[key];
    const q = Math.floor(num / value);
    num -= q * value;
    str += key.repeat(q);
  }

  return str;
};

const generateMockData = (): MateriiEntry[] => {
  const mockData: MateriiEntry[] = [];
  const possibleYears = [1, 2, 3, 4, 5, 6, 7, 8];
  const possibleGrades = ['5.00', '6.00', '7.00', '8.00', '9.00', '10.00'];
  const possibleCalificative = [
    'Insuficient',
    'Suficient',
    'Bine',
    'Foarte Bine',
    'Excelent',
  ];

  for (let i = 0; i < 20; i++) {
    const medii: MediiEntry = {};
    const yearsCount = Math.floor(Math.random() * possibleYears.length) + 1; // Number of years to include (1 to 4)
    const selectedYears = [...possibleYears]
      .sort(() => 0.5 - Math.random())
      .slice(0, yearsCount);

    selectedYears.forEach((year) => {
      let grade = '';
      if (year > 4) {
        grade =
          possibleGrades[Math.floor(Math.random() * possibleGrades.length)];
      } else {
        grade =
          possibleCalificative[
            Math.floor(Math.random() * possibleCalificative.length)
          ];
      }
      medii[year.toString()] = grade;
    });

    mockData.push({
      numeMaterie: `Materie ${i + 1}`,
      medii,
    });
  }

  return mockData;
};

const truncateToTwoDecimals = (num: number): string => {
  const numStr = num.toString();
  const decimalIndex = numStr.indexOf('.');
  if (decimalIndex === -1) return `${numStr}.00`;
  return numStr.slice(0, decimalIndex + 3).padEnd(decimalIndex + 3, '0');
};

const calculateMediiGenerale = (
  mediiMaterii: MateriiEntry[]
): MediiGeneraleEntry[] => {
  const yearTotals: {
    [year: string]: { total: number; count: number; options?: string[] };
  } = {};

  mediiMaterii.forEach((materie) => {
    for (const year in materie.medii) {
      if (parseInt(year) < 5) {
        const options = [];
        for (let [key, value] of Object.entries(materie.medii)) {
          if (parseInt(key) < 5) {
            options.push(value);
          }
        }
        yearTotals[year] = {
          total: 0,
          count: 0,
          options,
        };
      } else {
        const gradeNumber = parseFloat(materie.medii[year]);
        if (!yearTotals[year]) {
          yearTotals[year] = { total: 0, count: 0 };
        }
        yearTotals[year].total += gradeNumber;
        yearTotals[year].count += 1;
      }
    }
  });

  const mediiGenerale = Object.entries(yearTotals).map(
    ([year, { total, count, options }]) => {
      if (parseInt(year) < 5) {
        return {
          anMaterie: year,
          medie: options
            ? options[Math.floor(Math.random() * options!.length)]
            : '',
        };
      }

      const average = total / count;
      return {
        anMaterie: year,
        medie: truncateToTwoDecimals(average),
      };
    }
  );

  return mediiGenerale;
};

export const generateAcademicRecordsMockData = () => {
  const mockMediiMaterii = generateMockData();
  const mediiGenerale = calculateMediiGenerale(mockMediiMaterii);

  const result = {
    mediiMaterii: mockMediiMaterii,
    mediiGenerale: mediiGenerale,
  };

  return result;
};

export const adjustWeeklyScheduleTimes = (
  weeklySchedule: WeeklySchedule
): WeeklySchedule => {
  const adjustedSchedule: WeeklySchedule = {} as WeeklySchedule;

  Object.keys(weeklySchedule).forEach((dayKey) => {
    const day = dayKey as Days;
    const daySchedule = weeklySchedule[day];

    const adjustedDaySchedule: DaySchedule = {};

    Object.keys(daySchedule).forEach((timeSlotKey) => {
      const timeSlot = daySchedule[timeSlotKey];

      adjustedDaySchedule[timeSlotKey] = {
        ...timeSlot,
        oraStart: parseHourToTimezone(timeSlot.oraStart),
        oraSfarsit: parseHourToTimezone(timeSlot.oraSfarsit),
      };
    });

    adjustedSchedule[day] = adjustedDaySchedule;
  });

  return adjustedSchedule;
};

export const generateSchoolRecordMockData = (
  numEntries: number
): SchoolSituationJSON[] => {
  const schoolNames = [
    'Colegiul National Andrei Saguna Brasov',
    'Colegiul Naational Cantemir Voda Bucuresti',
    'Colegiul National Gheorghe Lazar Bucuresti',
    'Colegiul National Mihai Eminescu Botosani',
  ];
  const yearAndEducationFormats = [
    'Full-time',
    'Part-time',
    'Online',
    'Hybrid',
  ];
  const observations = [
    'Excellent performance',
    'Needs improvement',
    'Satisfactory',
    'Outstanding',
    'Average',
  ];

  function getRandomItem<T>(items: T[]): T {
    return items[Math.floor(Math.random() * items.length)];
  }

  function getRandomNumberString(min: number, max: number): string {
    return (Math.floor(Math.random() * (max - min + 1)) + min).toString();
  }

  const mockData = [];

  for (let i = 0; i < numEntries; i++) {
    mockData.push({
      schoolName: getRandomItem(schoolNames),
      volume: {
        noOfPages: getRandomNumberString(50, 500),
        matricolNumber: getRandomNumberString(1000, 9999),
        volumeNumber: getRandomNumberString(1, 20),
      },
      yearAndEducationFormat: getRandomItem(yearAndEducationFormats),
      schoolYear: getRandomNumberString(2010, 2023),
      observations: getRandomItem(observations),
    });
  }

  return mockData;
};

function parseSchoolYear(schoolYear: string): number {
  const match = schoolYear.match(/(\d{4})/);
  return match ? parseInt(match[0]) : 0;
}

// Comparator function for sorting
export function compareSchoolYears(
  a: SchoolSituationJSON,
  b: SchoolSituationJSON
): number {
  const yearA = parseSchoolYear(a.schoolYear);
  const yearB = parseSchoolYear(b.schoolYear);

  if (yearA < yearB) return -1;
  if (yearA > yearB) return 1;
  return 0;
}

export const numberOfSchoolYears = (type: AcademicRecordType) => {
  switch (type) {
    case AcademicRecordType['I - VIII']:
      return 8;

    case AcademicRecordType['IX - XII ( XIII )']:
      return 4;

    default:
      return 0;
  }
};

const randomDate = (start: string, end: string): string => {
  const startDate = new Date(start).getTime();
  const endDate = new Date(end).getTime();
  const randomDate = new Date(
    startDate + Math.random() * (endDate - startDate)
  );
  return randomDate.toISOString().split('T')[0];
};

const randomRomanianName = (
  type: 'full' | 'first' | 'last' = 'full'
): string => {
  const firstNames = [
    'Andrei',
    'Maria',
    'Ion',
    'Elena',
    'Mihai',
    'Ana',
    'Cristian',
    'Gabriel',
    'Ioana',
    'Radu',
  ];
  const lastNames = [
    'Popescu',
    'Ionescu',
    'Georgescu',
    'Dumitrescu',
    'Stan',
    'Preda',
    'Radu',
    'Marinescu',
    'Matei',
    'Toma',
  ];
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  if (type === 'first') return firstName;
  if (type === 'last') return lastName;
  return `${firstName} ${lastName}`;
};

const generateStudents = (
  numStudents: number,
  subjects: string[]
): StudentCatalog[] => {
  const students: StudentCatalog[] = [];
  for (let i = 0; i < numStudents; i++) {
    const numeComplet = randomRomanianName();
    const [prenume, nume] = numeComplet.split(' ');
    const materiiInfo: { [key: string]: MateriiInfo } = {};
    subjects.forEach((subject, index) => {
      materiiInfo[index] = {
        denumireMaterie: subject,
        medie: (Math.random() * 4 + 6).toFixed(2),
        medie_corigenta:
          Math.random() < 0.1 ? (Math.random() * 2 + 4).toFixed(2) : null,
        medie_finala: (Math.random() * 4 + 6).toFixed(2),
        note: Array.from({ length: Math.floor(Math.random() * 5) + 2 }, () => ({
          data: randomDate('2023-09-15', '2024-06-30'),
          nota: (Math.floor(Math.random() * 10) + 1).toString(),
        })),
        absente:
          Math.random() < 0.8
            ? Array.from({ length: Math.floor(Math.random() * 5) + 1 }, () =>
                randomDate('2023-09-15', '2024-06-30')
              )
            : [],
      };
    });
    students.push({
      volumRegistruMatricol: (Math.floor(Math.random() * 345) + 1).toString(),
      absente: Math.floor(Math.random() * 10),
      absenteNemotivate: Math.floor(Math.random() * 5),
      nrMatricol: `RM34/2/23${20 + i}`,
      paginaRegistruMatricol: (Math.floor(Math.random() * 30) + 1).toString(),
      numeComplet,
      dataNastere: randomDate('2004-01-01', '2005-12-31'),
      prenumeTata: randomRomanianName('first'),
      prenumeMama: randomRomanianName('first'),
      domiciliuSiNumarTelefon: `Strada ${randomRomanianName(
        'last'
      )} ${Math.floor(Math.random() * 100)}, (elev) 07${
        Math.floor(Math.random() * 9000000) + 1000000
      }`,
      initialaParinte: `${prenume[0]}.`,
      nume,
      prenume,
      situatie: Math.random() < 0.9 ? 'Promovat' : 'Corigent',
      medieGenerala: (Math.random() * 4 + 6).toFixed(2),
      mediePurtare: (Math.random() * 2 + 8).toFixed(2),
      materiiInfo,
    });
  }
  return students;
};

export const generateMockDataReportCatalog =
  (): CatalogReportObjectResponse => {
    const cadreSiDiscipline: CadruDisciplina[] = Array.from(
      { length: 17 },
      (_, i) => ({
        cadru: randomRomanianName(),
        disciplina: `Disciplina ${i + 1}`,
      })
    );

    const cadreSiDisciplineMedii: CadruDisciplina[] = cadreSiDiscipline.map(
      (item, i) => ({
        cadru: i < 12 ? item.cadru : randomRomanianName(),
        disciplina: item.disciplina,
      })
    );

    const subjects = [...cadreSiDiscipline.map((d) => d.disciplina), 'Purtare'];
    const secretariScoala = Array.from({ length: 4 }, () =>
      randomRomanianName()
    );

    return {
      schoolName: 'Liceul Teoretic Mihai Eminescu',
      schoolLocalitate: 'Cluj-Napoca',
      schoolJudet: 'Cluj',
      codClasa: '9D',
      anInceput: '2023-09-11T00:00:00+00:00',
      anSfarsit: '2024-08-31T00:00:00+00:00',
      anStudiu: 9,
      director: randomRomanianName(),
      cadruDidacticResponsabil: randomRomanianName(),
      cadreSiDiscipline,
      denumireMinister: 'Ministerul Educatiei',
      situatieElevi: {
        inscrisi: 29,
        veniti: Math.floor(Math.random() * 12) + 1,
        plecati: Math.floor(Math.random() * 12) + 1,
        existenti: 29,
        promovati: Math.floor(Math.random() * 12) + 1,
        corigentix1: Math.floor(Math.random() * 12) + 1,
        corigentix2: Math.floor(Math.random() * 12) + 1,
        repetenti: {
          count: Math.floor(Math.random() * 12) + 1,
          info: {
            1: Math.floor(Math.random() * 12) + 1,
            2: Math.floor(Math.random() * 12) + 1,
            3: Math.floor(Math.random() * 12) + 1,
            4: Math.floor(Math.random() * 12) + 1,
          },
        },
        amanati: {
          amanatix1: Math.floor(Math.random() * 12) + 1,
          amanatix2: Math.floor(Math.random() * 12) + 1,
          motive: {
            count: Math.floor(Math.random() * 12) + 1,
            info: {
              1: Math.floor(Math.random() * 12) + 1,
              2: Math.floor(Math.random() * 12) + 1,
              3: Math.floor(Math.random() * 12) + 1,
              4: Math.floor(Math.random() * 12) + 1,
            },
          },
        },
        absenteTotal: Math.floor(Math.random() * 12) + 1,
        absenteNemotivate: Math.floor(Math.random() * 12) + 1,
      },
      usersCatalog: generateStudents(29, subjects),
      cadreSiDisciplineMedii,
      secretariScoala,
      catalogInfo: null,
    };
  };

export function copyToClipboard(text: string): void {
  if (!document.hasFocus()) {
    alert('Please focus the document before copying text.');
    return;
  }

  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      console.log('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
    document.body.removeChild(textArea);
  }
}

export function omitDeep<T>(obj: T, propertiesToRemove: string[]): T {
  // If the object is not an object or array, return it directly
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // Deep clone the object
  const clonedObj = _.cloneDeep(obj);

  // Recursive function to omit properties
  function omitProperties(obj: any): any {
    if (Array.isArray(obj)) {
      // If the object is an array, process each item
      return obj.map((item) => omitProperties(item));
    } else if (typeof obj === 'object' && obj !== null) {
      // If the object is an object, process each key
      return Object.keys(obj).reduce((result, key) => {
        if (!propertiesToRemove.includes(key)) {
          result[key] = omitProperties(obj[key]);
        }
        return result;
      }, {} as any);
    }
    // Return other values directly
    return obj;
  }

  // Omit properties from the cloned object
  return omitProperties(clonedObj);
}

export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};
