import { UseAutocompleteProps } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface MyAutocompleteProps
  extends UseAutocompleteProps<
    {
      nume: string;
      id: number;
    },
    false,
    false,
    false
  > {
  clearIcon?: React.ReactNode;
}

interface AutocompleteInputProps {
  tailwindContainerClasses?: string;
  textFieldProps?: Partial<TextFieldProps>;
  autocompleteProps: MyAutocompleteProps;
  isOptional?: boolean;
}

export const AutocompleteInput = (
  props: AutocompleteInputProps
): JSX.Element => {
  const {
    tailwindContainerClasses,
    textFieldProps,
    autocompleteProps,
    isOptional,
  } = props;
  return (
    <div className={`py-3 ${tailwindContainerClasses || ''}`}>
      <Autocomplete
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.nume}
            </li>
          );
        }}
        sx={{
          '& .Mui-disabled': {
            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.85) !important',
            '&.MuiInputBase-input': {
              backgroundColor: 'rgba(0,0,0,0.05)',
            },
          },
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.nume}
        disablePortal
        id="combo-box-demo"
        {...autocompleteProps}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            label={
              textFieldProps?.label
                ? textFieldProps.label + (isOptional ? '(optional)' : '*')
                : ''
            }
          />
        )}
      />
    </div>
  );
};
