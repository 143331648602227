import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  IRI,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import { LessonDetailedTimetablePayload } from 'library/types/Lesson';
import { TimeSlot, TimeSlotPayload } from 'library/types/TimeSlot';
import {
  CollectionDetailedTimeTable,
  CollectionTimeTable,
  SwapTimeTablesPayload,
  TimeTable,
  TimeTablePayload,
  TimeslotsWithTimeTableData,
  WeeklySchedule,
} from 'library/types/TimeTable';

export function TimeTableApi() {
  const { apiHandler } = JWTInterceptor();

  const getTimeTable = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<
    CollectionTimeTable | CollectionApiResponse<CollectionTimeTable>
  > => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/orare/${id}` : `${serverUri()}/orare/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createTimeTable = async (
    timeTableInfo: TimeTablePayload
  ): Promise<TimeTable> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/orare`,
      data: timeTableInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'Time table was successfully created!',
    });

    return response.data;
  };

  const patchTimeTable = async (
    timeTableInfo: TimeTablePayload,
    orarId: number
  ): Promise<TimeTable> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/orare/${orarId}`,
      data: timeTableInfo,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'Time table was successfully edited!',
    });

    return response.data;
  };

  const editTimeTable = async (
    timeTableInfo: TimeTablePayload
  ): Promise<TimeSlot> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/orare`,
      data: timeTableInfo,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'Time table was edited successfully!',
    });

    return response.data;
  };

  const checkCatalogEntriesOnTimeTable = async (
    orarId: number
  ): Promise<{ hasEntries: boolean }> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/check-catalog-entries/${orarId}`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const getTimeslotsWithTimeTable = async (
    timeslotsWithTimeTableData?: TimeslotsWithTimeTableData
  ): Promise<WeeklySchedule> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/intervale-orare-zilnice`,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      data: timeslotsWithTimeTableData,
    });

    return response.data;
  };

  const swapTimeTables = async (
    swapTimeTablesPayload: SwapTimeTablesPayload
  ) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/swap-orare`,
      data: swapTimeTablesPayload,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'Time tables was successfully swapped!',
    });

    return response.data;
  };

  const generateAllDetailedTimeTable = async (schoolId: number) => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/orarDetaliat/generate_all/${schoolId}`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
      successMessage: 'Detailed time tables generated successfully!',
    });

    return response.data;
  };

  const deleteTimeTable = async (timeTableId: number) => {
    const response = await apiHandler({
      method: 'delete',
      url: `${serverUri()}/orare/${timeTableId}`,
      axiosConfig: {
        ...apiCallConfigTypes.delete,
      },
      successMessage: 'Time table successfully deleted',
    });

    return response.data;
  };

  const getDetailedTimeTable = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<
    | CollectionDetailedTimeTable
    | CollectionApiResponse<CollectionDetailedTimeTable>
  > => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/orarDetaliat/${id}`
        : `${serverUri()}/orarDetaliat/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const editDetailedTimeTable = async (
    detailedTimeTableId: number,
    detailedTimeTablePayload: LessonDetailedTimetablePayload
  ): Promise<TimeSlot> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/orarDetaliat/${detailedTimeTableId}`,
      data: detailedTimeTablePayload,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'Detailed time table was edited!',
    });

    return response.data;
  };

  return {
    getTimeTable,
    createTimeTable,
    getTimeslotsWithTimeTable,
    editTimeTable,
    checkCatalogEntriesOnTimeTable,
    swapTimeTables,
    generateAllDetailedTimeTable,
    patchTimeTable,
    deleteTimeTable,
    getDetailedTimeTable,
    editDetailedTimeTable,
  };
}
