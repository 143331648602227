import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import {
  StudentTest,
  Test,
  TestPayload,
  TestQuestion,
  TestQuestionAnswer,
  TestQuestionAnswerPayload,
  TestQuestionPayload,
  TestQuestionToTest,
  TestQuestionToTestPayload,
} from 'library/types/Test';

export function TestApi() {
  const { apiHandler } = JWTInterceptor();

  const getProfessorsTest = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Test | CollectionApiResponse<Test> | StudentTest> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/teste/${id}` : `${serverUri()}/teste/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getStudentsTest = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Test | CollectionApiResponse<Test> | StudentTest> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/teste-elev/${id}`
        : `${serverUri()}/teste-elev/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getTestQuestion = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<TestQuestion | CollectionApiResponse<TestQuestion>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/intrebari/${id}` : `${serverUri()}/intrebari/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getTestQuestionToTest = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<
    TestQuestionToTest | CollectionApiResponse<TestQuestionToTest>
  > => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/intrebare-test/${id}`
        : `${serverUri()}/intrebare-test/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getTestQuestionAnswer = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<
    TestQuestionAnswer | CollectionApiResponse<TestQuestionAnswer>
  > => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/raspuns-intrebare-test/${id}`
        : `${serverUri()}/raspuns-intrebare-test/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createTest = async (testInfo: TestPayload): Promise<TestPayload> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/teste`,
      data: testInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'The test entry was successfully created!',
    });

    return response.data;
  };

  const editTest = async (
    testId: number,
    testInfo: Partial<TestPayload>
  ): Promise<Test> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/teste/${testId}`,
      data: testInfo,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The test was successfully modified!',
    });

    return response.data;
  };

  const createTestQuestion = async (
    testQuestionInfo: TestQuestionPayload
  ): Promise<TestQuestion> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/intrebari`,
      data: testQuestionInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'The test question entry was successfully created!',
    });

    return response.data;
  };

  const editTestQuestion = async (
    testQuestionId: number,
    testQuestionInfo: Partial<TestQuestionPayload>
  ): Promise<TestQuestion> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/intrebari/${testQuestionId}`,
      data: testQuestionInfo,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The test was successfully modified!',
    });

    return response.data;
  };

  const createTestQuestionAnswer = async (
    testQuestionAnswerInfo: TestQuestionAnswer
  ): Promise<TestQuestionAnswer> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/raspuns-intrebare-test`,
      data: testQuestionAnswerInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'The test entry was successfully created!',
    });

    return response.data;
  };

  const createTestQuestionToTest = async (
    testQuestionToTestInfo: TestQuestionToTestPayload
  ): Promise<TestQuestionToTest> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/intrebare-test`,
      data: testQuestionToTestInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'The test entry was successfully created!',
    });

    return response.data;
  };

  const editTestQuestionToTest = async (
    testQuestionToTestId: number,
    testQuestionToTestInfo: Partial<TestQuestionToTestPayload>
  ): Promise<TestQuestionToTest> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/intrebare-test/${testQuestionToTestId}`,
      data: testQuestionToTestInfo,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The test was successfully modified!',
    });

    return response.data;
  };

  const deleteTestQuestionToTest = async (
    testQuestionToTestId: number
  ): Promise<TestQuestionToTest> => {
    const response = await apiHandler({
      method: 'delete',
      url: `${serverUri()}/intrebare-test/${testQuestionToTestId}`,
      axiosConfig: {
        ...apiCallConfigTypes.delete,
      },
      successMessage: 'Success!',
    });

    return response.data;
  };

  const answerTestQuestion = async (
    testQuestionAnswerInfo: TestQuestionAnswerPayload
  ) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/raspuns-intrebare-test`,
      data: testQuestionAnswerInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
    });

    return response.data;
  };

  const submitTestQuestionAnswers = async (testId: number | string) => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/teste/${testId}/submit-test-questions-answers`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
      successMessage: 'Ai trimis cu success raspunsurile testului!',
    });

    return response.data;
  };

  return {
    getProfessorsTest,
    getStudentsTest,
    getTestQuestion,
    getTestQuestionToTest,
    getTestQuestionAnswer,
    createTest,
    editTest,
    createTestQuestion,
    editTestQuestion,
    createTestQuestionAnswer,
    createTestQuestionToTest,
    editTestQuestionToTest,
    deleteTestQuestionToTest,
    answerTestQuestion,
    submitTestQuestionAnswers,
  };
}
