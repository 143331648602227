import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { icons } from 'resources/images';

// Define a type for language options
type LanguageOption = {
  code: string;
  name: string;
  flagUrl: string;
};

// Language options list
const languageOptions: LanguageOption[] = [
  { code: 'en', name: 'English', flagUrl: icons.flags.en },
  { code: 'ro', name: 'Română', flagUrl: icons.flags.ro },
];

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div>
      <button style={{ width: '250px' }} onClick={() => setIsOpen(!isOpen)}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={languageOptions.find((e) => e.code === i18n.language)?.flagUrl}
            alt="Current Language"
          />{' '}
        </div>
        {/* Placeholder path */}
      </button>
      {isOpen && (
        <div
          style={{
            display: 'flex',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            width: '100px',
            position: 'absolute',
            justifyContent: 'center',
            transform: 'translate(75px, 0)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100px',
              gap: 4,
              zIndex: '99999',
            }}
          >
            {languageOptions.map((option) => (
              <div
                key={option.code}
                onClick={() => changeLanguage(option.code)}
                style={{
                  padding: '0 10px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={option.flagUrl}
                  alt={option.name}
                  style={{
                    marginRight: '8px',
                    width: '20px',
                    cursor: 'pointer',
                  }}
                />
                {option.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
