import { publicServerUri, serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import { apiCallConfigTypes } from 'library/types/Common';
import {
  Message,
  MessagePayload,
  ViewMessagesType,
} from 'library/types/Message';

export function MessagesApi() {
  const { apiHandler } = JWTInterceptor();

  const getMessage = async (messageId: number): Promise<Message> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/mesaje/${messageId}`,
      data: {},
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const sendMessage = async (messageInfo: MessagePayload) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/mesaje`,
      data: messageInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'Mesaj trimis cu success!',
    });

    return response.data;
  };

  const sendBulkMessages = async (messageInfo: MessagePayload) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/mesaje_bulk`,
      data: messageInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
    });

    return response.data;
  };

  const markMessagesAsRead = async () => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/mesaje`,
      data: {},
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
    });

    return response.data;
  };

  const getAllMessages = async (type: ViewMessagesType) => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/mesaje_${type}`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  return {
    getMessage,
    sendMessage,
    sendBulkMessages,
    markMessagesAsRead,
    getAllMessages,
  };
}
