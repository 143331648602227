import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import { Catalog } from 'library/types/Catalog';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import {
  CheckedStudentHomeworkPayload,
  Homework,
  HomeworkPayload,
  StudentHomework,
  StudentHomeworkPayload,
} from 'library/types/Homework';

export function HomeworkApi() {
  const { apiHandler } = JWTInterceptor();

  const getHomework = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Homework | CollectionApiResponse<Homework>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/teme/${id}` : `${serverUri()}/teme/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createHomework = async (
    homeworkInfo: HomeworkPayload
  ): Promise<Homework> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/teme`,
      data: homeworkInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'The homework entry was successfully created!',
    });

    return response.data;
  };

  const editHomework = async (
    homeworkId: number,
    homeworkInfo: Partial<HomeworkPayload>
  ): Promise<Homework> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/teme/${homeworkId}`,
      data: homeworkInfo,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'Tema a fost modificata cu succes!',
    });

    return response.data;
  };

  const activateOrDeactivateHomework = async (
    homeworkId: number,
    newHomeworkStatus: boolean
  ) => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/teme/${homeworkId}`,
      data: {
        activ: newHomeworkStatus,
      },
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: newHomeworkStatus
        ? 'Tema a fost activata cu succes!'
        : 'Tema a fost dezactivata cu succes!',
    });

    return response.data;
  };

  const getStudentHomework = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Homework | CollectionApiResponse<Homework>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/teme_elev/${id}` : `${serverUri()}/teme_elev/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const submitStudentHomework = async (
    studentHomeworkInfo: StudentHomeworkPayload
  ): Promise<StudentHomework> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/teme_elev`,
      data: studentHomeworkInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'Ati raspuns la tema cu succes!',
    });

    return response.data;
  };

  const submitCheckedStudentHomework = async (
    checkedStudentHomeworkInfo: CheckedStudentHomeworkPayload
  ): Promise<StudentHomework> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/teme_elev`,
      data: checkedStudentHomeworkInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'Ati trimis corectura temei cu succes!',
    });

    return response.data;
  };

  return {
    getHomework,
    createHomework,
    editHomework,
    getStudentHomework,
    submitStudentHomework,
    submitCheckedStudentHomework,
    activateOrDeactivateHomework,
  };
}
