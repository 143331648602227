import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import { SchoolYear, SchoolYearPayload } from 'library/types/SchoolYear';
export function SchoolYearApi() {
  const { apiHandler } = JWTInterceptor();

  const getSchoolYear = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<SchoolYear | CollectionApiResponse<SchoolYear>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/aniScolari/${id}`
        : `${serverUri()}/aniScolari/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createSchoolYear = async (
    schoolYearInfo: SchoolYearPayload
  ): Promise<SchoolYear> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/aniScolari`,
      data: schoolYearInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Term was successfully created!',
    });

    return response.data;
  };

  const editSchoolYear = async (
    id: number,
    data: Partial<SchoolYearPayload>
  ): Promise<SchoolYear> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/aniScolari/${id}`,
      data: data,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The school year was successfully modified!',
    });

    return response.data;
  };

  return {
    getSchoolYear,
    createSchoolYear,
    editSchoolYear,
  };
}
