import { LessonApi } from 'api/lesson.api';
import { TimeTableApi } from 'api/timetable.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { UtilityContext } from 'context/UtilityContext';
import { CollectionApiResponse, IRI, SelectOption } from 'library/types/Common';
import { Lesson, LessonDetailedTimetablePayload } from 'library/types/Lesson';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const LessonDetailedTimetable = () => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const navigate = useNavigate();
  const location = useLocation();

  const utilityContext = useContext(UtilityContext);
  const userContext = useContext(UserContext);

  const state = location.state as {
    materie: {
      '@id': IRI;
      detaliiMaterie: string;
    };
    clasa: {
      '@id': IRI;
      id: number;
      codClasa: string;
      anStudiu: number;
    };
    detaliiTemporale: string;
    orarDetaliatId: number;
    saptamana: string;
  };

  const { materie, clasa, detaliiTemporale, orarDetaliatId, saptamana } =
    state || {};

  const [linkZoom, setLinkZoom] = useState<string>('');
  const [lessons, setLessons] = useState<Array<SelectOption>>([]);
  const [selectedLesson, setSelectedLesson] = useState<string>('');

  const { getLesson } = LessonApi();
  const { editDetailedTimeTable } = TimeTableApi();

  useEffect(() => {
    if (!materie || !clasa || !detaliiTemporale || !orarDetaliatId)
      navigate('/home');
    (async () => {
      const response = (await getLesson()) as CollectionApiResponse<Lesson>;
      setLessons(
        response['hydra:member'].map((e) => ({
          value: e['@id'],
          label: e.subiect,
        }))
      );
    })();
  }, []);

  const submitFormAction = async () => {
    if (!selectedLesson)
      return toast.error(tModules('lesson.allFieldsMustBeFilled'));
    const submitLessonDetailedTimetablePayload: LessonDetailedTimetablePayload =
      {
        lectie: selectedLesson as string,
        linkZoom,
      };

    await editDetailedTimeTable(
      orarDetaliatId,
      submitLessonDetailedTimetablePayload
    );

    navigate('/view-detailed-timetable', {
      state: {
        saptamana,
      },
    });
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          {state && state.clasa && (
            <div>
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('catalogue.class'),
                  disabled: true,
                  value: clasa.codClasa,
                }}
              />
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('catalogue.subject'),
                  disabled: true,
                  value: materie.detaliiMaterie,
                }}
              />
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('catalogue.timeDetails'),
                  disabled: true,
                  value: detaliiTemporale,
                }}
              />
              <RegularSelect
                options={lessons}
                onChange={(e) => {
                  console.log(e);
                  setSelectedLesson(e);
                }}
                selectProps={{
                  label: tModules('lesson.lesson'),
                  value: selectedLesson,
                  fullWidth: true,
                }}
              />
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  rows: 7,
                  multiline: true,
                  label: tModules('lesson.zoomLink'),
                  value: linkZoom,
                  onChange: (e) => {
                    setLinkZoom(e.target.value);
                  },
                }}
              />
            </div>
          )}
        </div>
        <ButtonComp
          onButtonClick={submitFormAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
