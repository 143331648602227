import { FisierApi } from 'api/fisier.api';
import { LessonApi } from 'api/lesson.api';
import { UserApi } from 'api/user.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import FileUploadComponent from 'common/components/inputs/FileInput';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { UtilityContext } from 'context/UtilityContext';
import { IFile, IRI, SelectOption } from 'library/types/Common';
import {
  Lesson,
  LessonPayload,
  ManageLessonModuleData,
} from 'library/types/Lesson';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ManageLessonModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const navigate = useNavigate();
  const location = useLocation();

  const userContext = useContext(UserContext);

  const state = location.state as {
    lessonId: number;
  };

  const { lessonId } = state || {};

  const { uploadFile } = FisierApi();
  const { createLesson, editLesson, getLesson } = LessonApi();
  const { getMe } = UserApi();

  const [lessonData, setLessonData] = useState<ManageLessonModuleData>({
    subiect: '',
    materie: '',
    oreDetaliate: [''],
    user: '',
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileEdit, setSelectedFileEdit] = useState<IFile | null>(null);

  const [subjects, setSubjects] = useState<Array<SelectOption>>([]);

  useEffect(() => {
    if (location.pathname.includes('edit') && !lessonId) navigate('/home');

    (async () => {
      const response = await getMe();

      setSubjects(
        response.data!.user.materii.map((e) => {
          return {
            value: `/api/materii/${e.id}`,
            label: e.tipMaterie.tipMaterie,
          };
        })
      );

      if (lessonId) {
        const lesson = (await getLesson({
          id: lessonId,
        })) as Lesson;

        setSelectedFileEdit(lesson.fisier);
        setLessonData({
          id: lesson.id,
          materie: lesson.materie['@id'],
          user: lesson.user['@id'],
          subiect: lesson.subiect,
          oreDetaliate: lesson.oreDetaliate,
        });
      }
    })();
  }, []);

  const submitFormAction = async () => {
    if (
      !lessonData ||
      !(selectedFile || selectedFileEdit) ||
      !lessonData.materie ||
      !lessonData.subiect
    )
      return toast.error(tModules('lesson.createEditLessonError'));

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      try {
        var uploadedFile = await uploadFile(formData);
      } catch (e) {
        console.error(e);
        toast.error(tModules('lesson.fileUploadError'));
        return;
      }
    }

    const lessonPayload: LessonPayload = {
      fisier: `/api/fisiere/${
        selectedFile ? uploadedFile.id : selectedFileEdit!.id
      }`,
      materie: lessonData.materie,
      subiect: lessonData.subiect,
      user: '/api/users/' + userContext.state.account!.user.id,
    };

    lessonId
      ? await editLesson(lessonId, lessonPayload)
      : await createLesson(lessonPayload);

    navigate('/table-lesson');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('lesson.title'),
              value: lessonData?.subiect,
              onChange: (e) => {
                setLessonData((prev) => {
                  return { ...prev, subiect: e.target.value };
                });
              },
            }}
          />
          <RegularSelect
            options={subjects}
            onChange={(e) => {
              setLessonData((prev) => ({
                ...prev,
                materie: e,
              }));
            }}
            selectProps={{
              label: tModules('lesson.subjectSelect'),
              value: lessonData.materie,
              fullWidth: true,
            }}
          />
          <FileUploadComponent
            buttonText={tModules('lesson.lessonFileUpload')}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
          />
          {!!lessonId && !selectedFile && (
            <div className="flex items-center">{selectedFileEdit?.urlPath}</div>
          )}
        </div>
        <ButtonComp
          onButtonClick={submitFormAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
