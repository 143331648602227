import { Typography } from '@mui/material';
import { ClassApi } from 'api/class.api';
import { EvaluationApi } from 'api/evaluation.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { UserContext } from 'context/UserContext';
import { CollectionApiResponse, IRI } from 'library/types/Common';
import {
  YearlyEvaluationQuestion,
  YearlyEvaluationResponsePayload,
} from 'library/types/Evaluation';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { icons } from 'resources/images';

export const YearlyEvaluationResponseModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const { getAvailableClasses, getStudentsOfClass } = ClassApi();
  const {
    sendYearlyEvaluationResponse,
    getYearlyEvaluationQuestionForProfessors,
    getSentYearlyEvaluationResponse,
  } = EvaluationApi();

  const [classes, setClasses] = useState<
    Array<{
      id: number;
      classCode: string;
    }>
  >([]);
  const [selectedClass, setSelectedClass] = useState<number | null>(null);
  const [students, setStudents] = useState<{
    [classId: string]: Array<{
      id: number;
      nume: string;
      prenume: string;
    }>;
  }>();
  const [selectedStudent, setSelectedStudent] = useState<number | null>();

  const [questions, setQuestions] = useState<{
    [iri: IRI]: {
      text: string;
      response: string;
    };
  }>();
  const [selectedQuestion, setSelectedQuestion] = useState<IRI>('');
  const [currentAnswers, setCurrentAnswers] = useState<{
    [userEvaluat: IRI]: {
      [iriIntrebare: IRI]: string;
    };
  }>({});

  useEffect(() => {
    (async () => {
      const classes = await getAvailableClasses();
      const evaluationQuestions = (
        (await getYearlyEvaluationQuestionForProfessors()) as CollectionApiResponse<YearlyEvaluationQuestion>
      )['hydra:member'];
      const evaluationCurrentResponses =
        await getSentYearlyEvaluationResponse();

      const currentAnswers: {
        [userEvaluat: IRI]: {
          [iriIntrebare: IRI]: string;
        };
      } = {};

      evaluationCurrentResponses
        .filter(
          (e) =>
            e.userRespondent ===
            '/api/users/' + userContext.state.account!.user.id
        )
        .forEach((e) => {
          currentAnswers[e.userEvaluat] = {
            ...currentAnswers[e.userEvaluat],
            [e.intrebareEvaluareAnuala]: e.raspuns,
          };
        });

      setCurrentAnswers(currentAnswers);

      const questionsObject: typeof questions = {};
      evaluationQuestions.forEach((e) => {
        questionsObject[e['@id']] = {
          text: e.intrebare,
          response: '',
        };
      });
      setQuestions(questionsObject);

      setClasses(
        classes
          .filter((e) => !Array.isArray(e))
          .map((e) => ({
            id: e.id,
            classCode: e.codClasa,
          }))
      );
    })();
  }, []);

  useEffect(() => {
    fetchStudentsOfClassAction();
  }, [selectedClass]);

  const fetchStudentsOfClassAction = async () => {
    if (!selectedClass) return;
    if (students && students[selectedClass.toString()]) return;

    const studentsOfClass = await getStudentsOfClass(selectedClass);

    setStudents((prevStudents) => {
      return prevStudents
        ? {
            ...prevStudents,
            [selectedClass.toString()]: studentsOfClass.map((e) => ({
              id: e.id,
              nume: e.nume,
              prenume: e.prenume,
            })),
          }
        : {
            [selectedClass.toString()]: studentsOfClass.map((e) => ({
              id: e.id,
              nume: e.nume,
              prenume: e.prenume,
            })),
          };
    });
  };

  const sendResponseAction = async () => {
    if (!selectedStudent || !questions) return;
    const payload: YearlyEvaluationResponsePayload = {
      intrebareEvaluareAnuala: selectedQuestion,
      userEvaluat: `/api/users/${selectedStudent}`,
      raspuns: questions[selectedQuestion].response,
    };
    await sendYearlyEvaluationResponse(payload);
    setCurrentAnswers((prev) => ({
      ...prev,
      ['/api/users/' + selectedStudent]: {
        ...prev['/api/users/' + selectedStudent],
        [selectedQuestion]: questions[selectedQuestion].response,
      },
    }));
  };

  const resetOnStudentChange = () => {
    if (!questions) return;
    const adjustedQuestions = { ...questions };
    Object.keys(adjustedQuestions).forEach((e) => {
      adjustedQuestions[e].response = '';
    });
    setQuestions(adjustedQuestions);
    setSelectedQuestion('');
  };

  const resetOnClassChange = () => {
    resetOnStudentChange();
    setSelectedStudent(null);
  };

  const onClassClickAction = (id: number) => {
    resetOnClassChange();
    if (selectedClass === id) return setSelectedClass(null);
    setSelectedClass(id);
  };

  const onStudentClickAction = async (id: number) => {
    resetOnStudentChange();
    if (selectedStudent === id) return setSelectedStudent(null);
    setSelectedStudent(id);
  };

  const onQuestionClickAction = (questionIri: string) => {
    if (selectedQuestion === questionIri) {
      return setSelectedQuestion('');
    }
    setSelectedQuestion(questionIri);
  };

  return (
    <div style={{ maxHeight: '100%' }} className="flex flex-col w-full">
      <div className="flex h-full w-full gap-2 justify-center">
        <div className="flex flex-col gap-2 w-36">
          <div className="w-36 h-8 flex justify-center items-center shadow-md background-primary">
            <Typography variant="footerBold">
              {tModules('evaluation.questions')}
            </Typography>
          </div>
          <div id="classes-container" className="">
            <div className="flex w-full flex-col gap-2 overflow-y-auto w-36 max-h-80">
              {classes.map((e, index) => {
                return (
                  <div
                    key={e.classCode + index}
                    onClick={() => onClassClickAction(e.id)}
                    className="flex items-center justify-between shadow-md p-3 cursor-pointer background-primary"
                  >
                    <div>
                      <Typography variant="h6">{e.classCode}</Typography>
                    </div>
                    <div>
                      <img
                        width={15}
                        src={icons.themes[userContext.state.theme].rightArrow}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {students && selectedClass && (
          <div className="flex flex-col gap-2 w-80">
            <div className="w-80 h-8 flex justify-center items-center shadow-md background-primary">
              <Typography variant="footerBold">
                {tModules('evaluation.students')}
              </Typography>
            </div>
            <div id="students-container" className="">
              <div className="flex w-full flex-col gap-2 overflow-y-auto w-80 max-h-80">
                {students[selectedClass.toString()] &&
                  students[selectedClass.toString()].map((e) => {
                    return (
                      <div
                        key={e.id + e.nume + e.prenume}
                        onClick={() => onStudentClickAction(e.id)}
                        className=" flex items-center justify-between shadow-md p-3 cursor-pointer background-primary"
                      >
                        <div>
                          <Typography variant="h6">
                            {e.nume + ' ' + e.prenume}
                          </Typography>
                        </div>
                        <div>
                          <img
                            width={15}
                            src={
                              icons.themes[userContext.state.theme].rightArrow
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        {students && selectedClass && selectedStudent && questions && (
          <div className="flex flex-col gap-2 flex-1">
            <div className="h-8 flex justify-center items-center shadow-md background-primary">
              <Typography variant="footerBold">
                {tModules('evaluation.questions')}
              </Typography>
            </div>
            <div
              id="questions-container"
              className="flex-1 flex w-full flex-col gap-2 overflow-y-auto w-full max-h-96"
            >
              {Object.keys(questions).map((questionIri) => {
                return (
                  <div
                    key={questionIri}
                    className="flex gap-2 w-full flex-col justify-between shadow-md p-3 background-primary select-none"
                  >
                    <div
                      onClick={() => onQuestionClickAction(questionIri)}
                      className="flex justify-between flex-col gap-4 cursor-pointer"
                    >
                      <Typography variant="h6">
                        {questions[questionIri].text}
                      </Typography>
                    </div>
                    {selectedQuestion === questionIri && (
                      <textarea
                        value={
                          currentAnswers['/api/users/' + selectedStudent]
                            ? currentAnswers['/api/users/' + selectedStudent][
                                questionIri
                              ]
                            : questions[questionIri].response
                        }
                        onChange={(e) =>
                          setQuestions((prev) => {
                            if (!prev) return;
                            return {
                              ...prev,
                              [questionIri]: {
                                text: prev[questionIri].text,
                                response: e.target.value,
                              },
                            };
                          })
                        }
                        className="w-full bg-gray-50 p-4 rounded-xl border shadow-md"
                        placeholder=""
                        rows={3}
                        style={{ resize: 'none' }}
                        disabled={
                          !!(
                            currentAnswers['/api/users/' + selectedStudent] &&
                            currentAnswers['/api/users/' + selectedStudent][
                              questionIri
                            ]
                          )
                        }
                      ></textarea>
                    )}
                    {selectedQuestion === questionIri &&
                      !(
                        currentAnswers['/api/users/' + selectedStudent] &&
                        currentAnswers['/api/users/' + selectedStudent][
                          questionIri
                        ]
                      ) && (
                        <ButtonComp
                          onButtonClick={() => {
                            sendResponseAction();
                          }}
                          buttonText="Trimite raspuns"
                          buttonProps={{
                            variant: 'contained',
                          }}
                          tailwindContainerClasses="flex justify-end"
                        />
                      )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
