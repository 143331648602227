import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { AverageApi } from 'api/average.api';
import { CatalogApi } from 'api/catalog.api';
import { CatedraApi } from 'api/catedra.api';
import { ClassApi } from 'api/class.api';
import { EvaluationApi } from 'api/evaluation.api';
import { HomeworkApi } from 'api/homework.api';
import { LessonApi } from 'api/lesson.api';
import { SchoolApi } from 'api/school.api';
import { SchoolYearApi } from 'api/schoolYear.api';
import { StudyYearApi } from 'api/studyYear.api';
import { SubjectApi } from 'api/subject.api';
import { TermAkaModulApi } from 'api/termAkaModul.api';
import { TestApi } from 'api/test.api';
import { TimeSlotApi } from 'api/timeslot.api';
import { UserApi } from 'api/user.api';
import { UserClassApi } from 'api/userClass.api';
import { UserContext } from 'context/UserContext';
import { CollectionApiResponse, ParamsType } from 'library/types/Common';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { TableConfig } from './TablesConfig';

type DataType<T> = CollectionApiResponse<T> | T[];

const GenericTable = <T extends Record<string, any>>(props: TableConfig<T>) => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const userContext = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  if (state) {
    var queryParams = state.queryParams;
    var overrideTitle = state.overrideTitle;
    var onCreateNavigateState = state.onCreateNavigateState;
    var routeSpecificId = state.routeSpecificId;
  }

  const getCollectionApis: {
    [key: string]: (config: {
      id?: number | undefined;
      params?: ParamsType;
    }) => Promise<any>;
  } = {
    user: UserApi().getUser,
    school: SchoolApi().getSchool,
    class: ClassApi().getClass,
    classProfile: ClassApi().getClassProfile,
    schoolYear: SchoolYearApi().getSchoolYear,
    studyYear: StudyYearApi().getStudyYear,
    subjectConfig: SubjectApi().getSubjectConfig,
    subjectType: SubjectApi().getSubjectType,
    termAkaModul: TermAkaModulApi().getTermAkaModul,
    timeSlot: TimeSlotApi().getTimeSlot,
    evaluationQuestions: EvaluationApi().getEvaluationQuestion,
    evaluationQuestionsConfig: EvaluationApi().getEvaluationConfig,
    yearlyEvaluationQuestions: EvaluationApi().getYearlyEvaluationQuestion,
    evaluationForProfessorResponse:
      EvaluationApi().getEvaluationForProfessorResponse,
    evaluationForStudentResponse:
      EvaluationApi().getEvaluationForStudentResponse,
    yearlyEvaluationResponse: EvaluationApi().getYearlyEvaluationResponse,
    catalog: CatalogApi().getCatalog,
    homework: HomeworkApi().getHomework,
    studentHomework: HomeworkApi().getStudentHomework,
    lesson: LessonApi().getLesson,
    testProfessors: TestApi().getProfessorsTest,
    testStudents: TestApi().getStudentsTest,
    testQuestion: TestApi().getTestQuestion,
    testQuestionToTest: TestApi().getTestQuestionToTest,
    testQuestionAnswer: TestApi().getTestQuestionAnswer,
    userClass: UserClassApi().getUserClass,
    classSpecialization: ClassApi().getClassSpecialization,
    catedra: CatedraApi().getCatedra,
    average: AverageApi().getAverage,
    closedSituation: AverageApi().getClosedSituation,
  };

  const {
    getCollectionApi,
    columns,
    navigateOnCreate,
    tableProps,
    tableConfigParams,
    allowedRolesToCreate,
    isNotHydrated,
  } = props;

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const tableColumns = useMemo<MRT_ColumnDef<T>[]>(
    () =>
      columns.map((e) => ({
        ...e,
        header: tModules('schoolAdmin.tables.genericTable.' + e.header),
      })),
    []
  );

  const { data, isError, isFetching, isLoading, refetch } = useQuery<
    DataType<T>
  >({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    cacheTime: 0,
    queryFn: async () => {
      const params: any = {
        page: pagination.pageIndex + 1,
        itemsPerPage: pagination.pageSize,
      };

      if (sorting?.length) {
        params[`order[${sorting[0].id}]`] = sorting[0].desc ? 'DESC' : 'ASC';
      }

      if (columnFilters?.length) {
        columnFilters.forEach((e) => {
          params[e.id] = e.value;
        });
      }
      const response = (await getCollectionApis[getCollectionApi]({
        ...(routeSpecificId ? { id: routeSpecificId } : {}),
        params: {
          ...(queryParams ? queryParams : {}),
          ...tableConfigParams,
          ...params,
        },
      })) as CollectionApiResponse<T>;
      return response;
    },
    keepPreviousData: true,
  });

  return (
    <div className="flex flex-col w-full">
      <div
        style={{ top: '-60px' }}
        className="absolute flex w-full justify-center"
      >
        <Typography variant="h3">
          {tCommon(overrideTitle || props.tableTitle)}
        </Typography>
      </div>
      {(isNotHydrated
        ? data && Array.isArray(data as T[])
        : data &&
          Array.isArray(
            (data as CollectionApiResponse<T>)['hydra:member']
          )) && (
        <MaterialReactTable
          columns={tableColumns.map((e) => {
            return {
              ...e,
              enableColumnFilter:
                e.enableColumnFilter &&
                state &&
                state.removeFilters &&
                !state.removeFilters.includes(e.accessorKey),
            };
          })}
          data={
            data
              ? isNotHydrated
                ? (data as T[])
                : (data as CollectionApiResponse<T>)['hydra:member']
              : []
          }
          //data is undefined on first render
          initialState={{ showColumnFilters: true }}
          positionActionsColumn="last"
          manualFiltering
          manualPagination
          manualSorting
          enableHiding={false}
          enableGlobalFilter={false}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          enableColumnResizing={true}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderTopToolbarCustomActions={() => (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              {((userContext.state.account?.selectedView &&
                allowedRolesToCreate?.includes(
                  userContext.state.account.selectedView
                )) ||
                !allowedRolesToCreate) &&
                navigateOnCreate && (
                  <Button
                    onClick={() => {
                      navigate(`${navigateOnCreate}`, {
                        ...(onCreateNavigateState
                          ? {
                              state: {
                                ...onCreateNavigateState,
                              },
                            }
                          : {}),
                      });
                    }}
                    color="primary"
                    variant="contained"
                  >
                    <span className="font-bold">Adauga</span>
                  </Button>
                )}
            </Box>
          )}
          rowCount={
            data
              ? isNotHydrated
                ? (data as T[])?.length
                : (data as CollectionApiResponse<T>)['hydra:totalItems']
              : 0
          }
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isFetching,
            sorting,
          }}
          {...tableProps}
        />
      )}
    </div>
  );
};

const queryClient = new QueryClient();

const GenericTableWithReactQueryProvider = <T extends Record<string, any>>(
  props: TableConfig<T>
) => (
  <QueryClientProvider client={queryClient}>
    <GenericTable {...props} />
  </QueryClientProvider>
);

export default GenericTableWithReactQueryProvider;
