import { ClassApi } from 'api/class.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { isObjectValid } from 'library/helpers/helper';
import {
  ClassProfile,
  ClassSpecialization,
  ClassSpecializationData,
  ClassSpecializationPayload,
} from 'library/types/Class';
import { CollectionApiResponse } from 'library/types/Common';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ClassSpecializationModule = (): JSX.Element => {
  const navigate = useNavigate();
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const userContext = useContext(UserContext);

  const location = useLocation();
  const state = location.state as {
    editId: number;
  };
  const { editId } = state || {};

  const {
    createClassSpecialization,
    getClassProfile,
    getClassSpecialization,
    editClassSpecialization,
  } = ClassApi();

  const [classSpecializationData, setClassSpecializationData] =
    useState<ClassSpecializationData>({
      profilClasa: null,
      tipSpecializare: '',
    });

  const [classProfileOptions, setClassProfileOptions] = useState<
    ClassProfile[]
  >([]);

  useEffect(() => {
    (async () => {
      const classProfiles =
        (await getClassProfile()) as CollectionApiResponse<ClassProfile>;

      setClassProfileOptions(classProfiles['hydra:member']);
      let classSpecialization: ClassSpecialization | undefined;

      if (editId) {
        classSpecialization = (await getClassSpecialization({
          id: editId,
        })) as ClassSpecialization;

        setClassSpecializationData({
          profilClasa: classSpecialization.profilClasa,
          tipSpecializare: classSpecialization.tipSpecializare,
        });
      }
    })();
  }, []);

  const isActionValid = () => {
    if (!classSpecializationData) return false;

    if (!isObjectValid(classSpecializationData)) {
      toast.error(
        tModules(
          'schoolAdmin.classSpecialization.submitClassSpecializationValidationError'
        )
      );
      return false;
    }
    return true;
  };

  const classSpecializationAction = async (id?: number) => {
    if (!isActionValid()) return;

    const { tipSpecializare, profilClasa } = classSpecializationData!;
    const payloadObject: ClassSpecializationPayload = {
      tipSpecializare,
      profilClasa: `/api/profileClasa/${profilClasa?.id}`,
    };
    navigate('/table-class-specialization');
    !editId
      ? await createClassSpecialization(payloadObject)
      : await editClassSpecialization(editId, payloadObject);
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules(
                'schoolAdmin.classSpecialization.classSpecializationType'
              ),
              value: classSpecializationData?.tipSpecializare ?? '',
              onChange: (e) => {
                setClassSpecializationData((prev) => {
                  return {
                    ...prev,
                    tipSpecializare: e.target.value,
                  };
                });
              },
            }}
          />
          <RegularSelect
            options={classProfileOptions!.map((e) => ({
              label: e.tipProfilClasa.toString(),
              value: e.id,
            }))}
            onChange={(e) => {
              setClassSpecializationData((prevValue) => {
                return {
                  ...prevValue,
                  profilClasa: classProfileOptions.find((el) => el.id === e)!,
                };
              });
            }}
            selectProps={{
              label: tModules('schoolAdmin.classSpecialization.classProfile'),
              value: classSpecializationData.profilClasa?.id ?? '',
              fullWidth: true,
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={classSpecializationAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Login',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
