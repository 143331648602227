import { Typography, useTheme } from '@mui/material';
import { UserContext } from 'context/UserContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { icons } from 'resources/images';

export const Footer = (): JSX.Element => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const theme = useTheme();

  return (
    <div style={{ zIndex: 999 }} className="h-20 w-full flex ">
      <div className="pl-8 flex items-center font-bold w-2/12">
        <img src={icons.general.logoJos} />
      </div>
      <div className="flex gap-1 items-end flex-grow justify-center items-center w-6/12">
        <span className="mr-3">
          <Typography
            color={{ color: theme.palette.primary.main }}
            variant="footerBold"
          >
            {t('footer.platformTitle')}
          </Typography>
        </span>
        <span>
          <Typography
            color={{ color: theme.palette.primary.main }}
            variant="footerLight"
          >
            {t('footer.footerTextPart1')}
          </Typography>
        </span>
        <span>
          <Typography
            color={{ color: theme.palette.primary.main }}
            variant="footerSemiBold"
          >
            {t('footer.footerTextPart2')}
          </Typography>
        </span>
      </div>
    </div>
  );
};
