import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface ClearIconProps {
  onClick: () => void;
}

const ClearIcon: React.FC<ClearIconProps> = ({ onClick }) => {
  return (
    <div>
      <IconButton size="small" onClick={onClick}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

export default ClearIcon;
