import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import {
  EvaluationConfig,
  EvaluationConfigPayload,
  EvaluationForProfessorResponse,
  EvaluationForStudentResponse,
  EvaluationQuestion,
  EvaluationQuestionPayload,
  EvaluationResponsePayload,
  SentEvaluationForProfessorResponse,
  SentEvaluationForStudentResponse,
  SentYearlyEvaluationResponse,
  YearlyEvaluationQuestion,
  YearlyEvaluationQuestionPayload,
  YearlyEvaluationResponse,
  YearlyEvaluationResponsePayload,
} from 'library/types/Evaluation';

export function EvaluationApi() {
  const { apiHandler } = JWTInterceptor();

  const createEvaluationQuestion = async (
    evaluationQuestionInfo: EvaluationQuestionPayload
  ) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/intrebare-evaluare`,
      data: evaluationQuestionInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Evaluation question was successfully created!',
    });

    return response.data;
  };

  const getEvaluationQuestion = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<
    EvaluationQuestion | CollectionApiResponse<EvaluationQuestion>
  > => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/intrebare-evaluare/${id}`
        : `${serverUri()}/intrebare-evaluare/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createYearlyEvaluationQuestion = async (
    yearlyEvaluationQuestionInfo: YearlyEvaluationQuestionPayload
  ) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/intrebare-evaluare-anuala`,
      data: yearlyEvaluationQuestionInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Evaluation question was successfully created!',
    });

    return response.data;
  };

  const getYearlyEvaluationQuestion = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<
    YearlyEvaluationQuestion | CollectionApiResponse<YearlyEvaluationQuestion>
  > => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/intrebare-evaluare-anuala/${id}`
        : `${serverUri()}/intrebare-evaluare-anuala/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getYearlyEvaluationQuestionForProfessors = async (): Promise<
    YearlyEvaluationQuestion | CollectionApiResponse<YearlyEvaluationQuestion>
  > => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/intrebari-evaluare-anuala-curenta/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const createEvaluationConfig = async (
    evaluationConfigInfo: EvaluationConfigPayload
  ) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/config-evaluare`,
      data: evaluationConfigInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Evaluation question form was successfully created!',
    });

    return response.data;
  };

  const getEvaluationConfig = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<EvaluationConfig | CollectionApiResponse<EvaluationConfig>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/config-evaluare/${id}`
        : `${serverUri()}/config-evaluare/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getCurrentEvaluationConfig = async (): Promise<EvaluationConfig> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/evaluare-modul-curent`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const sendYearlyEvaluationResponse = async (
    yearlyEvaluationResponseInfo: YearlyEvaluationResponsePayload
  ) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/raspuns-intrebare-evaluare-anuala`,
      data: yearlyEvaluationResponseInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'The evaluation response was recorded successfully!',
    });

    return response.data;
  };

  const sendEvaluationResponse = async (
    evaluationResponseInfo: EvaluationResponsePayload
  ) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/raspuns-intrebare-evaluare`,
      data: evaluationResponseInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'The evaluation response was recorded successfully!',
    });

    return response.data;
  };

  const getYearlyEvaluationResponse = async (config?: {
    params?: ParamsType;
  }): Promise<CollectionApiResponse<YearlyEvaluationResponse>> => {
    if (config) {
      var { params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/raspuns-intrebare-evaluare-anuala`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };
  const getSentYearlyEvaluationResponse = async (config?: {
    params?: ParamsType;
  }): Promise<SentYearlyEvaluationResponse[]> => {
    if (config) {
      var { params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/raspunsuri-evaluare-anuala-trimise`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getEvaluationForProfessorResponse = async (config?: {
    params?: ParamsType;
  }): Promise<CollectionApiResponse<EvaluationForProfessorResponse>> => {
    if (config) {
      var { params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/raspuns-intrebare-evaluare`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params: {
          ...params,
          'exists[oraEvaluata]': true,
        },
      },
    });

    return response.data;
  };

  const getEvaluationForStudentResponse = async (config?: {
    params?: ParamsType;
  }): Promise<CollectionApiResponse<EvaluationForStudentResponse>> => {
    if (config) {
      var { params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/raspuns-intrebare-evaluare`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params: {
          ...params,
          'exists[userEvaluat]': true,
        },
      },
    });

    return response.data;
  };

  const getSentEvaluationResponse = async (config?: {
    params?: ParamsType;
  }): Promise<
    SentEvaluationForStudentResponse[] | SentEvaluationForProfessorResponse[]
  > => {
    if (config) {
      var { params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/raspunsuri-evaluare-trimise`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  return {
    getCurrentEvaluationConfig,
    createEvaluationQuestion,
    getEvaluationQuestion,
    createYearlyEvaluationQuestion,
    getYearlyEvaluationQuestion,
    createEvaluationConfig,
    getEvaluationConfig,
    sendYearlyEvaluationResponse,
    sendEvaluationResponse,
    getYearlyEvaluationQuestionForProfessors,
    getYearlyEvaluationResponse,
    getEvaluationForProfessorResponse,
    getEvaluationForStudentResponse,
    getSentEvaluationResponse,
    getSentYearlyEvaluationResponse,
  };
}
