import { TimeTableApi } from 'api/timetable.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { adjustWeeklyScheduleTimes } from 'library/helpers/helper';
import { SwapTimeTablesPayload } from 'library/types/TimeTable';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const SwapTimeTablesModule = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const state = location.state as {
    classId: number;
    classCode: string;
    moduleId: number;
    orarId: number;
    orarIri: string;
    orarDetails: string;
  };

  const { classId, classCode, moduleId, orarId, orarIri, orarDetails } =
    state || {};

  const { getTimeslotsWithTimeTable, swapTimeTables } = TimeTableApi();

  const [timeslotsWithTimeTable, setTimeslotsWithTimeTable] = useState<
    {
      orarIri: string;
      details: string;
    }[]
  >([]);

  const [selectedTimeslot, setSelectedTimeslot] = useState<{
    orarIri: string;
    details: string;
  }>({
    orarIri: '',
    details: '',
  });

  useEffect(() => {
    if (!moduleId || !classId || !orarId) {
      navigate('/view-timetable');
      return;
    }

    (async () => {
      const timeslotsWithTimeTable = adjustWeeklyScheduleTimes(
        await getTimeslotsWithTimeTable({
          classId,
          moduleId,
          getOnlyOccupied: true,
          editedOrarId: orarId,
        })
      );

      const allTimeSlots: {
        orarIri: string;
        details: string;
      }[] = [];

      Object.values(timeslotsWithTimeTable).map((e, index) => {
        Object.values(e).forEach((el) => {
          allTimeSlots.push({
            orarIri: el.orarIri!,
            details:
              el.oraSfarsit +
              ' - ' +
              el.oraSfarsit +
              ' | ' +
              Object.keys(timeslotsWithTimeTable)[index] +
              ' - ' +
              el.materie?.tipMaterie +
              ' - ' +
              el.profesor?.numeComplet,
          });
        });
      });

      setTimeslotsWithTimeTable(allTimeSlots);
    })();
  }, []);

  const isActionValid = () => {
    if (!selectedTimeslot.orarIri) {
      toast.error(
        tModules('schoolAdmin.timeTable.swapTimeTableValidationError')
      );
      return false;
    }

    return true;
  };

  const swapTimeTablesAction = async () => {
    if (!isActionValid()) return;

    const swapTimeTablesPayload: SwapTimeTablesPayload = {
      orar1: orarIri,
      orar2: selectedTimeslot.orarIri,
    };

    await swapTimeTables(swapTimeTablesPayload);

    navigate('/view-timetable');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.timeTable.class'),
              value: classCode,
              disabled: true,
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.timeTable.sameTimeTableTimeError'),
              value: orarDetails,
              disabled: true,
            }}
          />
          <RegularSelect
            options={timeslotsWithTimeTable.map((e) => ({
              value: e.orarIri,
              label: e.details,
            }))}
            onChange={(e) => {
              setSelectedTimeslot(
                timeslotsWithTimeTable.find((el) => el.orarIri === e)!
              );
            }}
            selectProps={{
              label: tModules('schoolAdmin.timeTable.swapWithTimeTable'),
              value: selectedTimeslot.orarIri,
              fullWidth: true,
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={swapTimeTablesAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Salveaza',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
