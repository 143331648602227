import { UserApi } from 'api/user.api';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import {
  Theme,
  UserContext,
  UserContextActionTypes,
} from 'context/UserContext';
import dayjs from 'dayjs';
import { User, UserRoles } from 'library/types/User';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Profile = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const userContext = useContext(UserContext);
  const { changeActiveRole } = UserApi();
  const [userData, setUserData] = useState<{
    user: User;
    selectedView: UserRoles;
    theme: Theme;
  }>({
    user: userContext.state.account!.user,
    theme: userContext.state.theme,
    selectedView: userContext.state.account!.selectedView,
  });

  useEffect(() => {
    if (!userContext.state.account) {
      userContext.dispatch({ type: UserContextActionTypes.LogOut });
      return;
    }
    console.log('ratatata', userContext.state.account?.selectedView);
    setUserData({
      user: userContext.state.account.user,
      theme: userContext.state.theme,
      selectedView: userContext.state.account?.selectedView,
    });
  }, [
    userContext.state.account?.selectedView,
    userContext.state.account?.user,
  ]);

  const compileViewTypesOptions = (userRoles: UserRoles[]) => {
    const rolesWithoutUser = userRoles.filter((e) => e !== UserRoles.ROLE_USER);
    return rolesWithoutUser.map((e) => {
      switch (e) {
        case UserRoles.ROLE_SCHOOL_ADMIN:
          return {
            label: tCommon('userRoles.ROLE_SCHOOL_ADMIN'),
            value: e,
          };

        case UserRoles.ROLE_DIRECTOR:
          return {
            label: tCommon('userRoles.ROLE_DIRECTOR'),
            value: e,
          };

        case UserRoles.ROLE_INVATATOR:
          return {
            label: tCommon('userRoles.ROLE_INVATATOR'),
            value: e,
          };

        case UserRoles.ROLE_DIRIGINTE:
          return {
            label: tCommon('userRoles.ROLE_DIRIGINTE'),
            value: e,
          };

        case UserRoles.ROLE_PROFESOR:
          return {
            label: tCommon('userRoles.ROLE_PROFESOR'),
            value: e,
          };

        case UserRoles.ROLE_PARINTE:
          return {
            label: tCommon('userRoles.ROLE_PARINTE'),
            value: e,
          };

        case UserRoles.ROLE_ELEV:
          return {
            label: tCommon('userRoles.ROLE_ELEV'),
            value: e,
          };

        case UserRoles.ROLE_SECRETAR:
          return {
            label: tCommon('userRoles.ROLE_SECRETAR'),
            value: e,
          };

        default:
          return {
            label: '-',
            value: '-',
          };
      }
    });
  };

  const getViewTypeLabel = (userRole: UserRoles) => {
    switch (userRole) {
      case UserRoles.ROLE_SCHOOL_ADMIN:
        return tCommon('userRoles.ROLE_SCHOOL_ADMIN');

      case UserRoles.ROLE_DIRECTOR:
        return tCommon('userRoles.ROLE_DIRECTOR');

      case UserRoles.ROLE_INVATATOR:
        return tCommon('userRoles.ROLE_INVATATOR');

      case UserRoles.ROLE_DIRIGINTE:
        return tCommon('userRoles.ROLE_DIRIGINTE');

      case UserRoles.ROLE_PROFESOR:
        return tCommon('userRoles.ROLE_PROFESOR');

      case UserRoles.ROLE_PARINTE:
        return tCommon('userRoles.ROLE_PARINTE');

      case UserRoles.ROLE_ELEV:
        return tCommon('userRoles.ROLE_ELEV');

      case UserRoles.ROLE_SECRETAR:
        return tCommon('userRoles.ROLE_SECRETAR');

      default:
        return '-';
    }
  };

  const changeSelectedViewAction = async (selectedView: UserRoles) => {
    await changeActiveRole(selectedView);

    userContext.dispatch({
      type: UserContextActionTypes.SetSelectedView,
      value: selectedView,
    });
  };

  const changeThemeAction = (theme: Theme) => {
    setUserData((prevData) => {
      return {
        ...prevData,
        theme,
      };
    });

    userContext.dispatch({
      type: UserContextActionTypes.SetTheme,
      value: theme,
    });
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        {userData && (
          <div
            style={{
              width: '600px',
            }}
            className="flex flex-col"
          >
            {userData.user.roles.filter((e) => e !== UserRoles.ROLE_USER)
              .length > 1 ? (
              <RegularSelect
                options={compileViewTypesOptions(userData.user.roles)}
                onChange={(e) => changeSelectedViewAction(e)}
                selectProps={{
                  disabled:
                    userData.user.roles.filter((e) => e !== UserRoles.ROLE_USER)
                      .length === 1,
                  label: tModules('profile.currentRole'),
                  value: userData.selectedView,
                  fullWidth: true,
                }}
              />
            ) : (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('profile.currentRole'),
                  defaultValue: getViewTypeLabel(userData.selectedView),
                  disabled: true,
                }}
              />
            )}
            <RegularSelect
              options={Object.values(Theme).map((e) => {
                return {
                  label: e.charAt(0).toUpperCase() + e.slice(1),
                  value: e,
                };
              })}
              onChange={(e) => changeThemeAction(e)}
              selectProps={{
                label: tModules('profile.theme'),
                value: userData.theme,
                fullWidth: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.firstName'),
                defaultValue: userData.user.prenume,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.surname'),
                defaultValue: userData.user.nume,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.email'),
                defaultValue: userData.user.email,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.phoneNumber'),
                defaultValue: userData.user.telefon,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.fatherInitial'),
                defaultValue: userData.user.initialaParinte,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.birthdayDate'),
                defaultValue: dayjs(userData.user.dataNastere).format(
                  'DD.MM.YYYY'
                ),
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.socialIdentifier'),
                defaultValue: userData.user.cnp,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.schoolRegistrationNumber'),
                defaultValue: userData.user.nrMatricol,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.country'),
                defaultValue: userData.user.tara?.nume,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.county'),
                defaultValue: userData.user.judet?.nume,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.city'),
                defaultValue: userData.user.localitate?.nume,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.address'),
                defaultValue: userData.user.adresa,
                disabled: true,
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('profile.medicalNotes'),
                defaultValue: userData.user.mentiuniMedicale || '-',
                disabled: true,
                multiline: true,
                maxRows: 5,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
