import { publicServerUri, serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import {
  CollectionUser,
  InvatoriDirigintiClase,
  User,
  UserPayload,
  UserRoles,
} from 'library/types/User';

export function UserApi() {
  const { apiHandler } = JWTInterceptor();

  const login = (data: {
    email: string;
    password: string;
    scoala: number;
  }): Promise<{
    data?: {
      token: string;
      user: User;
    };
  }> => {
    return apiHandler({
      method: 'post',
      data,
      url: `${publicServerUri()}/login`,

      successMessage: `Successfully logged in!`,
      errorMessage: 'Invalid credentials.',
    });
  };

  const resetPassword = async (
    id: number
  ): Promise<{
    success: boolean;
    password: string;
  }> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/reset-password/${id}`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
      successMessage: 'Parola a fost resetata cu succes!',
    });

    return response.data;
  };

  const getMe = async (): Promise<{
    data?: {
      clasa: {
        codClasa: string;
        id: number;
      } | null;
      user: User;
    };
  }> => {
    return apiHandler({
      method: 'get',
      url: `${serverUri()}/me`,
    });
  };

  const getUser = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<User | CollectionApiResponse<CollectionUser>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/users/${id}` : `${serverUri()}/users/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getAllDiriginti = async (): Promise<Array<InvatoriDirigintiClase>> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/diriginti-clase`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const getAllInvatatori = async (): Promise<Array<InvatoriDirigintiClase>> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/invatatori-clase`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const getAllProfessors = async (): Promise<User[]> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/users/groups/profesori`,
      axiosConfig: apiCallConfigTypes.get,
    });

    return response.data;
  };

  const createUser = async (userInfo: UserPayload): Promise<User> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/users`,
      data: userInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'User successfully created!',
    });

    return response.data;
  };

  const editUser = async (id: number, userInfo: UserPayload): Promise<User> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/users/${id}`,
      data: userInfo,
      axiosConfig: apiCallConfigTypes.patch,
      successMessage: 'User successfully updated!',
    });

    return response.data;
  };

  const changeActiveRole = async (activeRole: UserRoles) => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/active-role`,
      data: {
        role: activeRole,
      },
      axiosConfig: apiCallConfigTypes.post,
    });
    return response.data;
  };

  const patchUser = async (id: number, data: any): Promise<User> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/users/${id}`,
      data,
      axiosConfig: apiCallConfigTypes.patch,
      successMessage: 'User successfully updated!',
    });

    return response.data;
  };

  return {
    login,
    resetPassword,
    getMe,
    getAllProfessors,
    createUser,
    getUser,
    editUser,
    changeActiveRole,
    getAllDiriginti,
    getAllInvatatori,
    patchUser,
  };
}
