const host = process.env.REACT_APP_HOST;
const environment = process.env.NODE_ENV;

export const publicServerUri = (): string => {
  switch (environment) {
    case 'development':
      // return host || 'https://api.preuniv.smart-edu.ro';
      return host || 'http://localhost:8000';
    case 'production':
      return host || '';
    default:
      return host || '';
  }
};

export const serverUri = (): string => {
  const environment = process.env.NODE_ENV;
  switch (environment) {
    case 'development':
      return 'http://localhost:8000/api';
    // return 'https://api.preuniv.smart-edu.ro/api';
    case 'production':
      return `${host}/api`;
    default:
      return '';
  }
};
