import { UserContext } from 'context/UserContext';
import { UserRoles } from 'library/types/User';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

export const RequireAuthAndRole = ({
  children,
  redirectTo,
  allowedRoles,
}: {
  children: JSX.Element;
  redirectTo: string;
  allowedRoles?: UserRoles[];
}) => {
  const userContext = useContext(UserContext);
  const userState = userContext.state;
  if (
    !userState.account ||
    (allowedRoles && !allowedRoles.includes(userState.account.selectedView))
  ) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};
