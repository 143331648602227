import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import {
  SimpleSubjectConfig,
  SubjectConfig,
  SubjectConfigPayload,
  SubjectType,
  SubjectTypePayload,
} from 'library/types/Subject';

export function SubjectApi() {
  const { apiHandler } = JWTInterceptor();

  const getSubjectType = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<SubjectType | CollectionApiResponse<SubjectType>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/tipuriMaterie/${id}`
        : `${serverUri()}/tipuriMaterie/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createSubjectType = async (
    subjectTypeInfo: SubjectTypePayload
  ): Promise<SubjectType> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/tipuriMaterie`,
      data: subjectTypeInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Subject type was successfully created!',
    });

    return response.data;
  };

  const getSubjectConfig = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<SubjectConfig | CollectionApiResponse<SubjectConfig>> => {
    if (config) var { id, params } = config;
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/materii/${id}` : `${serverUri()}/materii/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getSubjectConfigsForStudyYear = async (
    schoolId: number,
    studyYear: number
  ): Promise<SimpleSubjectConfig[]> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/materii-anstudiu/${schoolId}/${studyYear}`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const createSubjectConfig = async (
    subjectConfigInfo: SubjectConfigPayload
  ): Promise<SubjectConfig> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/materii`,
      data: subjectConfigInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Subject was successfully created!',
    });

    return response.data;
  };

  const getSubjectsForClass = async (
    classId: number
  ): Promise<SubjectConfig[]> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/materii-clasa/${classId}`,
      axiosConfig: apiCallConfigTypes.get,
    });

    return response.data;
  };

  const editSubjectConfig = async (
    id: number,
    data: Partial<SubjectConfigPayload>
  ): Promise<SubjectConfig> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/materii/${id}`,
      data: data,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The subject config was successfully modified!',
    });

    return response.data;
  };

  const editSubjectType = async (
    id: number,
    data: Partial<SubjectTypePayload>
  ): Promise<SubjectType> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/tipuriMaterie/${id}`,
      data: data,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The subject type was successfully modified!',
    });

    return response.data;
  };

  return {
    getSubjectType,
    createSubjectType,
    getSubjectConfig,
    createSubjectConfig,
    getSubjectConfigsForStudyYear,
    getSubjectsForClass,
    editSubjectConfig,
    editSubjectType,
  };
}
