import { CatalogApi } from 'api/catalog.api';
import { ClassApi } from 'api/class.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { CatalogPayload } from 'library/types/Catalog';
import { IRI } from 'library/types/Common';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const CreateCatalogEntryModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const state = location.state as {
    anStudiu: number;
    materie: {
      '@id': IRI;
      detaliiMaterie: string;
    };
    clasa: {
      '@id': IRI;
      id: number;
      codClasa: string;
      anStudiu: number;
    };
    detaliiTemporale: string;
    orarIri: IRI;
    orarDetaliatIri: IRI;
    modulIri: IRI;
    isGrade: boolean;
    saptamana: string;
  };
  const {
    materie,
    clasa,
    detaliiTemporale,
    isGrade,
    orarIri,
    modulIri,
    orarDetaliatIri,
    saptamana,
  } = state || {};

  const { getCalificative, createCatalogEntry } = CatalogApi();
  const { getStudentsOfClass, getAvailableClasses } = ClassApi();

  // if we decide we use direct entry as well
  // const [classes, setClasses] = useState<
  //   Array<{
  //     id: number;
  //     codClasa: string;
  //     // anStudiu: number;
  //   }>
  // >([]);
  // const [selectedClass, setSelectedClass] = useState<{
  //   id: number;
  //   codClasa: string;
  //   // anStudiu: number;
  // }>({
  //   id: -1,
  //   codClasa: '',
  //   // anStudiu: -1
  // });

  // const [timeTables, setTimeTables] = useState<
  //   Array<{
  //     '@id': IRI;
  //     detaliiIntervalOrar: string;
  //   }>
  // >([]);
  // const [selectedTimeTable, setselectedTimeTable] = useState<{
  //   '@id': IRI;
  //   detaliiOrar: string;
  // }>({
  //   '@id': '',
  //   detaliiOrar: '',
  // });

  const [students, setStudents] = useState<
    Array<{
      id: number;
      nume: string;
    }>
  >([]);
  const [selectedStudent, setSelectedStudent] = useState<{
    id: number;
    nume: string;
  }>({
    id: -1,
    nume: '',
  });

  const [noteSauCalificative, setNoteSauCalificative] = useState<
    Array<string | number>
  >([]);
  const [selectedNotaSauCalificativ, setSelectedNotaSauCalificativ] = useState<
    string | number
  >('');

  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    if (state && state.clasa) {
      (async () => {
        const students = await getStudentsOfClass(state.clasa.id);

        setStudents(
          students.map((e) => ({
            nume: e.prenume + ' ' + e.nume,
            id: e.id,
          }))
        );
      })();
      if (isGrade) {
        if (state.clasa.anStudiu < 5) {
          (async () => {
            const calificative = await getCalificative();

            setNoteSauCalificative(calificative);
          })();
        } else {
          setNoteSauCalificative([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
        }
      }
    } else {
      navigate('/view-detailed-timetable');
      // if we decide we use direct entry as well
      // (async () => {
      //   const classes = await getAvailableClasses();

      //   setClasses(
      //     classes.map((e) => ({
      //       id: e.id,
      //       codClasa: e.codClasa,
      //       // anStudiu: e.anStudiu
      //     }))
      //   );
      // })();
    }
  }, []);

  const submitFormAction = async () => {
    if (!selectedStudent || (isGrade && !selectedNotaSauCalificativ))
      return toast.error(tModules('catalogue.allFieldsMustBeFilled'));

    const submitCatalogPayload: CatalogPayload = {
      materie: materie['@id'],
      observatii: comment,
      user: `/api/users/${selectedStudent.id}`,
      clasa: clasa['@id'],
      orar: orarIri,
      orarDetaliat: orarDetaliatIri,
      modul: modulIri,
      ...(isGrade
        ? clasa.anStudiu < 5
          ? { calificativ: selectedNotaSauCalificativ as string }
          : { nota: selectedNotaSauCalificativ as number }
        : { absenta: true }),
    };

    await createCatalogEntry(submitCatalogPayload);

    navigate('/view-detailed-timetable', {
      state: {
        saptamana,
      },
    });
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          {state && state.clasa && (
            <div>
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('catalogue.class'),
                  disabled: true,
                  value: clasa.codClasa,
                }}
              />
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('catalogue.subject'),
                  disabled: true,
                  value: materie.detaliiMaterie,
                }}
              />
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('catalogue.timeDetails'),
                  disabled: true,
                  value: detaliiTemporale,
                }}
              />
            </div>
          )}
          <RegularSelect
            options={students.map((e) => ({
              label: e.nume,
              value: e.id,
              key: e.id,
            }))}
            onChange={(e) =>
              setSelectedStudent(students.find((el) => el.id === e)!)
            }
            selectProps={{
              label: tModules('catalogue.studentSelect'),
              value: selectedStudent.id !== -1 ? selectedStudent.id : '',
              fullWidth: true,
            }}
          />
          {isGrade && (
            <RegularSelect
              options={noteSauCalificative.map((e) => ({
                label: e + '',
                value: e,
                key: e,
              }))}
              onChange={(e) => {
                setSelectedNotaSauCalificativ(e);
              }}
              selectProps={{
                label:
                  clasa && clasa.anStudiu < 5
                    ? tModules('catalogue.mark')
                    : tModules('catalogue.grade'),
                value: selectedNotaSauCalificativ,
                fullWidth: true,
              }}
            />
          )}
          {!isGrade && (
            <RegularInput
              isOptional={true}
              textFieldProps={{
                fullWidth: true,
                rows: 7,
                multiline: true,
                label: tModules('catalogue.observation'),
                value: comment,
                onChange: (e) => {
                  setComment(e.target.value);
                },
              }}
            />
          )}
        </div>
        <ButtonComp
          onButtonClick={submitFormAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
