import { ClassApi } from 'api/class.api';
import { UserClassApi } from 'api/userClass.api';
import { AutocompleteInput } from 'common/components/inputs/AutocompleteInput';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { isObjectValid } from 'library/helpers/helper';
import { SimpleCollectionClass, StatusUserClasa } from 'library/types/Class';
import { User } from 'library/types/User';
import {
  UserClass,
  UserClassModuleData,
  UserClassPayload,
} from 'library/types/UserClass';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UserClassModule = (): JSX.Element => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const {
    createUserClass,
    getUnallocatedStudents,
    editUserClass,
    getUserClass,
    getStatusUserClasa,
  } = UserClassApi();
  const { getAvailableClasses } = ClassApi();

  const location = useLocation();
  const state = location.state as {
    editId: number;
    clasaId: number;
  };
  const { editId, clasaId } = state || {};

  const [userClassData, setUserClassData] = useState<UserClassModuleData>({
    elev: null,
    clasa: null,
  });

  const [students, setStudents] = useState<Array<User>>([]);
  const [classes, setClasses] = useState<Array<SimpleCollectionClass>>([]);

  const [userClassStatusOptions, setUserClassStatusOptions] = useState<
    StatusUserClasa[]
  >([]);
  const [selectedUserClassStatus, setSelectedUserClassStatus] = useState(1);

  const [autocompleteInputValues, setAutocompleteInputValues] = useState<{
    elev: string;
    clasa: string;
  }>({
    elev: '',
    clasa: '',
  });

  useEffect(() => {
    (async () => {
      const unallocatedStudents = await getUnallocatedStudents();
      const availableClasses = await getAvailableClasses();
      const userClassStatus = await getStatusUserClasa();
      setUserClassStatusOptions(userClassStatus['hydra:member']);

      let userClass: UserClass | undefined;

      if (editId) {
        userClass = (await getUserClass({ id: editId })) as UserClass;
        setUserClassData({
          elev: {
            nume: userClass.user.nume + ' ' + userClass.user.prenume,
            id: userClass.user.id,
          },
          clasa: {
            nume: userClass.clasa.codClasa,
            id: userClass.clasa.id,
          },
        });
      } else if (clasaId) {
        const selectedClass = availableClasses.find((e) => {
          return e.id == clasaId;
        });

        if (selectedClass)
          setUserClassData({
            elev: null,
            clasa: {
              nume: selectedClass.codClasa,
              id: selectedClass.id,
            },
          });
      }

      setStudents([
        ...unallocatedStudents,
        ...(userClass ? [userClass.user] : []),
      ]);
      setClasses(availableClasses);
    })();
  }, []);

  const isActionValid = () => {
    if (!userClassData) return false;

    if (!userContext.state.account?.user.scoala.id) return false;

    if (!isObjectValid(userClassData)) {
      toast.error('All fields must be filled!');
      return false;
    }
    return true;
  };

  const submitUserClassAction = async (
    addAnother: boolean,
    sameClass: boolean
  ) => {
    if (!isActionValid()) return;

    const { elev, clasa } = userClassData!;

    const payloadObject: UserClassPayload = {
      user: `/api/users/${elev!.id}`,
      clasa: `/api/clase/${clasa!.id}`,
      activ: true,
    };
    editId
      ? await editUserClass(editId, payloadObject)
      : await createUserClass(payloadObject);

    if (addAnother) {
      const unallocatedStudents = await getUnallocatedStudents();

      setStudents(unallocatedStudents);
      setUserClassData((prev) => ({
        elev: null,
        clasa: sameClass ? prev.clasa : null,
      }));
    } else {
      navigate('/table-user-class');
    }
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <AutocompleteInput
            autocompleteProps={{
              options: students.map((e) => ({
                nume: e.nume + ' ' + e.prenume,
                id: e.id,
              })),
              value: userClassData.elev,
              onChange: (
                event: any,
                newValue: {
                  nume: string;
                  id: number;
                } | null
              ) => {
                setUserClassData((prevValue) => {
                  return {
                    ...prevValue,
                    elev: newValue
                      ? {
                          nume: newValue.nume,
                          id: newValue.id,
                        }
                      : null,
                  };
                });
              },
              inputValue: autocompleteInputValues.elev,
              onInputChange: (event, newInputValue) => {
                setAutocompleteInputValues((prevValue) => {
                  return {
                    ...prevValue,
                    elev: newInputValue,
                  };
                });
              },
            }}
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.user.student'),
            }}
          />
          <AutocompleteInput
            autocompleteProps={{
              options: classes.map((e) => ({
                nume: e.codClasa,
                id: e.id,
              })),
              value: userClassData.clasa,
              disabled: !!clasaId,
              onChange: (
                event: any,
                newValue: {
                  nume: string;
                  id: number;
                } | null
              ) => {
                setUserClassData((prevValue) => {
                  return {
                    ...prevValue,
                    clasa: newValue
                      ? {
                          nume: newValue.nume,
                          id: newValue.id,
                        }
                      : null,
                  };
                });
              },
              inputValue: autocompleteInputValues.clasa,
              onInputChange: (event, newInputValue) => {
                setAutocompleteInputValues((prevValue) => {
                  return {
                    ...prevValue,
                    clasa: newInputValue,
                  };
                });
              },
            }}
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.user.class'),
            }}
          />
          {!!userClassStatusOptions.length && (
            <RegularSelect
              options={userClassStatusOptions.map((e) => ({
                value: e.id,
                label: e.label,
              }))}
              onChange={(e) => {
                setSelectedUserClassStatus(e);
              }}
              selectProps={{
                label: tModules('academicRecords.userClassStatus'),
                value: selectedUserClassStatus,
                fullWidth: true,
              }}
            />
          )}
        </div>
        <div className="flex gap-3">
          {/* {!editId && (
            <ButtonComp
              onButtonClick={() => submitUserClassAction(true, false)}
              buttonText={tModules('schoolAdmin.user.addAnother')}
              buttonProps={{
                variant: 'contained',
                defaultValue: 'Login',
              }}
              tailwindContainerClasses="flex justify-center"
            />
          )}
          {!editId && (
            <ButtonComp
              onButtonClick={() => submitUserClassAction(true, true)}
              buttonText={tModules('schoolAdmin.user.addAnotherInTheSameClass')}
              buttonProps={{
                variant: 'contained',
                defaultValue: 'Login',
              }}
              tailwindContainerClasses="flex justify-center"
            />
          )} */}
          <ButtonComp
            onButtonClick={() => submitUserClassAction(false, false)}
            buttonText={tCommon('inputs.saveButton')}
            buttonProps={{
              variant: 'contained',
              defaultValue: 'Login',
            }}
            tailwindContainerClasses="flex justify-center"
          />
        </div>
      </div>
    </div>
  );
};
