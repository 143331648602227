import { Download } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { publicServerUri } from 'config';

export const DownloadFile = ({
  fileUrl,
  fileName,
  displayButton,
}: {
  fileUrl: string;
  fileName?: string;
  displayButton?: boolean;
}) => {
  return (
    <Tooltip arrow title={fileName || 'Download'}>
      <a
        href={publicServerUri() + fileUrl}
        target="_blank"
        className="cursor-pointer text-blue-500 hover:underline focus:outline-none"
        download={publicServerUri() + fileUrl}
      >
        {displayButton || !fileName ? (
          <Download sx={{ fontSize: 30 }} />
        ) : (
          fileName
        )}
      </a>
    </Tooltip>
  );
};
