import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import {
  TermAkaModul,
  CollectionTermAkaModul,
  TermAkaModulPayload,
  SimpleTermAkaModul,
} from 'library/types/TermAkaModul';
export function TermAkaModulApi() {
  const { apiHandler } = JWTInterceptor();

  const getTermAkaModul = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<TermAkaModul | CollectionApiResponse<CollectionTermAkaModul>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/module/${id}` : `${serverUri()}/module/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createTermAkaModul = async (
    termAkaModulInfo: TermAkaModulPayload
  ): Promise<TermAkaModul> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/module`,
      data: termAkaModulInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Term was successfully created!',
    });

    return response.data;
  };

  const getAvailableModules = async (): Promise<Array<SimpleTermAkaModul>> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/available-modules`,
      axiosConfig: apiCallConfigTypes.get,
    });

    return response.data;
  };

  const editTermAkaModul = async (
    id: number,
    data: Partial<TermAkaModulPayload>
  ): Promise<TermAkaModul> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/module/${id}`,
      data: data,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The module was successfully modified!',
    });

    return response.data;
  };

  return {
    getTermAkaModul,
    createTermAkaModul,
    getAvailableModules,
    editTermAkaModul,
  };
}
