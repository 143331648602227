import { EvaluationApi } from 'api/evaluation.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { UserContext } from 'context/UserContext';
import { EvaluationQuestionPayload } from 'library/types/Evaluation';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const EvaluationQuestionModule = (): JSX.Element => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const { createEvaluationQuestion } = EvaluationApi();

  const [evaluationQuestion, setEvaluationQuestion] = useState<string>('');

  const isActionValid = () => {
    if (!evaluationQuestion) {
      toast.error(
        tModules(
          'schoolAdmin.evaluation.submitEvaluationQuestionValidationError'
        )
      );
      return false;
    }

    if (!userContext.state.account?.user.scoala.id) return false;

    return true;
  };

  const createEvaluationQuestionAction = async () => {
    if (!isActionValid()) return;

    const payloadObject: EvaluationQuestionPayload = {
      intrebare: evaluationQuestion,
    };
    await createEvaluationQuestion(payloadObject);
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules(
                'schoolAdmin.evaluation.moduleEvaluationQuestion'
              ),
              value: evaluationQuestion,
              onChange: (e) => {
                setEvaluationQuestion(e.target.value);
              },
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={createEvaluationQuestionAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Salveaza',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
