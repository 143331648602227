import { Typography, useTheme } from '@mui/material';
import { BurgerMenu } from 'common/BurgerMenu/BurgerMenu';
import LanguageSwitcher from 'common/components/utils/LanguageSwitcher';
import { UserContext, UserContextActionTypes } from 'context/UserContext';
import dayjs from 'dayjs';
import 'dayjs/locale/ro';
import { rolesOptions } from 'library/types/User';
import { TablesConfig } from 'modules/SchoolAdmin/Tables/TablesConfig';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { icons } from 'resources/images';
function RealTimeClock() {
  const theme = useTheme();
  const [currentDateTime, setCurrentDateTime] = useState(dayjs());

  useEffect(() => {
    // Update the date and time every second
    const interval = setInterval(() => {
      setCurrentDateTime(dayjs());
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Typography
        color={{ color: theme.palette.secondaries.text.main }}
        variant="headerLight"
      >
        {currentDateTime.format('dddd DD.MM.YYYY')}
      </Typography>
      <Typography
        color={{ color: theme.palette.secondaries.text.main }}
        variant="headerClock"
      >
        {currentDateTime.format('HH:mm')}
      </Typography>
    </>
  );
}

export const Header = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation('common');

  const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const { account } = userContext.state;
  dayjs.locale('ro');

  return (
    <div className="w-full relative">
      <div
        className={`w-full fixed top-0 left-0 w-full ${
          location.pathname === '/home' ? 'h-full' : 'h-[144px]'
        } bg-cover bg-center z-10`}
        style={{
          backgroundImage: `url(${
            location.pathname === '/home'
              ? icons.themes[userContext.state.theme].backgroundVector
              : icons.themes[userContext.state.theme].background
          })`,
        }}
      >
        <div className="relative h-36 w-full flex justify-between items-center shadow-md">
          {account?.user ? (
            <div className="pl-16 flex gap-4 w-1/3 items-center">
              <div className="flex flex-col items-center gap-1">
                <div className="w-16 h-16 overflow-hidden rounded-full border-4 border-gray-300">
                  <img
                    src={icons.general.mockProfilePic}
                    alt="Profile Picture"
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="flex text-center">
                  <Typography
                    color={{ color: theme.palette.secondaries.whiteText.main }}
                    variant="headerLightSmall"
                  >
                    {
                      rolesOptions.find((e) => e.value === account.selectedView)
                        ?.label
                    }
                  </Typography>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <Typography
                  color={{ color: theme.palette.secondaries.whiteText.main }}
                  variant="headerLight"
                >
                  {account.user.nume + ' ' + account.user.prenume}
                </Typography>
                <Typography
                  color={{ color: theme.palette.secondaries.whiteText.main }}
                  variant="headerLight"
                >
                  {account.user.scoala.numeScoala &&
                    (account.user.scoala.numeScoala.length > 25
                      ? account.user.scoala.numeScoala.substring(0, 26) + '...'
                      : account.user.scoala.numeScoala)}
                </Typography>
                <Typography
                  color={{ color: theme.palette.secondaries.whiteText.main }}
                  variant="headerLight"
                >
                  {account.user.localitate.nume}
                  {account.clasa &&
                    `, ${t('header.class')} ${account.clasa.codClasa}`}
                </Typography>
              </div>
            </div>
          ) : (
            <Typography
              color={{ color: theme.palette.secondaries.whiteText.main }}
              variant="headerWelcome"
              className="w-1/3 pl-16"
            >
              {t('header.welcome')}
            </Typography>
          )}
          <div className="flex w-1/3 justify-center items-center gap-2">
            <div className="relative">
              <img src={icons.general.logoTop} alt="Profile Picture" />
              {/* <Typography variant="headerBold">Platforma SMART-EDU</Typography> */}
            </div>
          </div>
          <div className="w-1/3 h-full flex">
            <div className="flex w-full justify-between items-center pr-12">
              <div className="flex flex-col justify-center items-center pl-8">
                <RealTimeClock />
              </div>
              {account && (
                <div className="flex flex-col h-full py-3 justify-between">
                  <div
                    onClick={() => navigate('/profile')}
                    className="flex items-center justify-end cursor-pointer"
                  >
                    <Typography
                      color={{ color: theme.palette.secondaries.text.main }}
                      variant="headerRegular"
                    >
                      {t('header.settings')}
                    </Typography>
                    <div
                      style={{ width: '40px' }}
                      className="flex justify-center"
                    >
                      <img
                        color={theme.palette.secondaries.text.main}
                        src={icons.themes[userContext.state.theme].setari}
                        width={30}
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => navigate('/home')}
                    className="flex items-center justify-end cursor-pointer"
                  >
                    <Typography
                      color={{ color: theme.palette.secondaries.text.main }}
                      variant="headerRegular"
                    >
                      {t('header.home')}
                    </Typography>
                    <div
                      style={{ width: '40px' }}
                      className="flex justify-center"
                    >
                      <img
                        src={icons.themes[userContext.state.theme].acasa}
                        width={30}
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toast.success(t('header.logoutMessage'));
                      userContext.dispatch({
                        type: UserContextActionTypes.LogOut,
                      });
                    }}
                    className="flex items-center cursor-pointer justify-end"
                  >
                    <Typography
                      color={{ color: theme.palette.secondaries.text.main }}
                      variant="headerRegular"
                    >
                      {t('header.logout')}
                    </Typography>
                    <div
                      style={{ width: '40px' }}
                      className="flex justify-center"
                    >
                      <img
                        src={icons.themes[userContext.state.theme].iesire}
                        width="20"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            height: '80px',
          }}
          className="relative flex justify-center items-center"
        >
          <div
            className="absolute"
            style={{
              transform: 'translate(calc(50% - 60px), -60px)',
            }}
          >
            <LanguageSwitcher />
          </div>
          {!!account && (
            <BurgerMenu
              isBurgerOpen={isBurgerOpen}
              setIsBurgerOpen={setIsBurgerOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};
