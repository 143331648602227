import { SchoolYearApi } from 'api/schoolYear.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { DatePickerInput } from 'common/components/inputs/DatePickerInput';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import dayjs, { Dayjs } from 'dayjs';
import { SchoolYear, SchoolYearPayload } from 'library/types/SchoolYear';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const SchoolYearModule = (): JSX.Element => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const { createSchoolYear, getSchoolYear, editSchoolYear } = SchoolYearApi();

  const location = useLocation();
  const state = location.state as {
    editId: number;
  };
  const { editId } = state || {};

  const [anInceput, setAnInceput] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);

  const [dataInceputAnScolar, setDataInceputAnScolar] = useState<Dayjs | null>(
    null
  );
  const [dataSfarsitAnScolar, setDataSfarsitAnScolar] = useState<Dayjs | null>(
    null
  );

  const [dataSfarsitPredare, setDataSfarsitPredare] = useState<Dayjs | null>(
    null
  );
  const [dataSfarsitPredareClasa8, setDataSfarsitPredareClasa8] =
    useState<Dayjs | null>(null);
  const [dataSfarsitPredareClasa12, setDataSfarsitPredareClasa12] =
    useState<Dayjs | null>(null);

  const [dataSfarsitCorigenta, setDataSfarsitCorigenta] =
    useState<Dayjs | null>(null);

  const [inceputEvaluareAnuala, setInceputEvaluareAnuala] =
    useState<Dayjs | null>(null);
  const [sfarsitEvaluareAnuala, setSfarsitEvaluareAnuala] =
    useState<Dayjs | null>(null);
  const [denumireMinister, setDenumireMinister] = useState<string>('');

  useEffect(() => {
    if (!editId) return;
    (async () => {
      const schoolYear = (await getSchoolYear({ id: editId })) as SchoolYear;

      setAnInceput(schoolYear.anInceput.toString());
      setIsActive(schoolYear.activ);
      setDataInceputAnScolar(dayjs(schoolYear.dataInceputAnScolar));
      setDataSfarsitAnScolar(dayjs(schoolYear.dataSfarsitAnScolar));
      setDataSfarsitPredare(dayjs(schoolYear.dataSfarsitPredare));
      setDataSfarsitPredareClasa8(dayjs(schoolYear.dataSfarsitPredareClasa8));
      setDataSfarsitPredareClasa12(dayjs(schoolYear.dataSfarsitPredareClasa12));
      setDataSfarsitCorigenta(dayjs(schoolYear.dataSfarsitCorigenta));
      setInceputEvaluareAnuala(dayjs(schoolYear.inceputEvaluareAnuala));
      setSfarsitEvaluareAnuala(dayjs(schoolYear.sfarsitEvaluareAnuala));
      setDenumireMinister(schoolYear.denumireMinister);
    })();
  }, []);

  const isActionValid = () => {
    if (!userContext.state.account?.user.scoala.id) return false;

    if (
      !anInceput ||
      !dataInceputAnScolar ||
      !dataSfarsitAnScolar ||
      !dataSfarsitPredare ||
      !dataSfarsitPredareClasa12 ||
      !dataSfarsitPredareClasa8 ||
      !dataSfarsitCorigenta ||
      !inceputEvaluareAnuala ||
      !sfarsitEvaluareAnuala
    ) {
      toast.error('All fields must be filled!');
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log(dataInceputAnScolar?.format());
  }, [dataInceputAnScolar]);

  const schoolYearAction = async (id?: number) => {
    if (!isActionValid()) return;

    const payloadObject: SchoolYearPayload = {
      scoala: `/api/scoli/${userContext.state.account!.user.scoala.id}`,
      anInceput: parseInt(anInceput as string),
      activ: isActive,
      dataInceputAnScolar: dataInceputAnScolar!.format('YYYY-MM-DD'),
      dataSfarsitAnScolar: dataSfarsitAnScolar!.format('YYYY-MM-DD'),
      dataSfarsitPredare: dataSfarsitPredare!.format('YYYY-MM-DD'),
      dataSfarsitPredareClasa8: dataSfarsitPredareClasa8!.format('YYYY-MM-DD'),
      dataSfarsitPredareClasa12:
        dataSfarsitPredareClasa12!.format('YYYY-MM-DD'),
      dataSfarsitCorigenta: dataSfarsitCorigenta!.format('YYYY-MM-DD'),
      inceputEvaluareAnuala: inceputEvaluareAnuala!.format('YYYY-MM-DD'),
      sfarsitEvaluareAnuala: sfarsitEvaluareAnuala!.format('YYYY-MM-DD'),
      denumireMinister,
    };

    !editId
      ? await createSchoolYear(payloadObject)
      : await editSchoolYear(editId, payloadObject);
    navigate('/school-years');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('schoolAdmin.schoolYear.schoolYearStartDate'),
              value: dataInceputAnScolar,
              onChange: (e) => {
                e?.add(6, 'hours');
                setDataInceputAnScolar(e);
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('schoolAdmin.schoolYear.schoolYearEndDate'),
              value: dataSfarsitAnScolar,
              onChange: (e) => {
                e?.add(6, 'hours');
                setDataSfarsitAnScolar(e);
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules(
                'schoolAdmin.schoolYear.secondExaminationEndDate'
              ),
              value: dataSfarsitCorigenta,
              onChange: (e) => {
                e?.add(6, 'hours');
                setDataSfarsitCorigenta(e);
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('schoolAdmin.schoolYear.teachingEndDate'),
              value: dataSfarsitPredare,
              onChange: (e) => {
                e?.add(6, 'hours');
                setDataSfarsitPredare(e);
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules(
                'schoolAdmin.schoolYear.twelfthGradeTeachingEndDate'
              ),
              value: dataSfarsitPredareClasa12,
              onChange: (e) => {
                e?.add(6, 'hours');
                setDataSfarsitPredareClasa12(e);
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules(
                'schoolAdmin.schoolYear.eighthGradeTeachingEndDate'
              ),
              value: dataSfarsitPredareClasa8,
              onChange: (e) => {
                e?.add(6, 'hours');
                setDataSfarsitPredareClasa8(e);
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules(
                'schoolAdmin.schoolYear.yearlyEvaluationStartDate'
              ),
              value: inceputEvaluareAnuala,
              onChange: (e) => {
                e?.add(6, 'hours');
                setInceputEvaluareAnuala(e);
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('schoolAdmin.schoolYear.yearlyEvaluationEndDate'),
              value: sfarsitEvaluareAnuala,
              onChange: (e) => {
                e?.add(6, 'hours');
                setSfarsitEvaluareAnuala(e);
              },
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.schoolYear.year'),
              value: anInceput,
              onChange: (e) => {
                if (e.target.value === '' || /^[0-9]*$/.test(e.target.value))
                  setAnInceput(e.target.value.replace('e', ''));
              },
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.schoolYear.ministryName'),
              value: denumireMinister,
              onChange: (e) => {
                setDenumireMinister(e.target.value);
              },
            }}
          />
          <RegularSelect
            options={[
              {
                label: tCommon('basic.yes'),
                value: true,
              },
              {
                label: tCommon('basic.no'),
                value: false,
              },
            ]}
            onChange={(e) => {
              setIsActive(e);
            }}
            selectProps={{
              label: tModules('schoolAdmin.schoolYear.activeSelect'),
              value: isActive,
              fullWidth: true,
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={schoolYearAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Login',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
