import dayjs from 'dayjs';
import { Catalog } from 'library/types/Catalog';
import {
  ClassProfile,
  ClassSpecialization,
  CollectionClass,
} from 'library/types/Class';
import {
  CollectionEvaluationConfig,
  EvaluationForProfessorResponse,
  EvaluationForStudentResponse,
  EvaluationQuestion,
  YearlyEvaluationQuestion,
  YearlyEvaluationResponse,
} from 'library/types/Evaluation';
import { Homework, StudentHomework } from 'library/types/Homework';
import { Lesson } from 'library/types/Lesson';
import { Catedra, CollectionSchool, SchoolProfile } from 'library/types/School';
import { CollectionSchoolYear } from 'library/types/SchoolYear';
import { StudyYear } from 'library/types/StudyYear';
import {
  SubjectConfigCollection as CollectionSubjectConfig,
  SubjectTypeCollection as CollectionSubjectType,
} from 'library/types/Subject';
import { CollectionTermAkaModul } from 'library/types/TermAkaModul';
import {
  Test,
  TestQuestion,
  TestQuestionAnswer,
  TestQuestionToTest,
} from 'library/types/Test';
import { CollectionTimeSlot } from 'library/types/TimeSlot';
import { CollectionUser, UserRoles } from 'library/types/User';
import { MRT_ColumnDef, MaterialReactTableProps } from 'material-react-table';
import React from 'react';
import { CatalogActions } from './CellComponents/CatalogActions';
import { DownloadFile } from './CellComponents/DownloadFile';
import { EditAction } from './CellComponents/EditAction';
import {
  EvaluationsConfigQuestions,
  EvaluationsConfigResponses,
} from './CellComponents/EvaluationConfigQuestions';
import { HomeworkActions } from './CellComponents/HomeworkActions';
import { LessonActions } from './CellComponents/LessonActions';
import { StudentHomeworkActions } from './CellComponents/StudentHomeworkActions';
import { StudentTeacher } from './CellComponents/StudentTeacher';
import { TestActions } from './CellComponents/TestActions';
import { YesNo } from './CellComponents/YesNo';
import { UserClass } from 'library/types/UserClass';
import { ClassActions } from './CellComponents/ClassActions';
import {
  ClosedSituationTableEntry,
  FullAverage,
  NonPrimaryAverage,
  PrimaryAverage,
  StudentSituationEnum,
} from 'library/types/Average';
import { parseHourToTimezone } from 'library/helpers/helper';
import { UserRolesCell } from './CellComponents/UserRoles';
export interface TableConfig<T extends Record<string, any>> {
  getCollectionApi: string;
  columns: MRT_ColumnDef<T>[];
  navigateOnEdit?: string;
  navigateOnCreate?: string;
  tableProps?: Partial<MaterialReactTableProps<T>>;
  path: string;
  tableConfigParams?: { [key: string]: string };
  tableTitle: string;
  allowedRolesToCreate?: UserRoles[];
  isNotHydrated?: boolean;
}

export const TablesConfig = () => {
  const selectedView = localStorage.getItem('smart-edu')
    ? JSON.parse(localStorage.getItem('smart-edu')!).account?.selectedView
    : undefined;

  const UsersConfig: TableConfig<CollectionUser> = {
    path: '/users',
    navigateOnCreate: '/user/create',
    getCollectionApi: 'user',
    tableTitle: 'burgerMenu.adminSubmenus.userTable',
    columns: [
      {
        accessorKey: 'idUserScoala',
        header: 'id',
        maxSize: 130,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
      },
      {
        accessorKey: 'nume',
        header: 'lastName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
      },
      {
        accessorKey: 'prenume',
        header: 'firstName',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
      },
      {
        accessorKey: 'telefon',
        header: 'phoneNumber',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
      },
      {
        accessorKey: 'email',
        header: 'email',
        enableSorting: false,
        enableFilterMatchHighlighting: false,
      },
      {
        accessorKey: 'nrMatricol',
        header: 'schoolRegistrationNumber',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
      },
      {
        accessorFn: (row) => {
          console.log(row.roles);
          return row && row.roles
            ? React.createElement(UserRolesCell, {
                userRoles: row.roles.filter((e) => e !== UserRoles.ROLE_USER),
              })
            : '';
        },
        header: 'roles',
        accessorKey: 'roles',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        filterFn: 'equals',
        filterSelectOptions: [
          { text: 'Director', value: 'ROLE_DIRECTOR' },
          { text: 'Secretar', value: 'ROLE_SECRETAR' },
          { text: 'Diriginte', value: 'ROLE_DIRIGINTE' },
          { text: 'Invatator', value: 'ROLE_INVATATOR' },
          { text: 'Profesor', value: 'ROLE_PROFESOR' },
          { text: 'Parinte', value: 'ROLE_PARINTE' },
          { text: 'Elev', value: 'ROLE_ELEV' },
        ],
        filterVariant: 'select',
      },
      {
        accessorFn: (row) => {
          return React.createElement(YesNo, { isYes: !!row.activ });
        },
        accessorKey: 'activ',
        header: 'active',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        filterFn: 'equals',
        filterSelectOptions: [
          { text: 'Activ', value: '1' },
          { text: 'Inactiv', value: '0' },
        ],
        filterVariant: 'select',
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(EditAction, {
            id: row.id,
            path: '/user/edit',
          });
        },
      },
    ] as MRT_ColumnDef<CollectionUser>[],
  };

  const SchoolsConfig: TableConfig<CollectionSchool> = {
    path: '/schools',
    getCollectionApi: 'school',
    tableTitle: 'burgerMenu.adminSubmenus.schoolTable',
    columns: [
      {
        accessorKey: 'id',
        header: 'id',
        maxSize: 130,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'numeScoala',
        header: 'school',
        enableSorting: false,
        enableResizing: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'adresa',
        header: 'address',
        enableSorting: false,
        enableResizing: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'cicluPrimar',
        accessorFn: (row) => {
          return React.createElement(YesNo, { isYes: !!row.cicluPrimar });
        },
        header: 'firstCycle',
        maxSize: 100,
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'cicluGimnazial',
        accessorFn: (row) => {
          return React.createElement(YesNo, { isYes: !!row.cicluGimnazial });
        },
        header: 'secondCycle',
        maxSize: 100,
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'cicluLiceal',
        accessorFn: (row) => {
          return React.createElement(YesNo, { isYes: !!row.cicluLiceal });
        },
        header: 'thirdCycle',
        maxSize: 100,
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tara',
        accessorFn: (row) => {
          return row.tara ? row.tara.nume : '';
        },
        header: 'country',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'judet',
        accessorFn: (row) => {
          return row.judet ? row.judet.nume : '';
        },
        header: 'county',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'localitate',
        accessorFn: (row) => {
          return row.localitate ? row.localitate.nume : '';
        },
        header: 'city',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'profilScoala',
        accessorFn: (row) => {
          return row.profilScoala ? row.profilScoala.tipProfilScoala : '';
        },
        header: 'schoolProfile',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
    ] as MRT_ColumnDef<CollectionSchool>[],
    tableProps: {
      enableFilters: false,
      enableColumnActions: false,
    },
  };

  const ClassesConfig: TableConfig<CollectionClass> = {
    path: '/classes',
    navigateOnCreate: '/individual-class/create',
    getCollectionApi: 'class',
    tableTitle: 'burgerMenu.adminSubmenus.classTable',
    columns: [
      {
        accessorKey: 'id',
        header: 'id',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'anStudiu.anStudiu',
        accessorFn: (row) => {
          return row && row.anStudiu ? row.anStudiu.anStudiu : '';
        },
        header: 'studyYear',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'codClasa',
        header: 'class',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'profilClasa.tipProfilClasa',
        accessorFn: (row) => {
          return row.profilClasa ? row.profilClasa.tipProfilClasa : '';
        },
        header: 'classProfile',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'specializare.tipSpecializare',
        accessorFn: (row) => {
          return row.specializare ? row.specializare.tipSpecializare : '';
        },
        header: 'specialization',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        id: 'cadruDidacticResponsabil.nume',
        accessorFn: (row) => {
          return row.cadruDidacticResponsabil
            ? row.cadruDidacticResponsabil.nume +
                ' ' +
                row.cadruDidacticResponsabil.prenume
            : '';
        },
        header: 'classHeadmaster',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'maxOreSaptamana',
        header: 'maximumNumberOfHours',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 200,
        accessorFn: (row) => {
          if (!row || !row.id || !row.anStudiu) return;
          console.log(row.codClasa);
          return React.createElement(ClassActions, {
            id: row.id,
            path: '/individual-class/edit',
            isPrimaryGrade: row.anStudiu.anStudiu < 5,
            codClasa: row.codClasa,
          });
        },
      },
    ] as MRT_ColumnDef<CollectionClass>[],
  };

  const SchoolYearConfig: TableConfig<CollectionSchoolYear> = {
    path: '/school-years',
    navigateOnCreate: '/school-year',
    getCollectionApi: 'schoolYear',
    tableTitle: 'burgerMenu.adminSubmenus.schoolYearTable',
    columns: [
      {
        accessorKey: 'anInceput',
        header: 'year',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataInceputAnScolar',
        header: 'schoolYearStartDate',
        accessorFn: (row) => {
          return dayjs(row.dataInceputAnScolar).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataSfarsitAnScolar',
        header: 'schoolYearEndDate',
        accessorFn: (row) => {
          return dayjs(row.dataSfarsitAnScolar).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataSfarsitPredare',
        header: 'teachingEndDate',
        accessorFn: (row) => {
          return dayjs(row.dataSfarsitPredare).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataSfarsitPredareClasa8',
        header: 'eighthGradeTeachingEndDate',
        accessorFn: (row) => {
          return dayjs(row.dataSfarsitPredareClasa8).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataSfarsitPredareClasa12',
        header: 'twelfthGradeTeachingEndDate',
        accessorFn: (row) => {
          return dayjs(row.dataSfarsitPredareClasa12).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataSfarsitCorigenta',
        header: 'secondExaminationEndDate',
        accessorFn: (row) => {
          return dayjs(row.dataSfarsitCorigenta).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'inceputEvaluareAnuala',
        header: 'yearlyEvaluationStartDate',
        accessorFn: (row) => {
          return dayjs(row.inceputEvaluareAnuala).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'sfarsitEvaluareAnuala',
        header: 'yearlyEvaluationEndDate',
        accessorFn: (row) => {
          return dayjs(row.sfarsitEvaluareAnuala).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'activ',
        header: 'active',
        accessorFn: (row) => {
          return React.createElement(YesNo, { isYes: !!row.activ });
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(EditAction, {
            id: row.id,
            path: '/school-year',
          });
        },
      },
    ] as MRT_ColumnDef<CollectionSchoolYear>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const StudyYearConfig: TableConfig<StudyYear> = {
    path: '/study-years',
    getCollectionApi: 'studyYear',
    tableTitle: 'burgerMenu.adminSubmenus.schoolYearTable',
    columns: [
      {
        accessorKey: 'id',
        header: 'id',
        maxSize: 130,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'anStudiu.anStudiu',
        header: 'studyYear',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
    ] as MRT_ColumnDef<StudyYear>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const TimeSlotConfig: TableConfig<CollectionTimeSlot> = {
    path: '/time-slots',
    navigateOnCreate: '/time-slot',
    getCollectionApi: 'timeSlot',
    tableTitle: 'burgerMenu.adminSubmenus.timeSlotTable',
    columns: [
      {
        accessorKey: 'id',
        header: 'id',
        maxSize: 130,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'oraStart',
        header: 'firstClassStart',
        accessorFn: (row) => {
          if (!row || !row.oraStart) return;
          return parseHourToTimezone(row.oraStart);
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'oraSfarsit',
        header: 'firstClassEnd',
        accessorFn: (row) => {
          if (!row || !row.oraSfarsit) return;
          return parseHourToTimezone(row.oraSfarsit);
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'anScolar.anInceput',
        header: 'schoolYear',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(EditAction, {
            id: row.id,
            path: '/time-slot',
          });
        },
      },
    ] as MRT_ColumnDef<CollectionTimeSlot>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const TermAkaModulConfig: TableConfig<CollectionTermAkaModul> = {
    path: '/terms',
    navigateOnCreate: '/term',
    getCollectionApi: 'termAkaModul',
    tableTitle: 'burgerMenu.adminSubmenus.moduleTable',
    columns: [
      {
        accessorKey: 'id',
        header: 'id',
        maxSize: 130,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'anScolar.anInceput',
        header: 'schoolYear',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'ordine',
        header: 'order',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tipModul',
        header: 'moduleType',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataInceput',
        header: 'startDate',
        accessorFn: (row) => {
          return dayjs(row.dataInceput).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataSfarsit',
        header: 'endDate',
        accessorFn: (row) => {
          return dayjs(row.dataSfarsit).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'inceputEvaluareModul',
        header: 'moduleEvaluationStartDate',
        accessorFn: (row) => {
          return dayjs(row.inceputEvaluareModul).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'sfarsitEvaluareModul',
        header: 'moduleEvaluationEndDate',
        accessorFn: (row) => {
          return dayjs(row.sfarsitEvaluareModul).format('DD.MM.YYYY');
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'activ',
        header: 'active',
        accessorFn: (row) => {
          return React.createElement(YesNo, { isYes: !!row.activ });
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(EditAction, {
            id: row.id,
            path: '/term',
          });
        },
      },
    ] as MRT_ColumnDef<CollectionTermAkaModul>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const ClassProfileConfig: TableConfig<ClassProfile> = {
    path: '/class-profiles',
    navigateOnCreate: '/class-profile',
    getCollectionApi: 'classProfile',
    tableTitle: 'burgerMenu.adminSubmenus.classProfileTable',
    columns: [
      {
        accessorKey: 'id',
        header: 'id',
        maxSize: 130,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tipProfilClasa',
        header: 'classProfile',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
    ] as MRT_ColumnDef<ClassProfile>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const SchoolProfileConfig: TableConfig<SchoolProfile> = {
    path: '/school-profiles',
    getCollectionApi: 'schoolProfile',
    tableTitle: 'burgerMenu.adminSubmenus.schoolProfileTable',
    columns: [
      {
        accessorKey: 'id',
        header: 'id',
        maxSize: 130,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tipProfilScoala',
        header: 'schoolProfile',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
    ] as MRT_ColumnDef<SchoolProfile>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const SubjectTypeConfig: TableConfig<CollectionSubjectType> = {
    path: '/subject-types',
    navigateOnCreate: '/subject-type',
    getCollectionApi: 'subjectType',
    tableTitle: 'burgerMenu.adminSubmenus.subjectTypeTable',
    columns: [
      {
        accessorKey: 'id',
        header: 'id',
        maxSize: 130,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        id: 'anStudiu.anStudiu',
        accessorFn: (row) => {
          if (!row || !row.anStudiu) return;
          return row.anStudiu.anStudiu;
        },
        header: 'studyYear',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorFn: (row) => {
          if (!row?.tipMaterie || typeof row.tipMaterie !== 'string') return;
          return row.tipMaterie;
        },
        id: 'tipMaterie',
        header: 'subjectType',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorFn: (row) => {
          if (!row || !row.catedra?.denumireCatedra) return;
          return row.catedra.denumireCatedra;
        },
        accessorKey: 'catedra.denumireCatedra',
        header: 'department',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorFn: (row) => {
          if (!row || !row.disciplina?.denumire) return;
          return row?.disciplina?.denumire;
        },
        header: 'discipline',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(EditAction, {
            id: row.id,
            path: '/subject-type',
          });
        },
      },
    ] as MRT_ColumnDef<CollectionSubjectType>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const SubjectConfigurationConfig: TableConfig<CollectionSubjectConfig> = {
    path: '/subject-configs',
    navigateOnCreate: '/subject-config',
    getCollectionApi: 'subjectConfig',
    tableTitle: 'burgerMenu.adminSubmenus.subjectProfessorTable',
    columns: [
      {
        accessorKey: 'id',
        header: 'id',
        maxSize: 130,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        id: 'tipMaterie.tipMaterie',
        accessorFn: (row) => {
          if (!row?.tipMaterie?.tipMaterie) return;
          return row.tipMaterie.tipMaterie;
        },
        header: 'subjectType',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'user.nume',
        header: 'professorLastName',
        accessorFn: (row) => {
          return row?.user ? row.user.nume : '';
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'user.prenume',
        header: 'professorFirstName',
        accessorFn: (row) => {
          return row?.user ? row.user.prenume : '';
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      // {
      //   accessorKey: 'teza',
      //   header: 'midTermPaper',
      //   accessorFn: (row) => {
      //     return React.createElement(YesNo, { isYes: !!row.teza });
      //   },
      //   enableSorting: false,
      //   enableResizing: false,
      //   enableFilterMatchHighlighting: false,
      //   enableColumnFilter: true,
      //   filterFn: 'equals',
      //   filterSelectOptions: [
      //     { text: 'Da', value: '1' },
      //     { text: 'Nu', value: '0' },
      //   ],
      //   filterVariant: 'select',
      // },
      // {
      //   accessorKey: 'procentTeza',
      //   header: 'midTermPaperPercentage',
      //   accessorFn: (row) => {
      //     return row.procentTeza || '';
      //   },
      //   enableSorting: false,
      //   enableResizing: false,
      //   enableFilterMatchHighlighting: false,
      //   enableColumnFilter: false,
      // },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(EditAction, {
            id: row.id,
            path: '/subject-config',
          });
        },
      },
    ] as MRT_ColumnDef<CollectionSubjectConfig>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const EvaluationQuestionConfig: TableConfig<EvaluationQuestion> = {
    path: '/evaluation-questions',
    navigateOnCreate: '/evaluation-question',
    getCollectionApi: 'evaluationQuestions',
    tableTitle: 'otherRoutesTitles.evaluationModuleQuestionsTable',
    columns: [
      {
        accessorKey: 'id',
        header: 'id',
        maxSize: 130,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'intrebare',
        header: 'question',
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
    ] as MRT_ColumnDef<EvaluationQuestion>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const EvaluationConfigurationConfig: TableConfig<CollectionEvaluationConfig> =
    {
      path: '/evaluation-configurations',
      navigateOnCreate: '/evaluation-question-config',
      getCollectionApi: 'evaluationQuestionsConfig',
      tableTitle: 'otherRoutesTitles.evaluationModuleQuestionConfigTable',
      columns: [
        {
          accessorKey: 'id',
          header: 'id',
          maxSize: 130,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
        },
        {
          header: 'module',
          accessorFn: (row) => {
            if (!row || !row.modul) return;

            return (
              row.modul.anScolar.anInceput +
              ' Ordine: ' +
              row.modul.ordine +
              ' - ' +
              (row.modul.activ ? 'Activ' : 'Inactiv')
            );
          },
          enableColumnFilter: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
        },
        {
          accessorKey: 'elevEvaluat',
          header: 'evaluatedUserType',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
          accessorFn: (row) => {
            return React.createElement(StudentTeacher, {
              isStudent: !!row.elevEvaluat,
            });
          },
        },
        {
          accessorKey: 'intrebari',
          header: 'questions',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
          Cell: ({ cell, row }) => {
            if (!row.original.intrebari) return;
            return React.createElement(EvaluationsConfigQuestions, {
              intrebari: row.original.intrebari,
            });
          },
        },
      ] as MRT_ColumnDef<CollectionEvaluationConfig>[],
      tableProps: {
        enableColumnActions: false,
        enableRowActions: false,
      },
    };

  const AnnualEvaluationQuestionConfig: TableConfig<YearlyEvaluationQuestion> =
    {
      path: '/yearly-evaluation-questions',
      navigateOnCreate: '/yearly-evaluation-question',
      getCollectionApi: 'yearlyEvaluationQuestions',
      tableTitle: 'otherRoutesTitles.evaluationAnnualQuestionsTable',
      columns: [
        {
          accessorKey: 'id',
          header: 'id',
          maxSize: 130,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
        },
        {
          accessorKey: 'anScolar.anInceput',
          header: 'schoolYear',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
        },
        {
          accessorKey: 'intrebare',
          header: 'question',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
        },
      ] as MRT_ColumnDef<YearlyEvaluationQuestion>[],
      tableProps: {
        enableColumnActions: false,
        enableRowActions: false,
      },
    };

  const EvaluationForProfessorResponseConfig: TableConfig<EvaluationForProfessorResponse> =
    {
      path: '/professors-evaluation-responses',
      getCollectionApi: 'evaluationForProfessorResponse',
      tableTitle: 'otherRoutesTitles.evaluationModuleStudentsAnswersTable',
      tableConfigParams: {
        'order[configEvaluare.modul.anScolar.anInceput]': 'DESC',
        'order[configEvaluare.modul.ordine]': 'DESC',
        'order[oraEvaluata.materie.tipMaterie.tipMaterie]': 'ASC',
      },
      columns: [
        {
          accessorKey: 'id',
          header: 'id',
          maxSize: 50,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
        },
        {
          accessorKey: 'oraEvaluata.materie.tipMaterie.tipMaterie',
          maxSize: 100,
          header: 'subject',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          accessorKey: 'configEvaluare.modul.anScolar.anInceput',
          maxSize: 100,
          header: 'schoolYear',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          accessorKey: 'configEvaluare.modul.ordine',
          maxSize: 100,
          header: 'order',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          accessorKey: 'oraEvaluata.materie.tipMaterie.anStudiu.anStudiu',
          header: 'studyYear',
          maxSize: 100,
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          header: 'professorLastName',
          enableSorting: false,
          maxSize: 100,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
          accessorFn: (row) => {
            if (!row || !row.oraEvaluata) return;
            return (
              row.oraEvaluata.materie.user.nume +
              ' ' +
              row.oraEvaluata.materie.user.prenume
            );
          },
        },
        {
          accessorKey: 'raspunsuri',
          header: 'answers',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
          Cell: ({ cell, row }) => {
            if (!row.original.raspunsuri) return;
            return React.createElement(EvaluationsConfigResponses, {
              raspunsuri: row.original.raspunsuri,
            });
          },
        },
      ] as MRT_ColumnDef<EvaluationForProfessorResponse>[],
      tableProps: {
        enableColumnActions: false,
        enableRowActions: false,
      },
    };

  const EvaluationForStudentResponseConfig: TableConfig<EvaluationForStudentResponse> =
    {
      path: '/students-evaluation-responses',
      getCollectionApi: 'evaluationForStudentResponse',
      tableTitle: 'otherRoutesTitles.evaluationModuleProfessorAnswersTable',
      tableConfigParams: {
        'order[configEvaluare.modul.anScolar.anInceput]': 'DESC',
        'order[configEvaluare.modul.ordine]': 'DESC',
        'order[userRespondent.nume]': 'ASC',
      },
      columns: [
        {
          accessorKey: 'id',
          header: 'id',
          maxSize: 50,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
        },
        {
          accessorKey: 'configEvaluare.modul.anScolar.anInceput',
          maxSize: 100,
          header: 'schoolYear',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          accessorKey: 'configEvaluare.modul.ordine',
          maxSize: 100,
          header: 'order',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          accessorKey: 'userRespondent.prenume',
          header: 'firstName',
          enableSorting: false,
          maxSize: 100,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          accessorKey: 'userRespondent.nume',
          header: 'lastName',
          enableSorting: false,
          maxSize: 100,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          accessorKey: 'raspunsuri',
          header: 'answers',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
          Cell: ({ cell, row }) => {
            if (!row.original.raspunsuri) return;
            return React.createElement(EvaluationsConfigResponses, {
              raspunsuri: row.original.raspunsuri,
            });
          },
        },
      ] as MRT_ColumnDef<EvaluationForStudentResponse>[],
      tableProps: {
        enableColumnActions: false,
        enableRowActions: false,
      },
    };

  const YearlyEvaluationResponseConfig: TableConfig<YearlyEvaluationResponse> =
    {
      path: '/yearly-evaluation-responses',
      getCollectionApi: 'yearlyEvaluationResponse',
      tableTitle: 'otherRoutesTitles.evaluationAnnualAnswersTable',
      tableConfigParams: {
        'order[intrebareEvaluareAnuala.anScolar.anInceput]': 'DESC',
        'order[userRespondent.nume]': 'ASC',
      },
      columns: [
        {
          accessorKey: 'id',
          header: 'id',
          maxSize: 50,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
        },
        {
          accessorKey: 'intrebareEvaluareAnuala.anScolar.anInceput',
          maxSize: 100,
          header: 'schoolYear',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          header: 'evaluatedStudent',
          enableSorting: false,
          maxSize: 100,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
          accessorFn: (row) => {
            if (!row || !row.userEvaluat) return;
            return row.userEvaluat.nume + ' ' + row.userEvaluat.prenume;
          },
        },
        {
          accessorKey: 'userRespondent.prenume',
          header: 'firstName',
          enableSorting: false,
          maxSize: 100,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          accessorKey: 'userRespondent.nume',
          header: 'lastName',
          enableSorting: false,
          maxSize: 100,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },
        {
          header: 'question',
          accessorKey: 'intrebareEvaluareAnuala.intrebare',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: true,
        },

        {
          accessorKey: 'raspuns',
          header: 'answer',
          enableSorting: false,
          enableResizing: false,
          enableFilterMatchHighlighting: false,
          enableColumnFilter: false,
        },
      ] as MRT_ColumnDef<YearlyEvaluationResponse>[],
      tableProps: {
        enableColumnActions: false,
        enableRowActions: false,
      },
    };

  const CatalogConfig: TableConfig<Catalog> = {
    path: '/catalogue-entries',
    getCollectionApi: 'catalog',
    tableTitle: 'burgerMenu.catalogueMenu',
    columns: [
      {
        accessorKey: 'orarDetaliat.data',
        header: 'catalogueDate',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.orarDetaliat) return;
          return dayjs(row.orarDetaliat.data).format('DD.MM.YYYY');
        },
      },
      {
        accessorKey: 'clasa.codClasa',
        header: 'class',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'materie.tipMaterie.tipMaterie',
        header: 'subject',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        id: 'user.nume',
        header: 'lastName',
        accessorKey: 'user.nume',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
      },
      {
        id: 'user.prenume',
        header: 'firstName',
        accessorKey: 'user.prenume',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
      },
      {
        accessorKey: 'nota',
        header: 'gradeOrCalificativ',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row) return '';
          if (row.nota) return row.nota;
          if (row.calificativ) return row.calificativ;
        },
      },
      {
        accessorKey: 'absenta',
        header: 'absence',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(YesNo, { isYes: !!row.absenta });
        },
      },
      {
        accessorKey: 'motivat',
        header: 'motivated',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row) return;
          if (row.absenta) {
            return React.createElement(YesNo, { isYes: !!row.motivat });
          } else {
            return '';
          }
        },
      },
      {
        accessorKey: 'observatii',
        header: 'observations',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(CatalogActions, {
            catalogId: row.absenta && !row.motivat ? row.id : null,
          });
        },
      },
    ] as MRT_ColumnDef<Catalog>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const HomeworkConfig: TableConfig<Homework> = {
    path: '/table-homework',
    navigateOnCreate: '/homework',
    allowedRolesToCreate: [UserRoles.ROLE_INVATATOR, UserRoles.ROLE_PROFESOR],
    getCollectionApi: 'homework',
    tableTitle: 'burgerMenu.homeworkSubmenus.homeworkTable',
    columns: [
      {
        accessorKey: 'titlu',
        header: 'title',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'clasa.codClasa',
        maxSize: 50,
        header: 'class',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'materie.tipMaterie.tipMaterie',
        header: 'subjectType',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'startDate',
        maxSize: 100,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.startsAt) return;
          return dayjs(row.startsAt).format('DD.MM.YYYY');
        },
      },
      {
        header: 'endDate',
        maxSize: 100,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.endsAt) return;
          return dayjs(row.endsAt).format('DD.MM.YYYY');
        },
      },
      {
        header: 'active',
        maxSize: 50,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || typeof row.activ !== 'boolean') return;
          return React.createElement(YesNo, { isYes: !!row.activ });
        },
      },
      {
        header: 'file',
        maxSize: 100,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.fisier) return;
          return React.createElement(DownloadFile, {
            fileUrl: row.fisier.contentUrl,
            fileName: row.fisier.urlPath,
            displayButton: true,
          });
        },
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(HomeworkActions, {
            homeworkId: row.id,
            isActive: row.activ,
            canSubmitHomework:
              (!row.temeElevi || !row.temeElevi.length) &&
              row.activ &&
              dayjs().valueOf() < dayjs(row.endsAt).valueOf() &&
              dayjs().valueOf() > dayjs(row.startsAt).valueOf(),
            canEdit: !row.temeElevi || !row.temeElevi.length,
          });
        },
      },
    ] as MRT_ColumnDef<Homework>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const StudentHomeworkConfig: TableConfig<StudentHomework> = {
    path: '/table-student-homework',
    getCollectionApi: 'studentHomework',
    tableTitle: '',
    columns: [
      {
        accessorKey: 'tema.titlu',
        header: 'title',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'tema.clasa.codClasa',
        header: 'class',
        maxSize: 80,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'tema.materie.tipMaterie.tipMaterie',
        header: 'subjectType',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tema.user.numeComplet',
        header: 'professorLastName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user.prenume',
        header: 'firstName',
        maxSize: 100,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user.nume',
        header: 'lastName',
        maxSize: 100,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        header: 'sentHomework',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.fisier) return;
          return React.createElement(DownloadFile, {
            fileUrl: row.fisier.contentUrl,
            fileName: row.fisier.urlPath,
            displayButton: true,
          });
        },
      },
      {
        header: 'sentHomeworkDate',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.created) return;
          return dayjs(row.created).format('DD.MM.YYYY');
        },
      },
      {
        header: 'reviewedHomework',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.fisierTemaCorectata) return;
          return React.createElement(DownloadFile, {
            fileUrl: row.fisierTemaCorectata.contentUrl,
            fileName: row.fisierTemaCorectata.urlPath,
            displayButton: true,
          });
        },
      },
      {
        header: 'reviewedHomeworkDate',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.updated) return;
          return dayjs(row.created).format('DD.MM.YYYY');
        },
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row || row.fisierTemaCorectata) return;
          return React.createElement(StudentHomeworkActions, {
            studentHomework: row,
          });
        },
      },
    ] as MRT_ColumnDef<StudentHomework>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const SolvedStudentHomeworkConfig: TableConfig<StudentHomework> = {
    path: '/table-student-homework/solved',
    getCollectionApi: 'studentHomework',
    tableTitle: '',
    columns: [
      {
        accessorKey: 'tema.titlu',
        header: 'title',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'tema.clasa.codClasa',
        header: 'class',
        maxSize: 80,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'tema.materie.tipMaterie.tipMaterie',
        header: 'subjectType',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tema.user.numeComplet',
        header: 'professorLastName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user.prenume',
        header: 'firstName',
        maxSize: 100,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user.nume',
        header: 'lastName',
        maxSize: 100,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        header: 'sentHomework',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.fisier) return;
          return React.createElement(DownloadFile, {
            fileUrl: row.fisier.contentUrl,
            fileName: row.fisier.urlPath,
            displayButton: true,
          });
        },
      },
      {
        header: 'sentHomeworkDate',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.created) return;
          return dayjs(row.created).format('DD.MM.YYYY');
        },
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(StudentHomeworkActions, {
            studentHomework: row,
          });
        },
      },
    ] as MRT_ColumnDef<StudentHomework>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const ReviewedStudentHomeworkConfig: TableConfig<StudentHomework> = {
    path: '/table-student-homework/reviewed',
    getCollectionApi: 'studentHomework',
    tableTitle: '',
    columns: [
      {
        accessorKey: 'tema.titlu',
        header: 'title',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'tema.clasa.codClasa',
        header: 'class',
        maxSize: 80,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'tema.materie.tipMaterie.tipMaterie',
        header: 'subjectType',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tema.user.numeComplet',
        header: 'professorLastName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user.prenume',
        header: 'firstName',
        maxSize: 100,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user.nume',
        header: 'lastName',
        maxSize: 100,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        header: 'reviewedHomework',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.fisierTemaCorectata) return;
          return React.createElement(DownloadFile, {
            fileUrl: row.fisierTemaCorectata.contentUrl,
            fileName: row.fisierTemaCorectata.urlPath,
            displayButton: true,
          });
        },
      },
      {
        header: 'reviewedHomeworkDate',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.updated) return;
          return dayjs(row.created).format('DD.MM.YYYY');
        },
      },
      {
        accessorKey: 'comentariu',
        header: 'comment',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
    ] as MRT_ColumnDef<StudentHomework>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const LessonConfig: TableConfig<Lesson> = {
    path: '/table-lesson',
    navigateOnCreate: '/lesson/create',
    allowedRolesToCreate: [UserRoles.ROLE_INVATATOR, UserRoles.ROLE_PROFESOR],
    getCollectionApi: 'lesson',
    tableTitle: 'burgerMenu.lessonsSubmenus.lessonTable',
    columns: [
      {
        accessorKey: 'materie.tipMaterie.tipMaterie',
        header: 'subjectType',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user.numeComplet',
        header: 'professorLastName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'subiect',
        header: 'topic',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'lesson',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.fisier) return;
          return React.createElement(DownloadFile, {
            fileUrl: row.fisier.contentUrl,
            fileName: row.fisier.urlPath,
            displayButton: true,
          });
        },
      },
      ...(selectedView !== UserRoles.ROLE_ELEV
        ? [
            {
              header: 'actions',
              // id: 'actions',
              enableResizing: false,
              enableFilterMatchHighlighting: false,
              enableColumnFilter: false,
              maxSize: 100,
              accessorFn: (row: Lesson) => {
                if (!row) return;
                return React.createElement(LessonActions, {
                  lessonId: row.id,
                });
              },
            },
          ]
        : []),
    ] as MRT_ColumnDef<Lesson>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const TestProfessorsConfig: TableConfig<Test> = {
    path: '/table-test/professors',
    navigateOnCreate: '/test/create',
    allowedRolesToCreate: [UserRoles.ROLE_INVATATOR, UserRoles.ROLE_PROFESOR],
    getCollectionApi: 'testProfessors',
    tableTitle: 'burgerMenu.testsSubmenus.testTableProfessors',
    columns: [
      {
        accessorKey: 'materie.tipMaterie.tipMaterie',
        header: 'subject',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'titlu',
        header: 'title',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'clasa.codClasa',
        header: 'class',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'startDate',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          return dayjs(row.startsAt).format('DD.MM.YYYY HH:mm');
        },
      },
      {
        header: 'endDate',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          return dayjs(row.endsAt).format('DD.MM.YYYY HH:mm');
        },
      },
      {
        header: 'active',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          return React.createElement(YesNo, { isYes: !!row.activ });
        },
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(TestActions, {
            id: row.id,
            hasAnswers: !!row.raspunsuriIntrebareTest?.length,
            basePath: 'test',
            isTest: true,
          });
        },
      },
    ] as MRT_ColumnDef<Test>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const TestStudentsConfig: TableConfig<Test> = {
    path: '/table-test/students',
    getCollectionApi: 'testStudents',
    isNotHydrated: true,
    tableTitle: 'burgerMenu.testsSubmenus.testTableStudents',
    columns: [
      {
        accessorKey: 'materie.tipMaterie.tipMaterie',
        header: 'subject',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'titlu',
        header: 'title',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'clasa.codClasa',
        header: 'class',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'startDate',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          return dayjs(row.startsAt).format('DD.MM.YYYY HH:mm');
        },
      },
      {
        header: 'endDate',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          return dayjs(row.endsAt).format('DD.MM.YYYY HH:mm');
        },
      },
      {
        header: 'active',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          return React.createElement(YesNo, { isYes: !!row.activ });
        },
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(TestActions, {
            id: row.id,
            hasAnswers: !!row.raspunsuriIntrebareTest?.length,
            basePath: 'test',
            isTest: true,
          });
        },
      },
    ] as MRT_ColumnDef<Test>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const TestQuestionConfig: TableConfig<TestQuestion> = {
    path: '/table-test-question',
    navigateOnCreate: '/test-question/create',
    allowedRolesToCreate: [UserRoles.ROLE_INVATATOR, UserRoles.ROLE_PROFESOR],
    getCollectionApi: 'testQuestion',
    tableTitle: 'burgerMenu.testsSubmenus.testQuestionTable',
    columns: [
      {
        accessorKey: 'materie.tipMaterie.tipMaterie',
        header: 'subject',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'intrebare',
        header: 'question',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'raspuns1',
        header: 'answer1',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'raspuns2',
        header: 'answer2',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'raspuns3',
        header: 'answer3',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'raspuns4',
        header: 'answer4',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'raspuns5',
        header: 'answer5',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'raspuns6',
        header: 'answer6',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'raspunsCorect',
        header: 'correctAnswer',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(TestActions, {
            id: row.id,
            basePath: 'test-question',
          });
        },
      },
    ] as MRT_ColumnDef<TestQuestion>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const TestQuestionToTestConfig: TableConfig<TestQuestionToTest> = {
    path: '/table-test-question-to-test',
    getCollectionApi: 'testQuestionToTest',
    tableTitle: 'burgerMenu.testsSubmenus.testQuestionToTestTable',
    columns: [
      {
        accessorKey: 'test.titlu',
        header: 'testTitle',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'test.materie.tipMaterie.tipMaterie',
        header: 'subject',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'test.clasa.codClasa',
        header: 'class',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'intrebare.intrebare',
        header: 'question',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      // {
      //   header: 'actions',
      // id: 'actions',
      //   enableResizing: false,
      //   enableFilterMatchHighlighting: false,
      //   enableColumnFilter: false,
      //   maxSize: 100,
      //   accessorFn: (row) => {
      //     if (!row) return;
      //     return React.createElement(TestActions, {
      //       id: row.id,
      //       basePath: 'test-question-to-test',
      //     });
      //   },
      // },
    ] as MRT_ColumnDef<TestQuestionToTest>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const TestQuestionAnswerConfig: TableConfig<TestQuestionAnswer> = {
    path: '/table-test-question-answer',
    getCollectionApi: 'testQuestionAnswer',
    tableTitle: 'burgerMenu.testsSubmenus.testQuestionAnswer',
    columns: [
      {
        accessorKey: 'test.titlu',
        header: 'testTitle',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'intrebareTest.test.materie.tipMaterie.tipMaterie',
        header: 'subject',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'intrebareTest.test.clasa.codClasa',
        header: 'class',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user.prenume',
        header: 'firstName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user.nume',
        header: 'lastName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'intrebareTest.intrebare.intrebare',
        header: 'question',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'raspunsElev',
        header: 'studentAnswer',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      ...(selectedView !== UserRoles.ROLE_ELEV
        ? [
            {
              accessorKey: 'raspunsCorect',
              header: 'correctAnswer',
              enableResizing: false,
              enableFilterMatchHighlighting: false,
              enableColumnFilter: false,
              accessorFn: (row: TestQuestionAnswer) => {
                if (!row || !row.raspunsCorect) return;
                return React.createElement(YesNo, {
                  isYes: row.raspunsElev === row.raspunsCorect,
                });
              },
            },
          ]
        : []),
    ] as MRT_ColumnDef<TestQuestionAnswer>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const UserClassConfig: TableConfig<UserClass> = {
    path: '/table-user-class',
    navigateOnCreate: '/user-class',
    getCollectionApi: 'userClass',
    tableTitle: 'burgerMenu.adminSubmenus.userClassTable',
    columns: [
      {
        accessorKey: 'user.prenume',
        header: 'firstName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'user.nume',
        header: 'lastName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'clasa.codClasa',
        header: 'class',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'activ',
        header: 'active',
        accessorFn: (row) => {
          return React.createElement(YesNo, { isYes: !!row.activ });
        },
        enableSorting: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(EditAction, {
            id: row.id,
            path: '/user-class',
          });
        },
      },
    ] as MRT_ColumnDef<UserClass>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const ClassSpecializationConfig: TableConfig<ClassSpecialization> = {
    path: '/table-class-specialization',
    navigateOnCreate: '/class-specialization',
    getCollectionApi: 'classSpecialization',
    tableTitle: 'burgerMenu.adminSubmenus.specializationTable',
    columns: [
      {
        accessorKey: 'profilClasa.tipProfilClasa',
        header: 'classProfile',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tipSpecializare',
        header: 'specialization',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(EditAction, {
            id: row.id,
            path: '/class-specialization',
          });
        },
      },
    ] as MRT_ColumnDef<ClassSpecialization>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const CatedraConfig: TableConfig<Catedra> = {
    path: '/table-catedra',
    navigateOnCreate: '/catedra',
    allowedRolesToCreate: [
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_SECRETAR,
    ],
    getCollectionApi: 'catedra',
    tableTitle: 'burgerMenu.adminSubmenus.catedraTable',
    columns: [
      {
        accessorKey: 'denumireCatedra',
        header: 'catedra',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        header: 'actions',
        // id: 'actions',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        maxSize: 100,
        accessorFn: (row) => {
          if (!row) return;
          return React.createElement(EditAction, {
            id: row.id,
            path: '/catedra',
          });
        },
      },
    ] as MRT_ColumnDef<Catedra>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const AverageConfig: TableConfig<FullAverage> = {
    path: '/average-table',
    allowedRolesToCreate: [
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_DIRIGINTE,
    ],
    isNotHydrated: true,
    getCollectionApi: 'average',
    tableTitle: 'burgerMenu.averageSubmenus.averageTableNonPrimary',
    columns: [
      {
        accessorKey: 'anInceput',
        header: 'schoolYear',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tipMaterie',
        header: 'subject',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'nume',
        header: 'lastName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'prenume',
        header: 'firstName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'codClasa',
        header: 'class',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'medieMaterie',
        header: 'subjectAverage',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'medieCalificativ',
        header: 'averageCalificativ',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'incheiata',
        header: 'closed',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        accessorFn: (row) => {
          return React.createElement(YesNo, {
            isYes: !!row.incheiata,
            shouldBeNull: row.incheiata === null || row.incheiata === undefined,
          });
        },
        // filterFn: 'equals',
        // filterSelectOptions: [
        //   { text: 'Da', value: '1' },
        //   { text: 'Nu', value: '0' },
        // ],
        // filterVariant: 'select',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'corigent',
        header: 'corigent',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        accessorFn: (row) => {
          return React.createElement(YesNo, {
            isYes: !!row.corigent,
            shouldBeNull: row.corigent === null || row.corigent === undefined,
          });
        },
        enableColumnFilter: false,
      },
      {
        accessorKey: 'absentaCorigenta',
        header: 'absentaCorigenta',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        accessorFn: (row) => {
          return React.createElement(YesNo, {
            isYes: !!row.absentaCorigenta,
            shouldBeNull:
              row.absentaCorigenta === null ||
              row.absentaCorigenta === undefined,
          });
        },
        // filterFn: 'equals',
        // filterSelectOptions: [
        //   { text: 'Da', value: '1' },
        //   { text: 'Nu', value: '0' },
        // ],
        // filterVariant: 'select',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'notaCorigenta',
        header: 'corigentaGrade',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'calificativCorigenta',
        header: 'corigentaCalificativ',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'medieFinala',
        header: 'finalAverage',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'calificativFinal',
        header: 'finalCalificativ',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'numeCadru',
        header: 'lastName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'prenumeCadru',
        header: 'firstName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'createdAt',
        header: 'date',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (!row || !row.createdAt) return;
          return dayjs(row.createdAt).format('DD.MM.YYYY');
        },
      },
    ] as MRT_ColumnDef<FullAverage>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  const ClosedSituationTableConfig: TableConfig<ClosedSituationTableEntry> = {
    path: '/closed-situation-table',
    allowedRolesToCreate: [
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PARINTE,
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_DIRIGINTE,
    ],
    getCollectionApi: 'closedSituation',
    tableTitle: 'burgerMenu.closingSituationSubmenus.closedSituationTable',
    columns: [
      {
        accessorKey: 'user.nume',
        header: 'studentSurname',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'user.prenume',
        header: 'studentFirstName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'anScolar.anInceput',
        header: 'schoolYear',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'anStudiu.anStudiu',
        header: 'studyYear',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        header: 'gradeOrCalificativ',
        enableColumnFilter: false,
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        accessorFn: (row) => {
          if (!row) return '';
          if (row && row.calificativAn) return row.calificativAn;
          if (row && row.medieAn) return row.medieAn;
        },
      },
      {
        accessorKey: 'situatie',
        header: 'situation',
        filterFn: 'equals',
        filterVariant: 'select',
        filterSelectOptions: [
          {
            text: StudentSituationEnum.Promovat,
            value: StudentSituationEnum.Promovat,
          },
          {
            text: StudentSituationEnum.Amanat,
            value: StudentSituationEnum.Amanat,
          },
          {
            text: StudentSituationEnum.Repetent,
            value: StudentSituationEnum.Repetent,
          },
        ],
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
        accessorFn: (row) => {
          if (!row) return '';
          return row.situatie?.label;
        },
      },
      {
        accessorKey: 'comentarii',
        header: 'observations',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'updatedBy.nume',
        header: 'closedBySurname',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'updatedBy.prenume',
        header: 'closedByFirstName',
        enableResizing: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilter: true,
      },
    ] as MRT_ColumnDef<ClosedSituationTableEntry>[],
    tableProps: {
      enableColumnActions: false,
      enableRowActions: false,
    },
  };

  return {
    user: UsersConfig,
    class: ClassesConfig,
    schoolYear: SchoolYearConfig,
    studyYear: StudyYearConfig,
    timeSlot: TimeSlotConfig,
    termAkaModul: TermAkaModulConfig,
    classProfile: ClassProfileConfig,
    subjectType: SubjectTypeConfig,
    subjectConfig: SubjectConfigurationConfig,
    evaluationQuestion: EvaluationQuestionConfig,
    annualEvaluationQuestion: AnnualEvaluationQuestionConfig,
    evaluationConfig: EvaluationConfigurationConfig,
    evaluationForProfessorResponseConfig: EvaluationForProfessorResponseConfig,
    evaluationForStudentResponseConfig: EvaluationForStudentResponseConfig,
    yearlyEvaluationResponseConfig: YearlyEvaluationResponseConfig,
    catalogConfig: CatalogConfig,
    homeworkConfig: HomeworkConfig,
    studentHomeworkConfig: StudentHomeworkConfig,
    solvedStudentHomeworkConfig: SolvedStudentHomeworkConfig,
    reviewedStudentHomeworkConfig: ReviewedStudentHomeworkConfig,
    lessonConfig: LessonConfig,
    testProfessorsConfig: TestProfessorsConfig,
    testStudentsConfig: TestStudentsConfig,
    testQuestionConfig: TestQuestionConfig,
    testQuestionToTestConfig: TestQuestionToTestConfig,
    testQuestionAnswerConfig: TestQuestionAnswerConfig,
    userClassConfig: UserClassConfig,
    classSpecializationConfig: ClassSpecializationConfig,
    catedraConfig: CatedraConfig,
    averageConfig: AverageConfig,
    closedSituationTableConfig: ClosedSituationTableConfig,
  };
};
