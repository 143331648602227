import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    primaryButton: Palette['primary'];
    secondaries: {
      text: Palette['secondary'];
      lightText: Palette['secondary'];
      whiteText: Palette['secondary'];
    };
  }

  interface PaletteOptions {
    primaryButton?: PaletteOptions['primary'];
    secondaries: {
      text: PaletteOptions['secondary'];
      lightText: PaletteOptions['secondary'];
      whiteText: PaletteOptions['secondary'];
    };
  }

  interface TypographyVariants {
    bodyBold: React.CSSProperties;
    bodySemiBold: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodyRegular: React.CSSProperties;
    bodyLight: React.CSSProperties;
    headerLight: React.CSSProperties;
    headerBold: React.CSSProperties;
    headerClock: React.CSSProperties;
    headerWelcome: React.CSSProperties;
    headerRegular: React.CSSProperties;
    footerLight: React.CSSProperties;
    footerRegular: React.CSSProperties;
    footerSemiBold: React.CSSProperties;
    footerBold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyBold: React.CSSProperties;
    bodySemiBold: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodyRegular: React.CSSProperties;
    bodyLight: React.CSSProperties;
    headerLight: React.CSSProperties;
    headerLightSmall: React.CSSProperties;
    headerBold: React.CSSProperties;
    headerClock: React.CSSProperties;
    headerWelcome: React.CSSProperties;
    headerRegular: React.CSSProperties;
    footerLight: React.CSSProperties;
    footerRegular: React.CSSProperties;
    footerSemiBold: React.CSSProperties;
    footerBold: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyBold: true;
    bodySemiBold: true;
    bodyMedium: true;
    bodyRegular: true;
    bodyLight: true;
    headerLight: true;
    headerLightSmall: true;
    headerBold: true;
    headerClock: true;
    headerWelcome: true;
    headerRegular: true;
    footerLight: true;
    footerRegular: true;
    footerSemiBold: true;
    footerBold: true;
  }

  interface TypographyPropsColorOverrides {
    whiteText: true;
  }
}

const typography = {
  h1: {
    fontFamily: 'Source Sans 3',
    fontSize: '60px',
    fontWeight: 600,
  },
  h2: {
    fontFamily: 'Source Sans 3',
    fontSize: '48px',
    fontWeight: 700,
  },
  h3: {
    fontFamily: 'Source Sans 3',
    fontSize: '40px',
    fontWeight: 500,
  },
  subtitle1: {
    fontFamily: 'Source Sans 3',
    fontSize: '28px',
    fontWeight: 400,
  },
  subtitle2: {
    fontFamily: 'Source Sans 3',
    fontSize: '18px',
    fontWeight: 700,
  },
  bodyBold: {
    fontFamily: 'Source Sans 3',
    fontSize: '14px',
    fontWeight: 700,
  },
  bodySemiBold: {
    fontFamily: 'Source Sans 3',
    fontSize: '14px',
    fontWeight: 600,
  },
  bodyMedium: {
    fontFamily: 'Source Sans 3',
    fontSize: '14px',
    fontWeight: 500,
  },
  bodyRegular: {
    fontFamily: 'Source Sans 3',
    fontSize: '14px',
    fontWeight: 400,
  },
  bodyLight: {
    fontFamily: 'Source Sans 3',
    fontSize: '14px',
    fontWeight: 300,
  },
  headerLight: {
    fontSize: '22px',
    fontWeight: 300,
    lineHeight: '26px',
  },
  headerLightSmall: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  headerBold: {
    fontSize: '24px',
    fontWeight: 700,
  },
  headerClock: {
    fontFamily: 'Source Sans 3',
    fontSize: '74px',
    fontWeight: 400,
    lineHeight: '74px',
  },
  headerWelcome: {
    fontFamily: 'Source Sans 3',
    fontSize: '64px',
    fontWeight: 400,
    lineHeight: '68px',
  },
  headerRegular: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '26px',
  },
  footerBold: {
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  footerLight: {
    fontSize: '16px',
    fontWeight: 300,
  },
  footerSemiBold: {
    fontSize: '18px',
    fontWeight: 600,
  },
  footerRegular: {
    fontSize: '20px',
    fontWeight: 400,
  },
};

declare module '@mui/material/Typography' {
  interface TypographyPropsColorOverrides {
    whiteText: true;
  }
}

export const Themes = {
  blue: createTheme({
    palette: {
      primary: { main: '#1976d2', light: 'rgba(25, 118, 210, 0.1)' },
      secondary: { main: '#00CCFF' },
      secondaries: {
        text: { main: '#003398' },
        whiteText: { main: '#FFFFFF' },
        lightText: { main: '#ff9100' },
      },
    },
    typography: {
      ...typography,
    },
  }),
  lightblue: createTheme({
    palette: {
      primary: { main: '#2B8FAD', light: 'rgba(43, 143, 173, 0.1)' },
      secondary: { main: '#00CBFF' },
      secondaries: {
        text: { main: '#2B8FAD' },
        whiteText: { main: '#FFFFFF' },
        lightText: { main: '#ff9100' },
      },
    },
    typography: {
      ...typography,
    },
  }),
  orange: createTheme({
    palette: {
      primary: { main: '#F06100', light: 'rgba(240, 97, 0, 0.1)' },
      secondary: { main: '#FFCC00' },
      secondaries: {
        text: { main: '#F06100' },
        whiteText: { main: '#FFFFFF' },
        lightText: { main: '#ff9100' },
      },
    },
    typography: {
      ...typography,
    },
  }),
  purple: createTheme({
    palette: {
      primary: { main: '#990099', light: 'rgba(153, 0, 153, 0.1)' },
      secondary: { main: '#D3A0C8' },
      secondaries: {
        text: { main: '#990099' },
        whiteText: { main: '#FFFFFF' },
        lightText: { main: '#ff9100' },
      },
    },
    typography: {
      ...typography,
    },
  }),
};
