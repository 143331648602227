import { ClassApi } from 'api/class.api';
import { FisierApi } from 'api/fisier.api';
import { HomeworkApi } from 'api/homework.api';
import { SubjectApi } from 'api/subject.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { DatePickerInput } from 'common/components/inputs/DatePickerInput';
import FileUploadComponent from 'common/components/inputs/FileInput';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import dayjs, { Dayjs } from 'dayjs';
import { IFile, IRI } from 'library/types/Common';
import { Homework, HomeworkPayload } from 'library/types/Homework';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import isToday from 'dayjs/plugin/isToday';
import { useTranslation } from 'react-i18next';
import { SubjectType } from 'library/types/Subject';

dayjs.extend(isToday);

export const CreateHomeworkModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const state = location.state as {
    homeworkId: number;
  };
  const { homeworkId } = state || {};

  const { getHomework, createHomework, editHomework } = HomeworkApi();
  const { getAvailableClasses } = ClassApi();
  const { getSubjectsForClass } = SubjectApi();
  const { uploadFile } = FisierApi();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(false);

  const [classes, setClasses] = useState<
    Array<{
      id: number;
      classCode: string;
    }>
  >([]);
  const [selectedClass, setSelectedClass] = useState<{
    id: number;
    classCode: string;
  }>({
    id: -1,
    classCode: '',
  });

  const [subjects, setSubjects] = useState<
    Array<{
      '@id': IRI;
      numeMaterie: string;
    }>
  >([]);
  const [selectedSubject, setSelectedSubject] = useState<{
    '@id': IRI;
    numeMaterie: string;
  }>({
    '@id': '',
    numeMaterie: '',
  });

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileEdit, setSelectedFileEdit] = useState<IFile | null>(null);

  useEffect(() => {
    (async () => {
      const classes = await getAvailableClasses();
      setClasses(
        classes.map((e) => {
          return {
            id: e.id,
            classCode: e.codClasa,
          };
        })
      );
    })();
    if (homeworkId) {
      (async () => {
        const homework = (await getHomework({
          id: homeworkId,
        })) as Homework;

        setSelectedClass({
          id: parseInt(
            homework.clasa['@id'].split('/')[
              homework.clasa['@id'].split('/').length - 1
            ]
          ),
          classCode: homework.clasa.codClasa,
        });

        setSelectedSubject({
          '@id': homework.materie['@id'],
          numeMaterie: homework.materie.tipMaterie.tipMaterie,
        });

        setTitle(homework.titlu);
        setDescription(homework.descriere);
        setActive(homework.activ);

        setStartDate(dayjs(homework.startsAt));
        setEndDate(dayjs(homework.endsAt));

        setSelectedFileEdit(homework.fisier);
      })();
    }
  }, []);

  useEffect(() => {
    if (selectedClass.id === -1) return;

    (async () => {
      const subjects = await getSubjectsForClass(selectedClass.id);
      setSubjects(
        subjects.map((e) => {
          return {
            '@id': e['@id'],
            numeMaterie: e.tipMaterie as string,
          };
        })
      );

      if (subjects.length === 1) {
        setSelectedSubject({
          '@id': subjects[0]['@id'],
          numeMaterie: (subjects[0].tipMaterie as SubjectType).tipMaterie,
        });
      }
    })();
  }, [selectedClass]);

  const submitFormAction = async () => {
    if (
      !title ||
      selectedClass.id === -1 ||
      !selectedSubject['@id'] ||
      !startDate ||
      !endDate
    )
      return toast.error(tModules('homework.createEditHomeworkError'));

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      try {
        var uploadedFile = await uploadFile(formData);
      } catch (e) {
        console.error(e);
        toast.error(tModules('homework.fileUploadError'));
        return;
      }
    }

    const homeworkPayload: HomeworkPayload = {
      fisier:
        selectedFile || selectedFileEdit
          ? `/api/fisiere/${
              selectedFile ? uploadedFile.id : selectedFileEdit!.id
            }`
          : null,
      clasa: `/api/clase/${selectedClass.id}`,
      materie: selectedSubject['@id'],
      activ: active,
      descriere: description,
      titlu: title,
      startsAt: startDate.isToday() ? dayjs().add(1, 'minute') : startDate,
      endsAt: endDate,
      user: '/api/users/' + userContext.state.account!.user.id,
    };

    homeworkId
      ? await editHomework(homeworkId, homeworkPayload)
      : await createHomework(homeworkPayload);

    navigate('/table-homework');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('homework.title'),
              value: title,
              onChange: (e) => {
                setTitle(e.target.value);
              },
            }}
          />
          <RegularInput
            isOptional={true}
            textFieldProps={{
              fullWidth: true,
              rows: 7,
              multiline: true,
              label: tModules('homework.description'),
              value: description,
              onChange: (e) => {
                setDescription(e.target.value);
              },
            }}
          />
          <RegularSelect
            options={classes.map((e) => ({
              value: e.id,
              label: e.classCode,
            }))}
            onChange={(e) => {
              setSelectedClass(classes.find((el) => el.id === e)!);
            }}
            selectProps={{
              label: tModules('homework.classSelect'),
              value: selectedClass.id !== -1 ? selectedClass.id : '',
              fullWidth: true,
            }}
          />
          <RegularSelect
            options={subjects.map((e) => ({
              value: e['@id'],
              label: e['numeMaterie'],
            }))}
            onChange={(e) => {
              setSelectedSubject(subjects.find((el) => el['@id'] === e)!);
            }}
            selectProps={{
              disabled: selectedClass.id === -1 || subjects.length < 2,
              label: tModules('homework.subjectSelect'),
              value: selectedSubject['@id'],
              fullWidth: true,
            }}
          />
          <div className="flex gap-2 py-2">
            <FileUploadComponent
              buttonText={tModules('homework.homeworkFileUpload')}
              setSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
            />
            {!!homeworkId && !selectedFile && (
              <div className="flex items-center">
                {selectedFileEdit?.urlPath}
              </div>
            )}
          </div>
          <DatePickerInput
            datePickerProps={{
              minDate: dayjs().startOf('day'),
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('homework.activePeriodStartDate'),
              value: startDate,
              onChange: (e) => {
                setStartDate(e);
              },
            }}
          />
          <DatePickerInput
            datePickerProps={{
              minDate: dayjs().startOf('day'),
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('homework.activePeriodEndDate'),
              value: endDate,
              onChange: (e) => {
                setEndDate(e?.endOf('day') ?? null);
              },
            }}
          />
          <RegularSelect
            options={[
              {
                value: true,
                label: tCommon('basic.yes'),
              },
              {
                value: false,
                label: tCommon('basic.no'),
              },
            ]}
            onChange={(e) => {
              setActive(e);
            }}
            selectProps={{
              label: tModules('homework.isHomeworkActive'),
              value: active,
              fullWidth: true,
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={submitFormAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
