import { MessagesApi } from 'api/messages.api';
import { UserContext } from 'context/UserContext';
import dayjs from 'dayjs';
import { convertToHTML, getUniqueValuesForKey } from 'library/helpers/helper';
import { Message, MessagePayload } from 'library/types/Message';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ReadMessageModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const params = useParams();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const account = userContext.state.account;

  const { id } = params as {
    id: string;
  };

  const [message, setMessage] = useState<Message>();
  const [currentMessage, setCurrentMessage] = useState<{
    fullString: string;
    senderName: string;
    senderId: string;
    messageBody: string;
    date: string;
  }>();
  const [allPreviousMessages, setAllPreviousMesages] = useState<
    Array<{
      fullString: string;
      senderName: string;
      senderId: string;
      messageBody: string;
      date: string;
    }>
  >([]);
  const [allSenderIds, setAllSenderIds] = useState<string[]>([]);
  const [reply, setReply] = useState('');

  const { getMessage, sendMessage } = MessagesApi();

  useEffect(() => {
    if (!id || typeof parseInt(id) !== 'number') {
      return navigate('/home');
    }
    getMessageAction();
  }, []);

  const getMessageAction = async () => {
    const message = await getMessage(parseInt(id));
    const allMessages = message.corpMesaj.split(`end-message-line`);
    if (allMessages[allMessages.length - 1] === '') {
      allMessages.splice(-1);
    }

    const allMappedMessages = allMessages.map((e, index) => {
      const messageContent = e.split('message-details|');
      const messageDetails = messageContent[1].split('|');
      const senderId = messageDetails[0].split('sender-id:')[1];
      const senderName = messageDetails[1].split('sender-name:')[1];
      const date = messageDetails[2].split('date:')[1];

      return {
        fullString: e + 'end-message-line',
        messageBody: messageContent[0],
        senderId,
        senderName,
        date,
      };
    });

    const allPreviousMessages = allMappedMessages.slice(
      0,
      allMessages.length - 1
    );

    setAllSenderIds(getUniqueValuesForKey(allPreviousMessages, 'senderId'));
    setMessage(message);
    setAllPreviousMesages(allPreviousMessages);
    setCurrentMessage(allMappedMessages[allMappedMessages.length - 1]);
  };

  const handleInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setReply(event.target.value);
  };

  const sendMessageAction = async () => {
    const textToHtml = convertToHTML(reply);
    const endOfMessageText = `message-details|sender-id:${
      account!.user.id
    }|sender-name:${
      account!.user.nume + ' ' + account!.user.prenume
    }|date:${dayjs()}|end-message-line`;
    if (!textToHtml) {
      return toast.error(tModules('message.noReplyError'));
    }
    const messageObject: MessagePayload = {
      corpMesaj:
        allPreviousMessages.map((e) => e.fullString).join('') +
        currentMessage?.fullString +
        textToHtml +
        endOfMessageText,
      subiect: 'RE: ' + message!.subiect,
      receptor: message!.emitator['@id'],
      thread: message!.thread,
    };

    await sendMessage(messageObject);
    navigate('/messages/inbox');
  };

  return (
    <div className="h-fit-content w-full">
      {message && (
        <div className="flex max-w-full w-full justify-center items-center">
          <section
            style={{ maxWidth: '80%', minWidth: '50%' }}
            className="p-9 flex flex-col bg-white shadow-lg rounded-3xl"
          >
            {!!allPreviousMessages.length && (
              <div className="flex flex-col justify-between shadow-md border mb-8 p-2 pb-4 overflow-y-auto max-h-60 rounded-xl">
                {allPreviousMessages.map((e, index) => {
                  return (
                    <article
                      key={'previous-messages-list-' + index}
                      className={`my-3 leading-7 tracking-wider rounded-xl
                                ${
                                  e.senderId === allSenderIds[0]
                                    ? 'bg-pink-200'
                                    : 'bg-yellow-100'
                                } 
                                p-4 mb-1`}
                    >
                      <div className="flex justify-between">
                        <h3 className="font-bold text-lg flex ">
                          {e.senderName}
                        </h3>
                        <h3 className="font-bold text-lg flex justify-end">
                          {dayjs(e.date).format('DD.MM.YYYY HH:mm')}
                        </h3>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: convertToHTML(e.messageBody),
                        }}
                        className="text-gray-700"
                      ></div>
                    </article>
                  );
                })}
              </div>
            )}
            <section>
              <h1 className="text-md">
                {tModules('message.from')}{' '}
                <span className="font-bold text-lg">
                  {currentMessage?.senderName}
                </span>
              </h1>
              <h1 className="text-md">
                {tModules('message.subject')}
                <span className="font-bold text-lg"> {message?.subiect}</span>
              </h1>
              <h1 className="text-md">
                {tModules('message.date')}{' '}
                <span className="font-bold text-lg">
                  {dayjs(currentMessage?.date).format('DD.MM.YYYY HH:mm')}
                </span>
              </h1>

              <h1 className="text-md font-bold pt-6 pb-4">
                {tModules('message.body')}
              </h1>

              <article className="text-gray-500 leading-7 tracking-wider">
                <div
                  dangerouslySetInnerHTML={{
                    __html: convertToHTML(currentMessage?.messageBody ?? ''),
                  }}
                  id="corp-mesaj"
                  className="text-gray-700 flex break-all overflow-y-auto max-h-60 text-lg"
                ></div>
              </article>
            </section>
            {message.emitator['@id'] !==
              `/api/users/${userContext.state.account?.user.id}` && (
              <section className="mt-6 rounded-xl">
                <textarea
                  onChange={handleInputChange}
                  className="w-full bg-gray-50 p-4 rounded-xl border shadow-md"
                  placeholder={tModules('message.replyInput')}
                  rows={5}
                  style={{ resize: 'none' }}
                ></textarea>
                <div className="flex items-center justify-end p-2">
                  <button
                    onClick={sendMessageAction}
                    className="bg-purple-600 text-white mt-2 px-6 py-2 rounded-xl"
                  >
                    {tCommon('inputs.sendButton')}
                  </button>
                </div>
              </section>
            )}
          </section>
        </div>
      )}
    </div>
  );
};
