import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  Class,
  ClassPayload,
  ClassProfile,
  ClassProfilePayload,
  ClassSpecialization,
  ClassSpecializationPayload,
  CollectionClass,
  SimpleCollectionClass,
  Specialization,
} from 'library/types/Class';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import { CatalogReportObjectJSON } from 'library/types/Reports';
import { User } from 'library/types/User';

export function ClassApi() {
  const { apiHandler } = JWTInterceptor();

  const getClass = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Class | CollectionApiResponse<CollectionClass>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/clase/${id}` : `${serverUri()}/clase/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const editClass = async (
    id: number,
    classInfo: ClassPayload
  ): Promise<Class> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/clase/${id}`,
      data: classInfo,
      axiosConfig: {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
      successMessage: 'Class successfully updated!',
    });

    return response.data;
  };

  const createClass = async (classInfo: ClassPayload): Promise<Class> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/clase`,
      data: classInfo,
      axiosConfig: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      successMessage: 'Class successfully created!',
    });

    return response.data;
  };

  const createClassProfile = async (
    classProfileInfo: ClassProfilePayload
  ): Promise<ClassProfile> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/profileClasa`,
      data: classProfileInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Class profile successfully created!',
    });

    return response.data;
  };

  const getClassProfile = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<ClassProfile | CollectionApiResponse<ClassProfile>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/profileClasa/${id}`
        : `${serverUri()}/profileClasa/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getClassSpecialization = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Specialization | CollectionApiResponse<Specialization>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/specializari/${id}`
        : `${serverUri()}/specializari/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createClassSpecialization = async (
    classSpecializationInfo: ClassSpecializationPayload
  ): Promise<ClassSpecialization> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/specializari`,
      data: classSpecializationInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Class specialization successfully created!',
    });

    return response.data;
  };

  const getAvailableClasses = async (): Promise<
    Array<SimpleCollectionClass>
  > => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/clase-disponibile/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const getAvailableClassesForCadru = async (): Promise<
    Array<SimpleCollectionClass>
  > => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/clase-disponibile-responsabil/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const getAvailableClassesForSubjectConfig = async (
    id: number
  ): Promise<Array<SimpleCollectionClass>> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/clase-disponibile-materie/${id}`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const getStudentsOfClass = async (
    id: number
  ): Promise<
    Array<{
      id: number;
      nume: string;
      prenume: string;
    }>
  > => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/elevi-clasa/${id}`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const getUnclosedStudentsOfClass = async (
    id: number
  ): Promise<
    Array<{
      id: number;
      nume: string;
      prenume: string;
    }>
  > => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/elevi-clasa-situatie-neincheiata/${id}`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const editClassSpecialization = async (
    id: number,
    data: Partial<ClassSpecializationPayload>
  ): Promise<ClassSpecialization> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/specializari/${id}`,
      data: data,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The class specialization was successfully modified!',
    });

    return response.data;
  };

  const getClaseDisponibileAnScolar = async (
    anScolar: number | string
  ): Promise<Class[]> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/clase-disponibile-an-scolar/${anScolar}`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  const addCatalogInfoToClass = async (
    id: number,
    catalogInfo: CatalogReportObjectJSON & { selectedSecretar: number }
  ) => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/clase/${id}`,
      data: {
        catalogInfo,
      },
      axiosConfig: {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
      successMessage: 'Class successfully updated!',
    });

    return response.data;
  };

  return {
    getClass,
    editClass,
    createClass,
    createClassProfile,
    createClassSpecialization,
    getClassProfile,
    getAvailableClasses,
    getAvailableClassesForSubjectConfig,
    getStudentsOfClass,
    getClassSpecialization,
    editClassSpecialization,
    getUnclosedStudentsOfClass,
    getAvailableClassesForCadru,
    getClaseDisponibileAnScolar,
    addCatalogInfoToClass,
  };
}
