import { EvaluationApi } from 'api/evaluation.api';
import { TermAkaModulApi } from 'api/termAkaModul.api';
import ClearIcon from 'common/components/icons/ClearIcon';
import { AutocompleteInput } from 'common/components/inputs/AutocompleteInput';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { t } from 'i18next';
import { isObjectValid } from 'library/helpers/helper';
import { CollectionApiResponse, IRI } from 'library/types/Common';
import {
  EvaluationConfigPayload,
  EvaluationQuestion,
} from 'library/types/Evaluation';
import { CollectionTermAkaModul } from 'library/types/TermAkaModul';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const EvaluationConfigModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const { createEvaluationConfig, getEvaluationQuestion } = EvaluationApi();
  const { getTermAkaModul } = TermAkaModulApi();

  const [configQuestions, setConfigQuestions] = useState<{
    [key: IRI]: string;
  }>({});

  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
  const [evaluationConfigData, setEvaluationConfigData] = useState<{
    isStudentEvaluated: boolean;
    module: IRI;
  }>({
    isStudentEvaluated: true,
    module: '',
  });

  const [evaluationQuestions, setEvaluationQuestions] = useState<
    Array<EvaluationQuestion>
  >([]);
  const [termAkaModules, setTermAkaModules] = useState<
    Array<CollectionTermAkaModul>
  >([]);

  useEffect(() => {
    (async () => {
      const evaluationQuestions =
        (await getEvaluationQuestion()) as CollectionApiResponse<EvaluationQuestion>;
      const modules =
        (await getTermAkaModul()) as CollectionApiResponse<CollectionTermAkaModul>;

      setEvaluationQuestions(evaluationQuestions['hydra:member']);
      setTermAkaModules(modules['hydra:member']);
    })();
  }, []);

  const isActionValid = () => {
    if (!isObjectValid(evaluationConfigData)) {
      toast.error(tModules('evaluation.allFieldsMustBeFilled'));
      return false;
    }

    if (Object.values(configQuestions).length < 3) {
      toast.error(tModules('evaluation.minimumNumberOfQuestions') + ' 3');
      return false;
    }

    if (!userContext.state.account?.user.scoala.id) return false;

    return true;
  };

  const createEvaluationConfigAction = async () => {
    if (!isActionValid()) return;

    const payloadObject: EvaluationConfigPayload = {
      intrebari: configQuestions,
      modul: evaluationConfigData!.module,
      elevEvaluat: evaluationConfigData!.isStudentEvaluated,
    };

    await createEvaluationConfig(payloadObject);
    navigate('/evaluation-configurations');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularSelect
            options={[
              { label: tCommon('userRoles.student'), value: true },
              { label: tCommon('userRoles.professor'), value: false },
            ]}
            onChange={(e) => {
              setEvaluationConfigData((prevValue) => {
                return {
                  ...prevValue,
                  isStudentEvaluated: e,
                };
              });
            }}
            selectProps={{
              label: tModules('evaluation.evaluatedUserType'),
              value: evaluationConfigData.isStudentEvaluated,
              fullWidth: true,
            }}
          />
          <RegularSelect
            options={termAkaModules.map((e) => ({
              label:
                tModules('evaluation.moduleInputOption') +
                ` ${e.ordine}, ${e.anScolar.anInceput}`,
              value: e['@id'],
            }))}
            onChange={(e) => {
              setEvaluationConfigData((prevValue) => {
                return {
                  ...prevValue,
                  module: e,
                };
              });
            }}
            selectProps={{
              label: tModules('evaluation.moduleInput'),
              value: evaluationConfigData.module,
              fullWidth: true,
            }}
          />
          <AutocompleteInput
            autocompleteProps={{
              options: evaluationQuestions.map((e) => ({
                nume: e.intrebare,
                id: e.id,
              })),
              value: {
                nume: autocompleteInputValue,
                id: 0,
              },
              onChange: (
                event: any,
                newValue: {
                  nume: string;
                  id: number;
                } | null
              ) => {
                setConfigQuestions((prev) => {
                  if (!newValue) return prev;
                  const questionIRI = '/api/intrebare-evaluare/' + newValue.id;
                  return {
                    ...prev,
                    [questionIRI]: newValue.nume,
                  };
                });
              },
              inputValue: autocompleteInputValue,
              onInputChange: (event, newInputValue) => {
                setAutocompleteInputValue(newInputValue);
              },
            }}
            textFieldProps={{
              fullWidth: true,
              label: tModules('evaluation.questionSelectInput'),
            }}
          />
          <div className="font-bold pb-2 flex">
            {tModules('evaluation.addedQuestions')}
          </div>
          <ul
            style={{
              maxHeight: '400px',
            }}
            className="p-4 overflow-y-auto border border-gray-300 flex flex-col gap-1"
          >
            {Object.keys(configQuestions).map((e, index) => (
              <li
                className="flex justify-between items-center"
                key={'intrebare-index-' + index}
              >
                <div>
                  <span className="pr-2">{index + 1}.</span>{' '}
                  {/* Mimicking bullet points */}
                  {configQuestions[e]}
                </div>
                <ClearIcon
                  onClick={() => {
                    const configQuestionsAux = { ...configQuestions };

                    delete configQuestionsAux[e];
                    setConfigQuestions(configQuestionsAux);
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
        <ButtonComp
          onButtonClick={createEvaluationConfigAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: tCommon('inputs.saveButton'),
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
