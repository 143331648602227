import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AverageApi } from 'api/average.api';
import { ClassApi } from 'api/class.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import dayjs from 'dayjs';
import {
  ActionSituation,
  Calificative,
  ClosingSituationRecord,
  GradeDetails,
  ManageCloseSituationActionTypes,
  Motiv,
} from 'library/types/Average';
import { SimpleCollectionClass } from 'library/types/Class';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const ManageCloseSituationModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const {
    getClosingSituationForStudent,
    setCorigenta,
    delayClosingAverage,
    promoteStudent,
    delaySituation,
    failStudent,
    getSituationReasons,
  } = AverageApi();
  const { getAvailableClassesForCadru, getUnclosedStudentsOfClass } =
    ClassApi();

  const [classes, setClasses] = useState<SimpleCollectionClass[]>([]);
  const [selectedClass, setSelectedClass] = useState<number>(-1);
  const [isPrimaryGrade, setIsPrimaryGrade] = useState<boolean>(false);
  const [reasons, setReasons] = useState<Motiv[]>([]);

  const [students, setStudents] = useState<
    Array<{
      id: number;
      nume: string;
      prenume: string;
    }>
  >([]);
  const [selectedStudent, setSelectedStudent] = useState<number>(-1);

  const [selectedSituation, setSelectedSituation] =
    useState<ClosingSituationRecord>([]);
  const [medii, setMedii] = useState<GradeDetails[]>([]);
  const [dialogConfig, setDialogConfig] = useState<{
    isOpen: boolean;
    type: 'average' | 'situation';
    typeOfAction: ManageCloseSituationActionTypes;
    averageId?: number;
    observatii: string;
    calificativFinal?: Calificative | '';
    motiv: number;
    notaPurtare: number;
    calificativPurtare: Calificative;
  }>({
    isOpen: false,
    type: 'average',
    typeOfAction: ManageCloseSituationActionTypes.SituatiePromovat,
    observatii: '',
    averageId: -1,
    calificativFinal: '',
    motiv: -1,
    notaPurtare: 10,
    calificativPurtare: Calificative.Excelent,
  });

  useEffect(() => {
    (async () => {
      const classes = await getAvailableClassesForCadru();
      const reasons = await getSituationReasons();
      setReasons(reasons['hydra:member']);

      setClasses(classes);
      if (classes.length === 1) {
        setIsPrimaryGrade(parseInt(classes[0].codClasa) < 5);
        setSelectedClass(classes[0].id);
      }
    })();
  }, []);

  useEffect(() => {
    setSelectedStudent(-1);
    if (selectedClass === -1) {
      return;
    }

    (async () => {
      const students = await getUnclosedStudentsOfClass(selectedClass);

      setStudents(students);
    })();
  }, [selectedClass]);

  useEffect(() => {
    if (selectedStudent === -1) {
      setSelectedSituation([]);
      return;
    }

    (async () => {
      await fetchClosingSituationForStudent();
    })();
  }, [selectedStudent]);

  const fetchClosingSituationForStudent = async () => {
    const response = await getClosingSituationForStudent(selectedStudent);

    setSelectedSituation(response);

    const medii = (
      response.filter((record) => 'medieId' in record) as GradeDetails[]
    ).map((e) => {
      return {
        ...e,
        actiuniMedie: e.isAmanata ? {} : e.actiuniMedie,
      };
    });

    setMedii(medii);
  };

  const averageAction = async (action: string, averageId: number) => {
    const { observatii, motiv } = dialogConfig;
    switch (action) {
      case 'setCorigenta':
        await setCorigenta(averageId);
        break;

      case 'setAmanata':
        await delayClosingAverage(averageId, observatii, motiv);
        break;

      default:
        return toast.error('Actiune invalida!');
    }

    await fetchClosingSituationForStudent();
  };

  const situationAction = async (action: string) => {
    const { observatii, calificativFinal, motiv } = dialogConfig;
    switch (action) {
      case 'repetenta':
        await failStudent({
          studentId: selectedStudent,
          observatii,
          motiv,
          isPrimaryGrade,
          purtare: isPrimaryGrade
            ? dialogConfig.calificativPurtare
            : dialogConfig.notaPurtare,
        });
        break;

      case 'situatie-amanata':
        await delaySituation({
          studentId: selectedStudent,
          observatii,
          motiv,
          isPrimaryGrade,
          purtare: isPrimaryGrade
            ? dialogConfig.calificativPurtare
            : dialogConfig.notaPurtare,
        });
        break;

      case 'promovat':
      case 'promovat-primar':
        await promoteStudent({
          studentId: selectedStudent,
          observatii,
          isPrimaryGrade,
          purtare: isPrimaryGrade
            ? dialogConfig.calificativPurtare
            : dialogConfig.notaPurtare,
          ...(isPrimaryGrade && calificativFinal
            ? { calificativ: calificativFinal }
            : {}),
        });
        break;
    }

    const students = await getUnclosedStudentsOfClass(selectedClass);

    setSelectedStudent(-1);
    setStudents(students);

    setMedii([]);
    setSelectedSituation([]);
  };

  const handleCloseDialog = async (shouldSubmit: boolean) => {
    if (shouldSubmit) {
      const { type, typeOfAction, averageId } = dialogConfig;

      if (type === 'average') {
        if (averageId) await averageAction(typeOfAction, averageId);
      } else {
        await situationAction(typeOfAction);
      }
    }

    setDialogConfig({
      isOpen: false,
      typeOfAction: ManageCloseSituationActionTypes.SituatiePromovat,
      type: 'average',
      averageId: -1,
      observatii: '',
      motiv: -1,
      notaPurtare: 10,
      calificativPurtare: Calificative.Excelent,
    });
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '750px',
          }}
          className="flex flex-col items-center"
        >
          <div
            style={{
              width: '600px',
            }}
            className="flex flex-col"
          >
            <RegularSelect
              options={classes.map((e) => ({
                value: e.id,
                label: e.codClasa as string,
              }))}
              onChange={(e) => {
                const selectedClass = classes.find((el) => el.id === e);
                setIsPrimaryGrade(parseInt(selectedClass!.codClasa) < 5);
                setSelectedClass(selectedClass!.id);
              }}
              selectProps={{
                label: tModules('average.class'),
                value: selectedClass,
                fullWidth: true,
              }}
            />
            <RegularSelect
              options={students.map((e) => ({
                value: e.id,
                label: e.nume + ' ' + e.prenume,
              }))}
              onChange={(e) => {
                setSelectedStudent(students.find((el) => el.id === e)!.id);
              }}
              selectProps={{
                disabled: selectedClass === -1,
                label: tModules('average.student'),
                value: selectedStudent,
                fullWidth: true,
              }}
            />
          </div>

          {!!selectedSituation.length && (
            <div className="flex flex-col">
              <table className="min-w-full table-auto bg-white rounded-lg overflow-hidden shadow-lg">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {tModules('average.subject')}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {tModules('average.gradeTableProfessor')}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {tModules(
                        isPrimaryGrade
                          ? 'average.averageCalificativ'
                          : 'average.average'
                      )}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {tModules('average.closedAverage')}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {tModules('average.corigenta')}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {tModules(
                        isPrimaryGrade
                          ? 'average.finalCalificativAverage'
                          : 'average.finalAverage'
                      )}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {tModules(
                        isPrimaryGrade
                          ? 'average.calificativCorigenta'
                          : 'average.gradeCorigenta'
                      )}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {tModules('average.absentaCorigenta')}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {tModules('average.averageDate')}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      {tModules('average.actions')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {medii.map((record: GradeDetails, index: number) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {record.materie}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {record.cadru}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {isPrimaryGrade
                          ? record.calificativMaterie
                          : record.medieMaterie}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {tCommon(
                          `basic.${!!record.medieIncheiata ? 'yes' : 'no'}`
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {tCommon(`basic.${!!record.corigenta ? 'yes' : 'no'}`)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {isPrimaryGrade
                          ? record.calificativFinalMaterie
                          : record.medieFinalaMaterie}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {isPrimaryGrade
                          ? record.calificativCorigenta
                          : record.notaCorigenta}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {tCommon(
                          `basic.${!!record.absentaCorigenta ? 'yes' : 'no'}`
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {dayjs(record.dataMedie).format('DD.MM.YYYY')}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {record.actiuniMedie &&
                        Object.keys(record.actiuniMedie).length ? (
                          <div className="flex gap-2">
                            {Object.keys(record.actiuniMedie).map(
                              (e, index: number) => {
                                return (
                                  <ButtonComp
                                    onButtonClick={() => {
                                      if (
                                        e ===
                                        ManageCloseSituationActionTypes.SetCorigentaMedie
                                      )
                                        averageAction(e, record.medieId);
                                      else {
                                        setDialogConfig({
                                          isOpen: true,
                                          type: 'average',
                                          typeOfAction:
                                            e as ManageCloseSituationActionTypes,
                                          averageId: record.medieId,
                                          observatii: '',
                                          motiv: -1,
                                          notaPurtare: 10,
                                          calificativPurtare:
                                            Calificative.Excelent,
                                        });
                                      }
                                    }}
                                    buttonText={tCommon(
                                      `inputs.closeSituation.${e}`
                                    )}
                                    buttonProps={{
                                      disabled: false,
                                      variant: 'contained',
                                    }}
                                    tailwindContainerClasses="flex justify-center"
                                  />
                                );
                              }
                            )}
                          </div>
                        ) : record.isAmanata ? (
                          <div>Amanata</div>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex gap-2 justify-center">
                {!!(
                  selectedSituation.filter(
                    (record) => 'actiuniSituatie' in record
                  ) as ActionSituation[]
                ).filter((record) => record.actiuniSituatie !== null).length &&
                  Object.keys(
                    (
                      selectedSituation.filter(
                        (record) => 'actiuniSituatie' in record
                      ) as ActionSituation[]
                    ).filter((record) => record.actiuniSituatie !== null)[0]
                      .actiuniSituatie as {}
                  ).map((e, index: number) => (
                    <ButtonComp
                      onButtonClick={() => {
                        setDialogConfig({
                          isOpen: true,
                          type: 'situation',
                          typeOfAction: e as ManageCloseSituationActionTypes,
                          observatii: '',
                          motiv: -1,
                          notaPurtare: 10,
                          calificativPurtare: Calificative.Excelent,
                        });
                      }}
                      buttonText={tCommon(`inputs.closeSituation.${e}`)}
                      buttonProps={{
                        disabled: false,
                        variant: 'contained',
                      }}
                      tailwindContainerClasses="flex justify-center"
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {dialogConfig.isOpen && (
        <Dialog
          fullWidth
          open={!!dialogConfig.isOpen}
          onClose={handleCloseDialog}
        >
          <DialogTitle className="flex justify-center">
            <div className="text-2xl font-bold">
              {tModules('average.observations')}
            </div>
          </DialogTitle>
          <DialogContent>
            <RegularInput
              isOptional={true}
              textFieldProps={{
                fullWidth: true,
                rows: 7,
                multiline: true,
                label: tModules('average.observations'),
                value: dialogConfig.observatii,
                onChange: (e) => {
                  setDialogConfig({
                    ...dialogConfig,
                    observatii: e.target.value,
                  });
                },
              }}
            />
            {![
              ManageCloseSituationActionTypes.SituatiePromovat,
              ManageCloseSituationActionTypes.SituatiePromovatPrimar,
            ].includes(dialogConfig.typeOfAction) && (
              <RegularSelect
                options={reasons.map((e) => ({
                  value: e.id,
                  label: e.label,
                }))}
                onChange={(e) => {
                  setDialogConfig((prev) => {
                    return {
                      ...prev,
                      motiv: e,
                    };
                  });
                }}
                selectProps={{
                  fullWidth: true,
                  label: tModules('average.reasoning'),
                  value: dialogConfig.calificativFinal || '',
                  autoWidth: true,
                }}
              />
            )}

            {![ManageCloseSituationActionTypes.SetAmanataMedie].includes(
              dialogConfig.typeOfAction
            ) && (
              <RegularSelect
                options={
                  isPrimaryGrade
                    ? [
                        Calificative.Excelent,
                        Calificative.FoarteBine,
                        Calificative.Bine,
                        Calificative.Suficient,
                        Calificative.Insuficient,
                      ].map((e) => ({
                        value: e,
                        label: e.toString(),
                      }))
                    : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => ({
                        value: e,
                        label: e.toString(),
                      }))
                }
                onChange={(e) => {
                  setDialogConfig((prev) => {
                    return {
                      ...prev,
                      ...(isPrimaryGrade
                        ? {
                            calificativPurtare: e,
                          }
                        : {
                            notaPurtare: e,
                          }),
                    };
                  });
                }}
                selectProps={{
                  fullWidth: true,
                  label: tModules(
                    isPrimaryGrade
                      ? 'average.calificativPurtare'
                      : 'average.gradePurtare'
                  ),
                  value: isPrimaryGrade
                    ? dialogConfig.calificativPurtare
                    : dialogConfig.notaPurtare,
                  autoWidth: true,
                }}
              ></RegularSelect>
            )}

            {dialogConfig.typeOfAction ===
              ManageCloseSituationActionTypes.SituatiePromovatPrimar && (
              <RegularSelect
                noOptionalOrMandatory={true}
                options={[
                  Calificative.Excelent,
                  Calificative.FoarteBine,
                  Calificative.Bine,
                  Calificative.Suficient,
                  Calificative.Insuficient,
                ].map((e) => ({
                  value: e,
                  label: e.toString(),
                }))}
                onChange={(e) => {
                  setDialogConfig((prev) => {
                    return {
                      ...prev,
                      calificativFinal: e as Calificative,
                    };
                  });
                }}
                selectProps={{
                  fullWidth: true,
                  label: tModules('average.finalCalificativ'),
                  value: dialogConfig.calificativFinal || '',
                  autoWidth: true,
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCloseDialog(false)}>
              {tCommon('inputs.closeButton')}
            </Button>
            <Button onClick={() => handleCloseDialog(true)}>
              {tCommon('inputs.confirmButton')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
