import { SubjectApi } from 'api/subject.api';
import { UserApi } from 'api/user.api';
import { AutocompleteInput } from 'common/components/inputs/AutocompleteInput';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { isObjectValid } from 'library/helpers/helper';
import { CollectionApiResponse } from 'library/types/Common';
import {
  SubjectConfig,
  SubjectConfigPayload,
  SubjectType,
} from 'library/types/Subject';
import { InvatoriDirigintiClase, User } from 'library/types/User';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const SubjectConfigModule = (): JSX.Element => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const { getAllProfessors, getAllInvatatori } = UserApi();
  const {
    getSubjectType,
    createSubjectConfig,
    getSubjectConfig,
    editSubjectConfig,
  } = SubjectApi();

  const location = useLocation();
  const state = location.state as {
    editId: number;
  };
  const { editId: subjectConfigId } = state || {};

  const [subjectConfigData, setSubjectConfigData] = useState<{
    teza: number;
    procentTeza?: string;
    user: string;
    tipMaterie: string;
  }>({
    teza: 0,
    procentTeza: '',
    user: '',
    tipMaterie: '',
  });
  const [professors, setProfessors] = useState<
    Array<User | InvatoriDirigintiClase>
  >([]);
  const [subjectTypes, setSubjectTypes] = useState<Array<SubjectType>>([]);
  const [
    subjectTypeAutocompleteInputValue,
    setSubjectTypeAutocompleteInputValue,
  ] = useState('');

  const [professorAutocompleteInputValue, setProfessorAutocompleteInputValue] =
    useState('');

  useEffect(() => {
    (async () => {
      const professors = await getAllProfessors();
      const invatatori = await getAllInvatatori();

      const allTeachers = [...professors, ...invatatori];
      const allUniqueTeachers: (User | InvatoriDirigintiClase)[] = [];

      for (let i = 0; i < allTeachers.length; i++) {
        if (
          allUniqueTeachers.findIndex((e) => e.id === allTeachers[i].id) !== -1
        )
          continue;

        allUniqueTeachers.push(allTeachers[i]);
      }
      const subjectTypes = (await getSubjectType({
        params: {
          pagination: 'false',
        },
      })) as CollectionApiResponse<SubjectType>;
      let subjectConfig: SubjectConfig | undefined;

      if (subjectConfigId) {
        subjectConfig = (await getSubjectConfig({
          id: subjectConfigId,
        })) as SubjectConfig;
      }

      setProfessors(allUniqueTeachers);
      setSubjectTypes(subjectTypes['hydra:member']);

      if (subjectConfig) {
        setSubjectTypeAutocompleteInputValue(
          (subjectConfig.tipMaterie as SubjectType).tipMaterie
        );

        setSubjectConfigData({
          teza: subjectConfig.teza ? 1 : 0,
          procentTeza: subjectConfig.procentTeza
            ? subjectConfig.procentTeza.toString()
            : undefined,
          user: subjectConfig.user.id.toString(),
          tipMaterie: (subjectConfig.tipMaterie as SubjectType).id.toString(),
        });
      }
    })();
  }, []);

  useEffect(() => {
    console.log('prof', professors);
  }, [professors]);

  const isActionValid = () => {
    if (!subjectConfigData) return false;

    if (!userContext.state.account?.user.scoala.id) return false;

    const objectToValidate = { ...subjectConfigData };
    if (!objectToValidate.teza) delete objectToValidate.procentTeza;

    if (!isObjectValid(objectToValidate)) {
      toast.error(
        tModules('schoolAdmin.subject.submitSubjectConfigValidationError')
      );
      return false;
    }
    return true;
  };

  const submitSubjectConfigAction = async () => {
    if (!isActionValid()) return;

    const { user, teza, procentTeza, tipMaterie } = subjectConfigData!;

    const payloadObject: SubjectConfigPayload = {
      scoala: `/api/scoli/${userContext.state.account!.user.scoala.id}`,
      user: `/api/users/${user}`,
      teza: !!teza,
      procentTeza: !teza ? 0 : parseInt(procentTeza!),
      tipMaterie: `/api/tipuriMaterie/${tipMaterie}`,
    };
    !subjectConfigId
      ? await createSubjectConfig(payloadObject)
      : await editSubjectConfig(subjectConfigId, payloadObject);

    navigate('/subject-configs');
  };

  return (
    <div className="page-container">
      {subjectConfigData && (
        <div className="flex items-center flex-col">
          <div
            style={{
              width: '600px',
            }}
            className="flex flex-col"
          >
            <AutocompleteInput
              autocompleteProps={{
                options: professors.map((e) => ({
                  nume: e.nume + ' ' + e.prenume,
                  id: e.id,
                })),
                value: {
                  nume: professorAutocompleteInputValue,
                  id: parseInt(subjectConfigData.user),
                },
                onChange: (
                  event: any,
                  newValue: {
                    nume: string;
                    id: number;
                  } | null
                ) => {
                  setSubjectConfigData((prev) => {
                    if (!newValue) return prev;
                    return {
                      ...prev,
                      user: newValue.id.toString(),
                    };
                  });
                },
                inputValue: professorAutocompleteInputValue,
                onInputChange: (event, newInputValue) => {
                  setProfessorAutocompleteInputValue(newInputValue);
                },
              }}
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.subject.professor'),
              }}
            />
            <AutocompleteInput
              autocompleteProps={{
                options: subjectTypes.map((e) => ({
                  nume: e.tipMaterie,
                  id: e.id,
                })),
                value: {
                  nume: subjectTypeAutocompleteInputValue,
                  id: parseInt(subjectConfigData.tipMaterie),
                },
                onChange: (
                  event: any,
                  newValue: {
                    nume: string;
                    id: number;
                  } | null
                ) => {
                  setSubjectConfigData((prev) => {
                    if (!newValue) return prev;
                    return {
                      ...prev,
                      tipMaterie: newValue.id.toString(),
                    };
                  });
                },
                inputValue: subjectTypeAutocompleteInputValue,
                onInputChange: (event, newInputValue) => {
                  setSubjectTypeAutocompleteInputValue(newInputValue);
                },
              }}
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.subject.subjectType'),
              }}
            />
            <RegularSelect
              options={[
                {
                  value: 0,
                  label: tCommon('basic.no'),
                },
                {
                  value: 1,
                  label: tCommon('basic.yes'),
                },
              ]}
              onChange={(e) => {
                setSubjectConfigData((prev) => ({
                  ...prev,
                  teza: e,
                  procentTeza: !e ? '' : prev.procentTeza,
                }));
              }}
              selectProps={{
                label: tModules('schoolAdmin.subject.midTermPaper'),
                value: subjectConfigData.teza,
              }}
            ></RegularSelect>
            <RegularInput
              textFieldProps={{
                disabled: !subjectConfigData.teza,
                fullWidth: true,
                label: tModules('schoolAdmin.subject.midTermPaperPercentage'),
                value: subjectConfigData.procentTeza,
                onChange: (e) => {
                  if (e.target.value === '' || /^[0-9]*$/.test(e.target.value))
                    setSubjectConfigData((prev) => ({
                      ...prev,
                      procentTeza: e.target.value,
                    }));
                },
              }}
            />
          </div>
          <ButtonComp
            onButtonClick={submitSubjectConfigAction}
            buttonText={tCommon('inputs.saveButton')}
            buttonProps={{
              variant: 'contained',
              defaultValue: 'Login',
            }}
            tailwindContainerClasses="flex justify-center"
          />
        </div>
      )}{' '}
    </div>
  );
};
