import { SubjectApi } from 'api/subject.api';
import { TermAkaModulApi } from 'api/termAkaModul.api';
import { TimeTableApi } from 'api/timetable.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import dayjs from 'dayjs';
import {
  changeDayNumberToDayString,
  changeDayStringToDayNumber,
} from 'library/helpers/helper';
import { IRI } from 'library/types/Common';
import { SimpleSubjectConfig } from 'library/types/Subject';
import {
  Days,
  TimeSlotForDaySchedule,
  TimeTablePayload,
} from 'library/types/TimeTable';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ManageTimeTableModule = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const state = location.state as {
    isEdit: boolean;
    availableTimeSlots:
      | {
          [key: string]: {
            [timeSlot: number]: TimeSlotForDaySchedule;
          };
        }
      | undefined;
    slot: TimeSlotForDaySchedule;
    intervalOrar: {
      ['@id']: IRI;
      start: string;
      end: string;
    };
    zi: number;
    anStudiu: number;
    selectedModule: number;
    classCode: string;
    classIri: IRI;
  };

  const {
    intervalOrar,
    zi,
    anStudiu,
    classCode,
    slot,
    isEdit,
    availableTimeSlots,
    classIri,
  } = state || {};

  const { createTimeTable, checkCatalogEntriesOnTimeTable, patchTimeTable } =
    TimeTableApi();
  const { getSubjectConfigsForStudyYear } = SubjectApi();
  const { getAvailableModules } = TermAkaModulApi();

  const [canEditSubject, setCanEditSubject] = useState<boolean>(false);

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<{
    ['@id']: IRI;
    label: string;
  }>({
    ['@id']: '',
    label: '',
  });

  const [selectedDay, setSelectedDay] = useState<number>(-1);

  const [subjectConfigs, setSubjectConfigs] = useState<
    Array<SimpleSubjectConfig>
  >([]);
  const [selectedSubjectConfig, setSelectedSubjectConfig] =
    useState<SimpleSubjectConfig>({
      id: -1,
      user: '',
      ['@id']: '',
      tipMaterie: '',
    });

  const [availableModules, setAvailableModules] = useState<
    Array<{
      id: number;
      ['@id']: IRI;
      details: string;
    }>
  >([]);
  const [selectedModules, setSelectedModules] = useState<
    Array<{
      id: number;
      ['@id']: IRI;
      details: string;
    }>
  >([]);

  useEffect(() => {
    if (
      !intervalOrar ||
      !intervalOrar['@id'] ||
      !zi ||
      !anStudiu ||
      !classCode
    ) {
      navigate('/view-timetable');
      return;
    }

    (async () => {
      if (slot.orarId) {
        setCanEditSubject(
          !(await checkCatalogEntriesOnTimeTable(slot.orarId)).hasEntries
        );
      }

      const subjectConfigs = await getSubjectConfigsForStudyYear(
        userContext.state.account!.user.scoala.id!,
        anStudiu
      );

      setSubjectConfigs(subjectConfigs);

      if (state.slot.orarIri) {
        const currentSubjectConfig = subjectConfigs.find((e) => {
          return e['@id'] === slot.materie!['@id'];
        });
        setSelectedSubjectConfig(currentSubjectConfig!);
      }

      const availableModulesResponse = await getAvailableModules();

      const availableModulesMapped = availableModulesResponse.map((e) => ({
        id: e.id,
        ['@id']: e['@id'],
        details: `${tModules('schoolAdmin.timeTable.orderAndDatePeriod')} ${
          e.ordine
        }, ${dayjs(e.dataInceput).format('DD.MM.YYYY')} - ${dayjs(
          e.dataSfarsit
        ).format('DD.MM.YYYY')}`,
      }));

      setAvailableModules(availableModulesMapped);

      if (state.selectedModule) {
        setSelectedModules([
          availableModulesMapped.find((e) => e.id == state.selectedModule)!,
        ]);
      }
    })();

    setSelectedDay(zi);

    const selectedTimeSlot = isEdit
      ? Object.values(availableTimeSlots![changeDayNumberToDayString(zi)]).find(
          (el: any) => el['intervalOrarIri'] === intervalOrar['@id']
        )!
      : {
          intervalOrarIri: intervalOrar['@id'],
          oraStart: intervalOrar.start,
          oraSfarsit: intervalOrar.end,
        };
    setSelectedTimeSlot({
      label: selectedTimeSlot.oraStart + ' - ' + selectedTimeSlot.oraSfarsit,
      ['@id']: selectedTimeSlot.intervalOrarIri,
    });
  }, []);

  const isActionValid = () => {
    if (
      !selectedTimeSlot['@id'] ||
      !selectedSubjectConfig['@id'] ||
      selectedDay === -1 ||
      !classIri ||
      selectedModules.length === 0
    ) {
      toast.error(
        tModules('schoolAdmin.timeTable.submitTimeTableValidationError')
      );
      return false;
    }

    if (
      selectedTimeSlot['@id'] === intervalOrar['@id'] &&
      zi === selectedDay &&
      selectedSubjectConfig['@id'] === slot.materie?.['@id']
    ) {
      toast.error(tModules('schoolAdmin.timeTable.sameTimeTableError'));
      return false;
    }
    return true;
  };

  const submitTimeTableAction = async () => {
    if (!isActionValid()) return;
    const payloadObject: TimeTablePayload = {
      scoala: `/api/scoli/${userContext.state.account!.user.scoala.id}`,
      intervalOrar: selectedTimeSlot['@id'],
      materie: selectedSubjectConfig['@id'],
      zi: selectedDay as 2 | 1 | 3 | 4 | 5 | 6 | 7,
      clasa: classIri,
      [isEdit ? 'modul' : 'module']: isEdit
        ? selectedModules[0]['@id']
        : selectedModules.map((e) => e['@id']),
    };

    isEdit
      ? await patchTimeTable(payloadObject, slot.orarId!)
      : await createTimeTable(payloadObject);

    navigate('/view-timetable', {
      state: {
        moduleIri: selectedModules[0]['@id'],
        classIri: classIri,
      },
    });
  };

  if (!state || !state.intervalOrar || !state.zi) return <></>;

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.timeTable.class'),
              value: classCode,
              disabled: true,
            }}
          />
          {isEdit ? (
            <RegularSelect
              options={Object.keys(availableTimeSlots!).map((e: string) => ({
                label: e,
                value: changeDayStringToDayNumber(e as Days),
              }))}
              onChange={(e) => {
                setSelectedDay(e);
                setSelectedTimeSlot({
                  ['@id']: '',
                  label: '',
                });
              }}
              selectProps={{
                label: tModules('schoolAdmin.timeTable.day'),
                value: selectedDay === -1 ? '' : selectedDay,
                fullWidth: true,
              }}
            />
          ) : (
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.timeTable.day'),
                value: zi,
                disabled: true,
              }}
            />
          )}
          {isEdit ? (
            <RegularSelect
              options={
                selectedDay !== -1
                  ? Object.values(
                      availableTimeSlots![
                        changeDayNumberToDayString(selectedDay)
                      ]
                    )
                      .sort((a, b) => {
                        return a.oraStart < b.oraStart ? -1 : 1;
                      })
                      .map((e: any) => ({
                        value: e.intervalOrarIri,
                        label: e.oraStart + ' - ' + e.oraSfarsit,
                      }))
                  : []
              }
              onChange={(e) => {
                if (selectedDay === -1) return;
                const selectedTimeSlot = Object.values(
                  availableTimeSlots![changeDayNumberToDayString(selectedDay)]
                ).find((el: any) => el['intervalOrarIri'] === e)!;
                setSelectedTimeSlot({
                  label:
                    selectedTimeSlot.oraStart +
                    ' - ' +
                    selectedTimeSlot.oraSfarsit,
                  ['@id']: selectedTimeSlot.intervalOrarIri,
                });
              }}
              selectProps={{
                disabled: selectedDay === -1,
                label: tModules('schoolAdmin.timeTable.timeSlot'),
                value: selectedTimeSlot['@id'],
                fullWidth: true,
              }}
            />
          ) : (
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('schoolAdmin.timeTable.timeSlot'),
                disabled: true,
                value: `${intervalOrar.start} - ${intervalOrar.end}`,
              }}
            />
          )}

          <RegularSelect
            options={subjectConfigs.map((e) => ({
              label: e.user + ' - ' + e.tipMaterie,
              value: e.id,
            }))}
            onChange={(e) => {
              setSelectedSubjectConfig(
                subjectConfigs.find((el) => el.id === e)!
              );
            }}
            selectProps={{
              disabled: isEdit && !canEditSubject,
              label: tModules('schoolAdmin.timeTable.subjectSelect'),
              value:
                selectedSubjectConfig.id === -1 ? '' : selectedSubjectConfig.id,
              fullWidth: true,
            }}
          />
          <RegularSelect
            options={availableModules.map((e) => ({
              label: e.details,
              value: e.id,
            }))}
            onChange={(arrayOfIds) => {
              setSelectedModules(
                arrayOfIds.map((entry: number) =>
                  availableModules.find((module) => {
                    return module.id === entry;
                  })
                )
              );
            }}
            selectProps={{
              disabled: isEdit,
              multiple: true,
              label:
                tModules('schoolAdmin.timeTable.moduleSelect') + !isEdit
                  ? tModules('schoolAdmin.timeTable.moduleSelectOptional')
                  : '',
              value: selectedModules.map((e) => e.id),
              fullWidth: true,
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={submitTimeTableAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Salveaza',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
