import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import { apiCallConfigTypes } from 'library/types/Common';
import { CatalogReportObjectResponse } from 'library/types/Reports';

export function ReportsApi() {
  const { apiHandler } = JWTInterceptor();

  const getCatalogReport = async (
    id: number
  ): Promise<CatalogReportObjectResponse> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/clase/catalog/${id}`,
      axiosConfig: apiCallConfigTypes.get,
    });

    return response.data;
  };

  return { getCatalogReport };
}
