import { CatedraApi } from 'api/catedra.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { UserContext } from 'context/UserContext';
import { CatedraPayload } from 'library/types/Catedra';
import { Catedra } from 'library/types/School';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const CatedraModule = (): JSX.Element => {
  const navigate = useNavigate();
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const userContext = useContext(UserContext);

  const { getCatedra, editCatedra, createCatedra } = CatedraApi();

  const location = useLocation();
  const state = location.state as {
    editId: number;
  };
  const { editId } = state || {};

  const [denumireCatedra, setDenumireCatedra] = useState<string>('');

  useEffect(() => {
    if (!editId) return;
    (async () => {
      const catedra = (await getCatedra({ id: editId })) as Catedra;
      setDenumireCatedra(catedra.denumireCatedra);
    })();
  }, []);

  const submitSubjectConfigAction = async () => {
    if (!denumireCatedra)
      return toast.error('Denumirea catedrei este obligatorie!');

    const payloadObject: CatedraPayload = {
      denumireCatedra,
      scoala: `/api/scoli/${userContext.state.account!.user.scoala.id}`,
    };
    !editId
      ? await createCatedra(payloadObject)
      : await editCatedra(editId, payloadObject);

    navigate('/table-catedra');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.catedra.denumireCatedra'),
              value: denumireCatedra,
              onChange: (e) => setDenumireCatedra(e.target.value),
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={submitSubjectConfigAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Login',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
