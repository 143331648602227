import { Typography } from '@mui/material';
import { AverageApi } from 'api/average.api';
import { ClassApi } from 'api/class.api';
import { SubjectApi } from 'api/subject.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { UtilityContext } from 'context/UtilityContext';
import dayjs from 'dayjs';
import {
  Calificative,
  GradesAndAbsences,
  MarksAndAbsences,
  PrimaryGradeOccurances,
} from 'library/types/Average';
import { SimpleCollectionClass } from 'library/types/Class';
import { SelectOption } from 'library/types/Common';
import { SubjectConfig } from 'library/types/Subject';
import { TablesConfig } from 'modules/SchoolAdmin/Tables/TablesConfig';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ManageAverageModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const navigate = useNavigate();

  const { getStudentsOfClass, getAvailableClasses } = ClassApi();
  const { getSubjectsForClass } = SubjectApi();
  const {
    getGradesAndAbsences,
    forcedCloseAverage,
    unclosedAverage,
    closeAverage,
    getPrimaryGradeOccurances,
  } = AverageApi();

  const [selectedClass, setSelectedClass] = useState<number>(-1);
  const [isPrimaryGrade, setIsPrimaryGrade] = useState<boolean>(false);
  const [primaryGradeOccurances, setPrimaryGradeOccurances] = useState<
    SelectOption[]
  >([]);
  const [selectedGradeOccurance, setSelectedGradeOccurance] =
    useState<string>('');

  const [selectedSubjectConfig, setSelectedSubjectConfig] =
    useState<number>(-1);
  const [selectedStudent, setSelectedStudent] = useState<number>(-1);

  const [subjects, setSubjects] = useState<SubjectConfig[]>([]);
  const [classes, setClasses] = useState<SimpleCollectionClass[]>([]);
  const [students, setStudents] = useState<
    Array<{
      id: number;
      nume: string;
      prenume: string;
    }>
  >([]);

  const [gradesAndAbsences, setGradesAndAbsences] = useState<GradesAndAbsences>(
    {
      note: [],
      absente: [],
      isInchisa: false,
    }
  );

  const [shouldShowNextOptions, setShouldShowNextOptions] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const classes = await getAvailableClasses();

      setClasses(classes);
    })();
  }, []);

  useEffect(() => {
    if (selectedClass === -1) return;
    setSelectedSubjectConfig(-1);
    setSelectedStudent(-1);
    (async () => {
      const subjectConfigs = await getSubjectsForClass(selectedClass);
      const students = await getStudentsOfClass(selectedClass);

      setStudents(students);
      setSubjects(subjectConfigs);
    })();
  }, [selectedClass]);

  useEffect(() => {
    if (selectedStudent === -1) return;
    fetchStudentGradesAction();
  }, [selectedSubjectConfig]);

  useEffect(() => {
    if (selectedStudent === -1)
      return setGradesAndAbsences({
        note: [],
        absente: [],
        isInchisa: false,
      });

    (async () => {
      await fetchStudentGradesAction();
    })();
  }, [selectedStudent]);

  const resetState = () => {
    setSelectedSubjectConfig(-1);
    setSelectedStudent(-1);
  }

  const fetchStudentGradesAction = async () => {
    setShouldShowNextOptions(false);
    setPrimaryGradeOccurances([]);

    const gradesAndAbsences = await getGradesAndAbsences({
      subjectId: selectedSubjectConfig,
      studentId: selectedStudent,
      isPrimaryGrade: isPrimaryGrade,
    });

    if (typeof gradesAndAbsences === 'string')
      setGradesAndAbsences({ note: [], absente: [], isInchisa: false });
    else {
      if (isPrimaryGrade) {
        setGradesAndAbsences({
          absente: (gradesAndAbsences as MarksAndAbsences).absente,
          note: (gradesAndAbsences as MarksAndAbsences).calificative.map(
            (e) => ({
              cadru: e.cadru,
              data: e.data,
              nota: e.nota,
            })
          ),
          isInchisa: (gradesAndAbsences as MarksAndAbsences).isInchisa,
        });
      } else {
        setGradesAndAbsences({
          absente: (gradesAndAbsences as GradesAndAbsences).absente,
          note: (gradesAndAbsences as GradesAndAbsences).note,
          isInchisa: (gradesAndAbsences as GradesAndAbsences).isInchisa,
        });
      }
    }
    if (isPrimaryGrade) {
      const primaryGradeOccurances = await getPrimaryGradeOccurances({
        subjectId: selectedSubjectConfig,
        studentId: selectedStudent,
      });

      const parsedPrimaryGradeOccurances = sortByCalificativ(
        primaryGradeOccurances
      );

      setPrimaryGradeOccurances(parsedPrimaryGradeOccurances);
      setSelectedGradeOccurance(parsedPrimaryGradeOccurances[0]?.value || '');
    }
  };

  const closeAverageAction = async () => {
    const closeAverageResponse = await closeAverage({
      subjectId: selectedSubjectConfig,
      studentId: selectedStudent,
      isPrimaryGrade: isPrimaryGrade,
      calificativ: isPrimaryGrade ? selectedGradeOccurance : undefined,
    });

    if (closeAverageResponse.medieNeincheiata) {
      toast.error(tModules('average.averageNotClosed'));
      setShouldShowNextOptions(true);
    } else {
      // navigate(`/average-table-${isPrimaryGrade ? 'primary' : 'non-primary'}`);
      resetState();
    }
  };

  const forceCloseAverageAction = async () => {
    await forcedCloseAverage({
      subjectId: selectedSubjectConfig,
      studentId: selectedStudent,
      isPrimaryGrade: isPrimaryGrade,
      calificativ: isPrimaryGrade ? selectedGradeOccurance : undefined,
    });
    resetState();
    // navigate(`/average-table-${isPrimaryGrade ? 'primary' : 'non-primary'}`);

  };

  const unclosedAverageAction = async () => {
    await unclosedAverage({
      subjectId: selectedSubjectConfig,
      studentId: selectedStudent,
      isPrimaryGrade: isPrimaryGrade,
    });
    resetState();
    // navigate(`/average-table-${isPrimaryGrade ? 'primary' : 'non-primary'}`);

  };

  const sortByCalificativ = (
    primaryGradeOccurances: PrimaryGradeOccurances
  ) => {
    if (!primaryGradeOccurances) return [];
    const calificativeOrder = [
      Calificative.Excelent,
      Calificative.FoarteBine,
      Calificative.Bine,
      Calificative.Suficient,
      Calificative.Insuficient,
    ];

    return Object.entries(primaryGradeOccurances)
      .sort((a, b) => {
        if (a[1] === b[1]) {
          const aIndex = calificativeOrder.indexOf(a[0] as Calificative);
          const bIndex = calificativeOrder.indexOf(b[0] as Calificative);
          return aIndex - bIndex;
        }
        return Number(b[1]) - Number(a[1]);
      })
      .map(([key, value]) => ({
        value: key,
        label: key + ' - ' + value,
      }));
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularSelect
            options={classes.map((e) => ({
              value: e.id,
              label: e.codClasa as string,
            }))}
            onChange={(e) => {
              const selectedClass = classes.find((el) => el.id === e);
              console.log(parseInt(selectedClass!.codClasa));
              setIsPrimaryGrade(parseInt(selectedClass!.codClasa) < 5);
              setSelectedClass(selectedClass!.id);
            }}
            selectProps={{
              label: tModules('average.class'),
              value: selectedClass,
              fullWidth: true,
            }}
          />
          <RegularSelect
            options={subjects.map((e) => ({
              value: e.id,
              label: e.tipMaterie as string,
            }))}
            onChange={(e) => {
              setSelectedSubjectConfig(subjects.find((el) => el.id === e)!.id);
            }}
            selectProps={{
              disabled: selectedClass === -1,
              label: tModules('average.subject'),
              value: selectedSubjectConfig,
              fullWidth: true,
            }}
          />
          <RegularSelect
            options={students.map((e) => ({
              value: e.id,
              label: e.nume + ' ' + e.prenume,
            }))}
            onChange={(e) => {
              setSelectedStudent(students.find((el) => el.id === e)!.id);
            }}
            selectProps={{
              disabled: selectedSubjectConfig === -1 || selectedClass === -1,
              label: tModules('average.student'),
              value: selectedStudent,
              fullWidth: true,
            }}
          />
        </div>
        {gradesAndAbsences.note.length > 0 && (
          <div
            style={{
              width: '800px',
            }}
            className="flex flex-col gap-4"
          >
            <div className="flex justify-center mt-2">
              <Typography variant="h6">{tModules('average.grades')}</Typography>
            </div>

            <table className="min-w-full table-auto bg-white rounded-lg overflow-hidden shadow-lg">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    {tModules('average.gradeTableProfessor')}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    {tModules('average.gradeTableValue')}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    {tModules('average.gradeTableDate')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {gradesAndAbsences.note.map((grade, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {grade.cadru}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {grade.nota}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {dayjs(grade.data).format('DD.MM.YYYY')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {gradesAndAbsences.absente.length > 0 && (
          <div
            style={{
              width: '800px',
            }}
            className="flex flex-col gap-4"
          >
            <div className="flex justify-center mt-6">
              <Typography variant="h6">
                {tModules('average.absences')}
              </Typography>
            </div>
            <table className="min-w-full table-auto bg-white rounded-lg overflow-hidden shadow-lg">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    {tModules('average.absenceTableProfessor')}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    {tModules('average.absenceTableDate')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {gradesAndAbsences.absente.map((absence, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {absence.cadru}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {dayjs(absence.data).format('DD.MM.YYYY')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {isPrimaryGrade &&
          primaryGradeOccurances &&
          selectedStudent !== -1 &&
          !gradesAndAbsences.isInchisa && (
            <div
              style={{
                width: '600px',
              }}
              className="flex flex-col mt-6"
            >
              <RegularSelect
                options={primaryGradeOccurances}
                onChange={(e) => {
                  setSelectedGradeOccurance(e);
                }}
                selectProps={{
                  label: tModules('average.preponderentaCalificativ'),
                  value: selectedGradeOccurance,
                  fullWidth: true,
                }}
              />
            </div>
          )}
        {selectedStudent !== -1 && !gradesAndAbsences.isInchisa && (
          <div className="flex flex-col mt-6">
            <ButtonComp
              onButtonClick={() => closeAverageAction()}
              buttonText={tCommon('inputs.closeAverageAction')}
              buttonProps={{
                disabled: shouldShowNextOptions,
                variant: 'contained',
              }}
              tailwindContainerClasses="flex justify-center"
            />
            {shouldShowNextOptions && (
              <div className="flex flex-col">
                <ButtonComp
                  onButtonClick={() => forceCloseAverageAction()}
                  buttonText={tCommon('inputs.forceCloseAverageAction')}
                  buttonProps={{
                    disabled: !shouldShowNextOptions,
                    variant: 'contained',
                  }}
                  tailwindContainerClasses="flex justify-center"
                />
                <ButtonComp
                  onButtonClick={() => unclosedAverageAction()}
                  buttonText={tCommon('inputs.unclosedAverageAction')}
                  buttonProps={{
                    disabled: !shouldShowNextOptions,
                    variant: 'contained',
                  }}
                  tailwindContainerClasses="flex justify-center"
                />
              </div>
            )}
          </div>
        )}
        {selectedStudent !== -1 && gradesAndAbsences.isInchisa && (
          <div className="mt-3">
            <Typography variant="h3">
              {tModules('average.averageClosed')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
