import { ClassApi } from 'api/class.api';
import { SubjectApi } from 'api/subject.api';
import { TestApi } from 'api/test.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { DatePickerInput } from 'common/components/inputs/DatePickerInput';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { TimePickerInput } from 'common/components/inputs/TimePickerInput';
import { UserContext } from 'context/UserContext';
import { UtilityContext } from 'context/UtilityContext';
import dayjs from 'dayjs';
import { extractIdFromIri } from 'library/helpers/helper';
import { SelectOption } from 'library/types/Common';
import { SubjectType } from 'library/types/Subject';
import { Test, TestPayload } from 'library/types/Test';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ManageTestModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const utilityContext = useContext(UtilityContext);
  const userContext = useContext(UserContext);

  const state = location.state as {
    editId: number;
  };

  const { editId: testId } = state || {};

  const { createTest, getProfessorsTest, editTest } = TestApi();
  const { getAvailableClasses } = ClassApi();
  const { getSubjectsForClass } = SubjectApi();

  const [testData, setTestData] = useState<TestPayload>({
    clasa: '',
    materie: '',
    titlu: '',
    startsAt: dayjs(),
    endsAt: dayjs(),
    activ: false,
  });

  const [subjects, setSubjects] = useState<Array<SelectOption>>([]);

  const [classes, setClasses] = useState<Array<SelectOption>>([]);

  useEffect(() => {
    if (location.pathname.includes('edit') && !testId) navigate('/home');

    (async () => {
      const classes = await getAvailableClasses();

      setClasses(
        classes.map((e) => {
          return {
            value: `/api/clase/${e.id}`,
            label: e.codClasa,
          };
        })
      );

      if (testId) {
        const test = (await getProfessorsTest({
          id: testId,
        })) as Test;

        setTestData({
          clasa: test.clasa['@id'],
          materie: test.materie['@id'],
          titlu: test.titlu,
          startsAt: dayjs(test.startsAt),
          endsAt: dayjs(test.endsAt),
          activ: test.activ,
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (!testData.clasa) {
      setSubjects([]);
      setTestData(() => ({
        ...testData,
        materie: '',
      }));
      return;
    }

    (async () => {
      const subjects = await getSubjectsForClass(
        extractIdFromIri(testData.clasa)
      );
      setSubjects(
        subjects.map((e) => ({
          value: e['@id'],
          label: e.tipMaterie as string,
        }))
      );
    })();
  }, [testData.clasa]);

  const submitFormAction = async () => {
    const { endsAt, startsAt, titlu, materie, clasa, activ } = testData;

    if (!startsAt || !endsAt || !titlu || !materie || !clasa)
      return toast.error(tModules('test.createEditTestError'));

    if (startsAt > endsAt)
      return toast.error(tModules('test.startAfterEndError'));

    const testPayload: TestPayload = {
      clasa,
      materie,
      titlu,
      startsAt,
      endsAt,
      activ,
    };

    try {
      testId
        ? await editTest(testId, testPayload)
        : await createTest(testPayload);

      navigate('/table-test/professors');
    } catch (e) {
      console.log('err in manage test module');
    }
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularSelect
            options={classes}
            onChange={(e) => {
              setTestData((prev) => ({
                ...prev,
                clasa: e,
              }));
            }}
            selectProps={{
              label: tModules('test.class'),
              value: testData.clasa,
              fullWidth: true,
            }}
          />
          <RegularSelect
            options={subjects}
            onChange={(e) => {
              setTestData((prev) => ({
                ...prev,
                materie: e,
              }));
            }}
            selectProps={{
              disabled: !testData.clasa || !subjects.length,
              label: tModules('test.subject'),
              value: testData.materie,
              fullWidth: true,
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('test.title'),
              value: testData.titlu,
              onChange: (e) => {
                setTestData((prev) => {
                  return { ...prev, titlu: e.target.value };
                });
              },
            }}
          />
          <TimePickerInput
            isDateTimePicker={true}
            dateTimePickerProps={{
              minTime: dayjs().startOf('day'),
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('test.startsAt'),
              value: dayjs(testData.startsAt),
              onChange: (e) => {
                if (!e) return;
                setTestData((prev) => ({
                  ...prev,
                  startsAt: e,
                }));
              },
            }}
          />
          <TimePickerInput
            isDateTimePicker={true}
            dateTimePickerProps={{
              minTime: dayjs().startOf('day'),
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('test.endsAt'),
              value: dayjs(testData.endsAt),
              onChange: (e) => {
                if (!e) return;
                setTestData((prev) => ({
                  ...prev,
                  endsAt: e,
                }));
              },
            }}
          />
          <RegularSelect
            options={[
              {
                label: tCommon('basic.yes'),
                value: true,
              },
              {
                label: tCommon('basic.no'),
                value: false,
              },
            ]}
            onChange={(e) => {
              setTestData((prevValue) => {
                return {
                  ...prevValue,
                  activ: e,
                };
              });
            }}
            selectProps={{
              label: tModules('test.activeSelect'),
              value: testData.activ,
              fullWidth: true,
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={submitFormAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
