import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import { SchoolYear, SchoolYearPayload } from 'library/types/SchoolYear';
import { StudyYear } from 'library/types/StudyYear';
export function StudyYearApi() {
  const { apiHandler } = JWTInterceptor();

  const getStudyYear = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<StudyYear | CollectionApiResponse<StudyYear>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/aniStudiu/${id}` : `${serverUri()}/aniStudiu/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  return {
    getStudyYear,
  };
}
