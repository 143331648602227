import { ClassApi } from 'api/class.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { isObjectValid } from 'library/helpers/helper';
import { ClassProfilePayload } from 'library/types/Class';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ClassProfileModule = (): JSX.Element => {
  const navigate = useNavigate();
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const { createClassProfile } = ClassApi();

  const [classProfileData, setClassProfileData] = useState<ClassProfilePayload>(
    {
      tipProfilClasa: '',
    }
  );

  const isActionValid = () => {
    if (!isObjectValid(classProfileData)) {
      toast.error(
        tModules('schoolAdmin.classProfile.submitClassProfileValidationError')
      );
      return false;
    }
    return true;
  };

  const classProfileAction = async (id?: number) => {
    if (!isActionValid()) return false;

    const { tipProfilClasa } = classProfileData;

    const payloadObject: ClassProfilePayload = {
      tipProfilClasa,
    };
    try {
      await createClassProfile(payloadObject);
      navigate('/class-profiles');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('schoolAdmin.classProfile.classProfileType'),
              value: classProfileData.tipProfilClasa,
              onChange: (e) => {
                setClassProfileData({
                  tipProfilClasa: e.target.value,
                });
              },
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={classProfileAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Login',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
