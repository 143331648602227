import { createContext, Dispatch, useReducer } from 'react';

export enum UtilityContextActionTypes {
  ApiLoading = 'API_LOADING',
  ApiLoaded = 'API_LOADED',
}

export interface UtilityContextState {
  isLoading: boolean;
}

export interface ContextAction {
  type: UtilityContextActionTypes;
}

const userContextReducer = (
  state: UtilityContextState,
  action: ContextAction
): UtilityContextState => {
  let newState = state;
  switch (action.type) {
    case UtilityContextActionTypes.ApiLoading:
      newState = {
        ...state,
        isLoading: true,
      };
      break;
    case UtilityContextActionTypes.ApiLoaded:
      newState = {
        ...state,
        isLoading: false,
      };
      break;
  }

  return { ...newState };
};

const initialState = {
  isLoading: false,
};

const getInitialContext = (): UtilityContextState => {
  let context = initialState;

  return context;
};

export const UtilityContext = createContext<{
  state: UtilityContextState;
  dispatch: Dispatch<ContextAction>;
}>({
  state: getInitialContext(),
  dispatch: () => null,
});

export const UtilityContextProvider = (props: any): JSX.Element => {
  const [state, dispatch] = useReducer(userContextReducer, getInitialContext());

  return (
    <UtilityContext.Provider value={{ state: state, dispatch: dispatch }}>
      {props.children}
    </UtilityContext.Provider>
  );
};
