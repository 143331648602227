import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import { CatedraPayload } from 'library/types/Catedra';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import { Catedra } from 'library/types/School';

export function CatedraApi() {
  const { apiHandler } = JWTInterceptor();

  const getCatedra = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Catedra | CollectionApiResponse<Catedra>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/catedre/${id}` : `${serverUri()}/catedre/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createCatedra = async (
    catedraInfo: CatedraPayload
  ): Promise<Catedra> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/catedre`,
      data: catedraInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Catedra was successfully created!',
    });

    return response.data;
  };

  const editCatedra = async (
    id: number,
    data: Partial<CatedraPayload>
  ): Promise<Catedra> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/catedre/${id}`,
      data: data,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The catedra type was successfully modified!',
    });

    return response.data;
  };

  return {
    getCatedra,
    editCatedra,
    createCatedra,
  };
}
