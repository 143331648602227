import { Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { UserContext } from 'context/UserContext';
import { UserRoles } from 'library/types/User';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const EditAction = ({ id, path }: { id: number; path: string }) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const userRole = userContext.state.account!.selectedView;

  if (userRole !== UserRoles.ROLE_SCHOOL_ADMIN) return <></>;

  return (
    <Tooltip arrow title="Editeaza">
      <div
        className="cursor-pointer"
        onClick={() => {
          navigate(path, {
            state: {
              editId: id,
            },
          });
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
          <IconButton color="secondary">
            <Edit />
          </IconButton>
        </Box>
      </div>
    </Tooltip>
  );
};
