import { useTranslation } from 'react-i18next';

export enum UserRoles {
  ROLE_SCHOOL_ADMIN = 'ROLE_SCHOOL_ADMIN',
  ROLE_SECRETAR = 'ROLE_SECRETAR',
  ROLE_DIRECTOR = 'ROLE_DIRECTOR',
  ROLE_DIRIGINTE = 'ROLE_DIRIGINTE',
  ROLE_INVATATOR = 'ROLE_INVATATOR',
  ROLE_PROFESOR = 'ROLE_PROFESOR',
  ROLE_PARINTE = 'ROLE_PARINTE',
  ROLE_ELEV = 'ROLE_ELEV',
  ROLE_USER = 'ROLE_USER',
}

// for UserRoles enum create a function to get human string values for each role in a hook so it can use the translation hook
export const useUserRoles = () => {
  const { t } = useTranslation('common');
  return {
    [UserRoles.ROLE_SCHOOL_ADMIN]: t(
      `userRoles.${UserRoles.ROLE_SCHOOL_ADMIN}`
    ),
    [UserRoles.ROLE_SECRETAR]: t(`userRoles.${UserRoles.ROLE_SECRETAR}`),
    [UserRoles.ROLE_DIRECTOR]: t(`userRoles.${UserRoles.ROLE_DIRECTOR}`),
    [UserRoles.ROLE_DIRIGINTE]: t(`userRoles.${UserRoles.ROLE_DIRIGINTE}`),
    [UserRoles.ROLE_INVATATOR]: t(`userRoles.${UserRoles.ROLE_INVATATOR}`),
    [UserRoles.ROLE_PROFESOR]: t(`userRoles.${UserRoles.ROLE_PROFESOR}`),
    [UserRoles.ROLE_PARINTE]: t(`userRoles.${UserRoles.ROLE_PARINTE}`),
    [UserRoles.ROLE_ELEV]: t(`userRoles.${UserRoles.ROLE_ELEV}`),
  };
};
