import axios, { AxiosRequestConfig } from 'axios';
import { publicServerUri } from 'config';
import {
  LOCAL_STORAGE_CONTEXT,
  UserContext,
  UserContextActionTypes,
} from 'context/UserContext';
import {
  UtilityContext,
  UtilityContextActionTypes,
} from 'context/UtilityContext';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const instance = axios.create();

instance.interceptors.request.use((request) => {
  const storedContextString = localStorage.getItem(LOCAL_STORAGE_CONTEXT);
  if (storedContextString) {
    const context = JSON.parse(storedContextString);
    const account = context.account;
    const isLoggedIn = account && account.accessToken;
    const isApiUrl = request.url && request.url.startsWith(publicServerUri());

    if (request && request.headers && isLoggedIn && isApiUrl) {
      request.headers.Authorization = `Bearer ${account.accessToken}`;
    }
  }
  return request;
});

export function JWTInterceptor() {
  const { t } = useTranslation('library');
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const utilityContext = useContext(UtilityContext);

  const apiHandler = useCallback(
    async (config: {
      method: 'get' | 'patch' | 'post' | 'delete' | 'put';
      url: string;
      data?: any;
      axiosConfig?: AxiosRequestConfig;
      successMessage?: string;
      errorMessage?: string;
    }): Promise<{
      data?: any;
      error: string | null;
    }> => {
      return new Promise((resolve, reject) => {
        try {
          let apiCall;
          let error: string;
          if (config.data && config.axiosConfig) {
            apiCall = instance[config.method](
              config.url,
              config.data,
              config.axiosConfig
            );
          } else if (config.data) {
            apiCall = instance[config.method](config.url, config.data);
          } else if (config.axiosConfig) {
            apiCall = instance[config.method](config.url, config.axiosConfig);
          } else {
            apiCall = instance[config.method](config.url);
          }
          utilityContext.dispatch({
            type: UtilityContextActionTypes.ApiLoading,
          });
          apiCall
            .then((res) => {
              if (config.successMessage) {
                toast.success(config.successMessage);
              }
              resolve({
                data: res.data,
                error: null,
              });
            })
            .catch((e) => {
              error = config.errorMessage || t('jwtInterceptor.tryLater');
              if (e && e.response) {
                error = e.response.data?.message || error;
                if (
                  [401].includes(e.response.status) &&
                  !config.url.includes('/login')
                ) {
                  userContext.dispatch({ type: UserContextActionTypes.LogOut });

                  navigate('/login');
                }
              }
              if (e.response?.data?.error_message) {
                toast.error(e.response.data.error_message);
              } else {
                toast.error(error);
              }
              return reject({
                error,
              });
            })
            .finally(() => {
              utilityContext.dispatch({
                type: UtilityContextActionTypes.ApiLoaded,
              });
            });
        } catch (e) {
          if (!config || !config.method) {
            toast.error(t('jwtInterceptor.invalidRequest'));
            reject({ error: t('jwtInterceptor.invalidRequest') });
          } else {
            toast.error(t('jwtInterceptor.unknownIssue'));
            reject({ error: t('jwtInterceptor.unknownIssue') });
          }
          utilityContext.dispatch({
            type: UtilityContextActionTypes.ApiLoaded,
          });
        }
      });
    },
    []
  );

  return {
    apiHandler,
  };
}
