import { Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { UserContext } from 'context/UserContext';
import { UserRoles } from 'library/types/User';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from 'resources/images';

export const TestActions = ({
  id,
  basePath,
  hasAnswers,
  isTest,
}: {
  id: number;
  basePath: string;
  hasAnswers?: boolean;
  isTest?: boolean;
}) => {
  const userContext = useContext(UserContext);
  const userRole = userContext.state.account!.selectedView;
  const navigate = useNavigate();

  return (
    <div className="flex gap-2">
      {isTest && [UserRoles.ROLE_ELEV].includes(userRole) && !hasAnswers && (
        <div className="flex">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(`/answer-test`, {
                state: {
                  testId: id,
                },
              });
            }}
          >
            <div className="text-blue-500 underline">Incepe testul</div>
          </div>
        </div>
      )}
      {isTest &&
        (([UserRoles.ROLE_ELEV].includes(userRole) && hasAnswers) ||
          userRole !== UserRoles.ROLE_ELEV) && (
          <div
            className="text-blue-500 underline cursor-pointer text-center flex items-center"
            onClick={() => {
              navigate('/table-test-question-answer', {
                state: {
                  queryParams: {
                    test: `/api/teste/${id}`,
                  },
                },
              });
            }}
          >
            Vezi raspunsuri
          </div>
        )}

      {[UserRoles.ROLE_INVATATOR, UserRoles.ROLE_PROFESOR].includes(
        userRole
      ) && (
        <div className="flex items-center">
          <Tooltip arrow title="Editeaza">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate(`/${basePath}/edit`, {
                  state: {
                    editId: id,
                  },
                });
              }}
            >
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <IconButton color="secondary">
                  <Edit />
                </IconButton>
              </Box>
            </div>
          </Tooltip>
        </div>
      )}

      {isTest &&
        [UserRoles.ROLE_INVATATOR, UserRoles.ROLE_PROFESOR].includes(
          userRole
        ) && (
          <div className="flex items-center">
            <Tooltip arrow title="Asociaza intrebari la test">
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate(`/test-question-to-test`, {
                    state: {
                      editId: id,
                    },
                  });
                }}
              >
                <img src={icons.general.bookWithPencil} width={25} />
              </div>
            </Tooltip>
          </div>
        )}
    </div>
  );
};
