import { Typography, useTheme } from '@mui/material';
import { ClassApi } from 'api/class.api';
import { EvaluationApi } from 'api/evaluation.api';
import { TimeTableApi } from 'api/timetable.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { UserContext } from 'context/UserContext';
import { CollectionApiResponse, IRI } from 'library/types/Common';
import {
  EvaluationForProfessorResponse,
  EvaluationForStudentResponse,
  EvaluationResponsePayload,
  RaspunsuriIntrebari,
  SentEvaluationForProfessorResponse,
  SentEvaluationForStudentResponse,
} from 'library/types/Evaluation';
import { CollectionTimeTable } from 'library/types/TimeTable';
import { UserRoles } from 'library/types/User';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from 'resources/images';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

const generateImages = (
  isDisabled: boolean,
  A: number | null,
  questionIri: IRI,
  answer: (answer: number, questionIri: IRI) => void
) => {
  let images = [];

  for (let i = 0; i < 5; i++) {
    images.push(
      <img
        className="cursor-pointer"
        onClick={() => {
          if (isDisabled) return;
          answer(i + 1, questionIri);
        }}
        width={25}
        key={i}
        src={
          !A
            ? icons.general.fullCircle
            : i < A
            ? icons.general.fullCircleFilled
            : icons.general.fullCircle
        }
        alt="Type X"
      />
    );
  }

  return images;
};

export const EvaluationResponseModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const selectedRole = userContext.state.account!.selectedView;
  const theme = useTheme();

  const { getAvailableClasses, getStudentsOfClass } = ClassApi();
  const {
    sendEvaluationResponse,
    getCurrentEvaluationConfig,
    getSentEvaluationResponse,
  } = EvaluationApi();
  const { getTimeTable } = TimeTableApi();

  const [classes, setClasses] = useState<
    Array<{
      id: number;
      classCode: string;
    }>
  >([]);
  const [selectedClass, setSelectedClass] = useState<number | null>(null);

  const [subjects, setSubjects] = useState<
    Array<{
      '@id': IRI;
      numeMaterie: string;
    }>
  >([]);
  const [selectedSubject, setSelectedSubject] = useState<IRI>('');

  const [students, setStudents] = useState<{
    [classId: string]: Array<{
      id: number;
      nume: string;
      prenume: string;
    }>;
  }>();
  const [selectedStudent, setSelectedStudent] = useState<number | null>();

  const [configEvaluare, setConfigEvaluare] = useState<IRI>('');

  const [questions, setQuestions] = useState<{
    [iri: IRI]: {
      text: string;
      response: number | null;
    };
  }>();
  const [currentAnswers, setCurrentAnswers] = useState<{
    [entitateEvaluata: IRI]: {
      [iriIntrebare: IRI]: string;
    };
  }>({});

  useEffect(() => {
    (async () => {
      const evaluationConfig = await getCurrentEvaluationConfig();
      const evaluationCurrentResponses = await getSentEvaluationResponse();
      const questionsFromConfig = evaluationConfig?.intrebari || [];
      const questionsMapped: typeof questions = {};
      Object.keys(questionsFromConfig).map((e) => {
        questionsMapped[e] = {
          text: questionsFromConfig[e],
          response: null,
        };
      });

      setQuestions(questionsMapped);
      setConfigEvaluare(
        evaluationConfig ? '/api/config-evaluare/' + evaluationConfig.id : ''
      );

      const currentAnswers: {
        [oraEvaluata: IRI]: {
          [iriIntrebare: IRI]: string;
        };
      } = {};

      if (selectedRole === UserRoles.ROLE_ELEV) {
        const timetables =
          (await getTimeTable()) as CollectionApiResponse<CollectionTimeTable>;

        setSubjects(
          _.uniqBy(timetables['hydra:member'], 'materie.id').map((e) => ({
            numeMaterie: e.materie.tipMaterie.tipMaterie,
            '@id': `/api/orare/${e.id}`,
          }))
        );

        (
          evaluationCurrentResponses as SentEvaluationForProfessorResponse[]
        ).forEach((e) => {
          if (!e.oraEvaluata) return;
          const raspunsuri = parseRaspunsuri(e.raspunsuri);

          currentAnswers[e.oraEvaluata] = {
            ...raspunsuri,
          };
        });
      } else {
        const availableClasses = await getAvailableClasses();
        setClasses(
          availableClasses
            .filter((e) => !Array.isArray(e))
            .map((e) => ({
              id: e.id,
              classCode: e.codClasa,
            }))
        );

        (
          evaluationCurrentResponses as SentEvaluationForStudentResponse[]
        ).forEach((e) => {
          if (!e.userEvaluat) return;
          const raspunsuri = parseRaspunsuri(e.raspunsuri);

          currentAnswers[e.userEvaluat] = {
            ...raspunsuri,
          };
        });
      }
      setCurrentAnswers(currentAnswers);
    })();
  }, []);

  useEffect(() => {
    if (!selectedClass) return;

    if (students && students[selectedClass.toString()]) return;

    getStudentsOfClass(selectedClass).then((students) => {
      setStudents((prevStudents) => {
        return prevStudents
          ? {
              ...prevStudents,
              [selectedClass.toString()]: students.map((e) => ({
                id: e.id,
                nume: e.nume,
                prenume: e.prenume,
              })),
            }
          : {
              [selectedClass.toString()]: students.map((e) => ({
                id: e.id,
                nume: e.nume,
                prenume: e.prenume,
              })),
            };
      });
    });
  }, [selectedClass]);

  const parseRaspunsuri = (raspunsuriToParse: RaspunsuriIntrebari) => {
    const raspunsuri: {
      [questionIri: IRI]: string;
    } = {};

    Object.keys(raspunsuriToParse).forEach((el) => {
      raspunsuri[el] = raspunsuriToParse[el].raspuns.toString();
    });

    return raspunsuri;
  };

  const sendResponseAction = async () => {
    if (
      !configEvaluare ||
      !questions ||
      Object.values(questions)
        .map((e) => e.response)
        .findIndex((e) => !e) !== -1
    ) {
      return;
    }
    if (
      ![
        UserRoles.ROLE_PROFESOR,
        UserRoles.ROLE_INVATATOR,
        UserRoles.ROLE_ELEV,
      ].includes(selectedRole)
    ) {
      return;
    }
    if (selectedRole !== UserRoles.ROLE_ELEV && !selectedStudent) return;
    if (selectedRole === UserRoles.ROLE_ELEV && !selectedSubject) return;
    const raspunsuri: RaspunsuriIntrebari = {};

    const responsesForCurrentAnswers: {
      [questionIri: IRI]: string;
    } = {};

    Object.keys(questions).forEach((e) => {
      responsesForCurrentAnswers[e] = questions[e].response!.toString();
      raspunsuri[e] = {
        textIntrebare: questions[e].text,
        raspuns: questions[e].response!,
      };
    });

    const payload: EvaluationResponsePayload = {
      ...(UserRoles.ROLE_ELEV === selectedRole
        ? { oraEvaluata: selectedSubject }
        : { userEvaluat: '/api/users/' + selectedStudent }),
      raspunsuri,
      configEvaluare,
    };

    await sendEvaluationResponse(payload);

    if (UserRoles.ROLE_ELEV === selectedRole) {
      setCurrentAnswers((prev) => ({
        ...prev,
        [selectedSubject]: parseRaspunsuri(payload.raspunsuri),
      }));
    } else {
      setCurrentAnswers((prev) => ({
        ...prev,
        ['/api/users/' + selectedStudent]: parseRaspunsuri(payload.raspunsuri),
      }));
    }
  };

  const resetOnClassChange = () => {
    if (!questions) return;
    resetQuestions();
    setSelectedStudent(null);
  };

  const resetQuestions = () => {
    if (!questions) return;
    const adjustedQuestions = { ...questions };
    Object.keys(adjustedQuestions).forEach((e) => {
      adjustedQuestions[e].response = null;
    });
    setQuestions(adjustedQuestions);
  };

  const setAnswerForQuestion = (answer: number, questionIri: IRI) => {
    if (!questions) return;

    setQuestions((prev) => ({
      ...prev,
      [questionIri]: {
        text: prev![questionIri].text,
        response: answer,
      },
    }));
  };

  return (
    <div style={{ maxHeight: '100%' }} className="flex flex-col w-full">
      <div className="flex h-full w-full gap-2 justify-center">
        {selectedRole !== UserRoles.ROLE_ELEV && classes && (
          <div className="flex flex-col gap-2 w-36">
            <div className="w-36 h-8 flex justify-center items-center shadow-md background-primary">
              <Typography variant="footerBold">
                {tModules('evaluation.classes')}
              </Typography>
            </div>
            <div id="classes-container" className="">
              <div className="flex w-full flex-col gap-2 overflow-y-auto w-36 max-h-80">
                {classes.map((e, index) => {
                  return (
                    <div
                      key={e.classCode + index}
                      onClick={() => {
                        resetOnClassChange();
                        if (selectedClass === e.id)
                          return setSelectedClass(null);
                        setSelectedClass(e.id);
                      }}
                      className="flex items-center justify-between shadow-md p-3 cursor-pointer background-primary"
                    >
                      <div>
                        <Typography variant="h6">{e.classCode}</Typography>
                      </div>
                      <div>
                        <img
                          width={15}
                          src={icons.themes[userContext.state.theme].rightArrow}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {selectedRole !== UserRoles.ROLE_ELEV && students && selectedClass && (
          <div className="flex flex-col gap-2 w-80">
            <div className="w-80 h-8 flex justify-center items-center shadow-md background-primary">
              <Typography variant="footerBold">
                {tModules('evaluation.students')}
              </Typography>
            </div>
            <div id="students-container" className="">
              <div className="flex w-full flex-col gap-2 overflow-y-auto w-80 max-h-80">
                {students[selectedClass.toString()] &&
                  students[selectedClass.toString()].map((e) => {
                    return (
                      <div
                        key={e.id + e.nume + e.prenume}
                        onClick={() => {
                          resetQuestions();
                          if (selectedStudent === e.id)
                            return setSelectedStudent(null);
                          setSelectedStudent(e.id);
                        }}
                        style={{
                          backgroundColor:
                            selectedStudent === e.id
                              ? theme.palette.primary.main
                              : '#f5f5f5',
                          color: selectedStudent === e.id ? 'white' : 'black',
                        }}
                        className={`flex items-center justify-between shadow-md p-3 cursor-pointer`}
                      >
                        <div>
                          <Typography variant="h6">
                            {e.nume + ' ' + e.prenume}
                          </Typography>
                        </div>
                        <div>
                          <img
                            width={15}
                            src={
                              selectedStudent === e.id
                                ? icons.general.whiteRightArrow
                                : icons.themes[userContext.state.theme]
                                    .rightArrow
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        {selectedRole === UserRoles.ROLE_ELEV && subjects && (
          <div className="flex flex-col gap-2 w-80">
            <div className="w-80 h-8 flex justify-center items-center shadow-md background-primary">
              <Typography variant="footerBold">
                {tModules('evaluation.subjects')}
              </Typography>
            </div>
            <div id="students-container" className="">
              <div className="flex w-full flex-col gap-2 overflow-y-auto w-80 max-h-80">
                {subjects.map((e) => {
                  return (
                    <div
                      key={e['@id'] + e.numeMaterie}
                      onClick={() => {
                        resetQuestions();
                        if (selectedSubject === e['@id'])
                          return setSelectedSubject('');
                        setSelectedSubject(e['@id']);
                      }}
                      style={{
                        backgroundColor:
                          selectedSubject === e['@id']
                            ? theme.palette.primary.main
                            : '#f5f5f5',
                        color: selectedSubject === e['@id'] ? 'white' : 'black',
                      }}
                      className=" flex items-center justify-between shadow-md p-3 cursor-pointer background-primary"
                    >
                      <div>
                        <Typography variant="h6">{e.numeMaterie}</Typography>
                      </div>
                      <div>
                        <img
                          width={15}
                          src={
                            selectedSubject === e['@id']
                              ? icons.general.whiteRightArrow
                              : icons.themes[userContext.state.theme].rightArrow
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {questions &&
          ((selectedRole === UserRoles.ROLE_ELEV && selectedSubject) ||
            selectedStudent) && (
            <div className="flex flex-col gap-2 flex-1">
              <div className="h-8 flex justify-center items-center shadow-md background-primary">
                <Typography variant="footerBold">
                  {tModules('evaluation.questions')}
                </Typography>
              </div>
              <div
                id="questions-container"
                className="flex-1 flex w-full flex-col gap-2 overflow-y-auto w-full max-h-96"
              >
                {Object.keys(questions).map((questionIri) => {
                  const evaluationAlreadySubmitted =
                    selectedRole === UserRoles.ROLE_ELEV
                      ? !!currentAnswers[selectedSubject]
                      : !!currentAnswers['/api/users/' + selectedStudent];

                  const response = evaluationAlreadySubmitted
                    ? selectedRole === UserRoles.ROLE_ELEV
                      ? parseInt(currentAnswers[selectedSubject][questionIri])
                      : parseInt(
                          currentAnswers['/api/users/' + selectedStudent][
                            questionIri
                          ]
                        )
                    : questions[questionIri].response;

                  return (
                    <div
                      key={questionIri}
                      className="flex gap-2 w-full flex-col justify-between shadow-lg p-3 background-primary"
                    >
                      <div className="flex justify-between">
                        <Typography variant="h6">
                          {questions[questionIri].text}
                        </Typography>
                        <div className="flex items-center h-8 gap-1">
                          {generateImages(
                            evaluationAlreadySubmitted,
                            response,
                            questionIri,
                            setAnswerForQuestion
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {!!Object.values(questions).length &&
                  ((selectedRole === UserRoles.ROLE_ELEV &&
                    !currentAnswers[selectedSubject]) ||
                    (selectedRole === UserRoles.ROLE_PROFESOR &&
                      !currentAnswers['/api/users/' + selectedStudent])) && (
                    <ButtonComp
                      onButtonClick={() => {
                        sendResponseAction();
                      }}
                      buttonText="Trimite raspuns"
                      buttonProps={{
                        variant: 'contained',
                      }}
                      tailwindContainerClasses="flex justify-end"
                    />
                  )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
