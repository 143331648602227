import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import { Homework, HomeworkPayload } from 'library/types/Homework';
import { Lesson, LessonPayload } from 'library/types/Lesson';

export function LessonApi() {
  const { apiHandler } = JWTInterceptor();

  const getLesson = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Lesson | CollectionApiResponse<Lesson>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/lectii/${id}` : `${serverUri()}/lectii/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createLesson = async (lessonInfo: LessonPayload): Promise<Lesson> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/lectii`,
      data: lessonInfo,
      axiosConfig: {
        ...apiCallConfigTypes.post,
      },
      successMessage: 'The lesson entry was successfully created!',
    });

    return response.data;
  };

  const editLesson = async (
    lessonId: number,
    lessonInfo: Partial<HomeworkPayload>
  ): Promise<Homework> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/lectii/${lessonId}`,
      data: lessonInfo,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The lesson was successfully modified!',
    });

    return response.data;
  };

  return {
    getLesson,
    createLesson,
    editLesson,
  };
}
