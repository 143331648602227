import { IFile, IRI, IriEntity } from './Common';

export interface TimeTable extends TimeTablePayload, IriEntity {
  id: number;
}
export interface TimeTablePayload {
  scoala: IRI;
  clasa: IRI;
  materie: IRI;
  module?: Array<IRI>;
  modul?: IRI;
  zi: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  intervalOrar: IRI;
}

export type CollectionTimeTable = {
  id: number;
  clasa: {
    '@id': IRI;
    id: number;
    codClasa: string;
  };
  materie: {
    '@id': IRI;
    id: number;
    tipMaterie: {
      '@id': IRI;
      anStudiu: {
        anStudiu: number;
      };
      tipMaterie: string;
    };
  };
  user: {
    nume: string;
    prenume: string;
  };
};

export interface ClassDetail {
  ['@id']: IRI;
  codClasa: string;
  id: number;
}

export interface ProfessorDetail {
  ['@id']: IRI;
  numeComplet: string;
}

export interface SubjectDetail {
  ['@id']: IRI;
  tipMaterie: string;
  tipMaterieIri?: IRI;
}

export interface TimeSlotForDaySchedule {
  intervalOrarIri: string;
  oraStart: string;
  oraSfarsit: string;
  materie?: SubjectDetail;
  profesor?: ProfessorDetail;
  orarIri?: IRI;
  orarId?: number;
  clasa?: ClassDetail;
  orarDetaliatIri?: IRI;
  orarDetaliatId: number;
  anStudiu: number;
  modulIri: IRI;
  linkZoom: string | null;
  lectie?: {
    subiect: string | null;
    fisier: IFile;
  };
}

export interface DaySchedule {
  [timeSlot: string]: TimeSlotForDaySchedule;
}

export enum Days {
  Luni = 'Luni',
  Marti = 'Marti',
  Miercuri = 'Miercuri',
  Joi = 'Joi',
  Vineri = 'Vineri',
  Sambata = 'Sambata',
  Duminica = 'Duminica',
}

export type WeeklySchedule = {
  [key in Days]: DaySchedule;
};

export type TimeslotsWithTimeTableData = {
  schoolId?: number;
  moduleId?: number;
  classId?: number;
  editedOrarId?: number;
  getOnlyAvailable?: boolean;
  getOnlyOccupied?: boolean;
};

export type SwapTimeTablesPayload = {
  orar1: IRI;
  orar2: IRI;
};

export interface CollectionDetailedTimeTable extends IriEntity {
  id: number;
  orar: {
    '@id': IRI;
    id: number;
    scoala: {
      '@id': IRI;
      numeScoala: string;
      id: number;
    };
    clasa: {
      '@id': IRI;
      id: number;
      codClasa: string;
    };
    materie: {
      '@id': IRI;
      id: number;
      tipMaterie: {
        tipMaterie: string;
        '@id': IRI;
        anStudiu: {
          '@id': IRI;
          anStudiu: number;
        };
      };
      user: {
        '@id': IRI;
        numeComplet: string;
      };
    };
    modul: {
      '@id': IRI;
      id: number;
    };
    zi: number;
    intervalOrar: {
      oraStart: string;
      oraSfarsit: string;
    };
  };
  data: string;
  linkZoom: string;
  lectie: {
    id: number;
    fisier: IFile;
    subiect: string;
  };
  fisier: null;
  interval_orar: {
    '@id': IRI;
    id: number;
    oraStart: string;
    oraSfarsit: string;
  };
}

export interface SlotDetails {
  slot: TimeSlotForDaySchedule;
  day: string;
  date?: string;
}
