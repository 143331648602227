import { TestApi } from 'api/test.api';
import ClearIcon from 'common/components/icons/ClearIcon';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { CollectionApiResponse, IRI, SelectOption } from 'library/types/Common';
import { SubjectType } from 'library/types/Subject';
import {
  Test,
  TestQuestion,
  TestQuestionToTest,
  TestQuestionToTestPayload,
} from 'library/types/Test';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ManageTestQuestionToTestModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as {
    editId: number;
  };

  const { editId: testId } = state || {};
  const {
    createTestQuestionToTest,
    getTestQuestionToTest,
    deleteTestQuestionToTest,
    getProfessorsTest,
    getTestQuestion,
  } = TestApi();

  const [testQuestionToTestData, setTestQuestionToTestData] =
    useState<TestQuestionToTestPayload>({
      test: '',
      intrebari: [],
    });

  const [initialTestQuestionsToTest, setInitialTestQuestionsToTest] = useState<
    Array<{
      '@id': string;
      intrebare: IRI;
    }>
  >([]);

  const [currentlySelectedQuestion, setCurrentlySelectedQuestion] =
    useState<IRI>('');

  const [selectedTest, setSelectedTest] = useState<Test>();

  const [testQuestions, setTestQuestions] = useState<Array<SelectOption>>([]);

  useEffect(() => {
    if (!testId) navigate('/home');

    (async () => {
      const test = (await getProfessorsTest({ id: testId })) as Test;
      setSelectedTest(test);

      const subjectIri = test?.materie['@id'];

      if (!subjectIri) return;

      const testQuestionsResponse = (await getTestQuestion({
        params: {
          materie: subjectIri,
        },
      })) as CollectionApiResponse<TestQuestion>;

      setTestQuestions(
        testQuestionsResponse['hydra:member']
          .filter((e) => e.materie['@id'] === subjectIri)
          .map((e) => {
            return {
              value: e['@id'],
              label: e.intrebare,
            };
          })
      );

      const testQuestionsToTest = (
        (await getTestQuestionToTest({
          params: {
            test: test['@id'],
          },
        })) as CollectionApiResponse<TestQuestionToTest>
      )['hydra:member'];
      setInitialTestQuestionsToTest(
        testQuestionsToTest.map((e) => ({
          '@id': e['@id'],
          intrebare: e.intrebare['@id'],
        }))
      );

      setTestQuestionToTestData({
        test: test['@id'],
        intrebari: testQuestionsToTest.map((e) => e.intrebare['@id']),
      });
    })();
  }, []);

  const submitFormAction = async () => {
    const { test, intrebari } = testQuestionToTestData;

    if (!test || !intrebari.length)
      return toast.error(tModules('test.createEditTestQuestionToTestError'));

    if (!testId) {
      const testQuestionToTestPayload: TestQuestionToTestPayload = {
        test,
        intrebari,
      };
      await createTestQuestionToTest(testQuestionToTestPayload);
    } else {
      const testQuestionsToTestToBeRemoved = initialTestQuestionsToTest.filter(
        (e) => {
          return !intrebari.includes(e.intrebare);
        }
      );
      const testQuestionsToTestToBeAdded = intrebari.filter((e) => {
        return !initialTestQuestionsToTest.some((el) => el.intrebare === e);
      });

      for (const testQuestionToTest of testQuestionsToTestToBeRemoved) {
        const testQuestionToTestId = parseInt(
          testQuestionToTest['@id'].split('/').pop()!
        );
        await deleteTestQuestionToTest(testQuestionToTestId);
      }

      const testQuestionToTestPayload: TestQuestionToTestPayload = {
        test,
        intrebari: testQuestionsToTestToBeAdded,
      };
      await createTestQuestionToTest(testQuestionToTestPayload);
    }

    navigate('/table-test-question-to-test');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              disabled: true,
              label: tModules('test.test'),
              value: selectedTest
                ? selectedTest?.titlu +
                  ' - ' +
                  (selectedTest?.materie?.tipMaterie as SubjectType)
                    .tipMaterie +
                  ' - ' +
                  selectedTest?.clasa.codClasa
                : '',
              fullWidth: true,
            }}
          />
          <RegularSelect
            options={testQuestions.filter((e) => {
              return !testQuestionToTestData.intrebari.includes(
                e.value as string
              );
            })}
            onChange={(e: string) => {
              setTestQuestionToTestData((prev) => {
                const auxObject = { ...prev };
                if (!auxObject.intrebari.includes(e))
                  auxObject.intrebari.push(e);

                return auxObject;
              });
            }}
            selectProps={{
              disabled:
                !testQuestionToTestData.test ||
                testQuestions.filter((e) => {
                  return !testQuestionToTestData.intrebari.includes(
                    e.value as string
                  );
                }).length === 0,
              label: tModules('test.testQuestion'),
              value: currentlySelectedQuestion,
              fullWidth: true,
            }}
          />
          {!!testQuestionToTestData.intrebari.length && (
            <ul
              style={{
                maxHeight: '400px',
              }}
              className="p-4 overflow-y-auto border border-gray-300 flex flex-col gap-1"
            >
              <div className="flex text-lg font-bold justify-center w-100">
                {tModules('test.selectedQuestions')}
              </div>
              {testQuestionToTestData.intrebari.map((e, index) => {
                return (
                  <li
                    className="flex justify-between items-center"
                    key={'intrebare-index-' + index}
                  >
                    <div>
                      <span className="pr-2">{index + 1}.</span>{' '}
                      {testQuestions.find((el) => el.value === e)?.label}
                    </div>
                    <ClearIcon
                      onClick={() => {
                        const configQuestionsAux = [
                          ...testQuestionToTestData.intrebari,
                        ];
                        console.log(testQuestionToTestData);

                        configQuestionsAux.splice(index, 1);
                        setTestQuestionToTestData((prev) => ({
                          ...prev,
                          intrebari: configQuestionsAux,
                        }));
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <ButtonComp
          onButtonClick={submitFormAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
