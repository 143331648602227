import { Typography } from '@mui/material';
import { MessagesApi } from 'api/messages.api';
import dayjs from 'dayjs';
import { Message, ViewMessagesType } from 'library/types/Message';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const ViewMessagesInboxModule = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();

  const viewType = ViewMessagesType.Inbox;

  useEffect(() => {
    if (![ViewMessagesType.Inbox, ViewMessagesType.Outbox].includes(viewType))
      return navigate('/home');
    getMessages();
  }, []);

  const [messages, setMessages] = useState<Message[]>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const { getAllMessages } = MessagesApi();

  const getMessages = async () => {
    const messages = (await getAllMessages(viewType))['hydra:member'];
    setMessages(messages);
  };

  const navigateToMessage = (index: number, messageId: number) => {
    navigate(`/read-message/${messageId}`);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full h-16 items-center justify-center">
        <Typography variant="h3"></Typography>
      </div>
      <div
        style={{
          maxHeight: '600px',
        }}
        className="flex w-full shadow-lg rounded-3xl flex-col rounded-l-3xl bg-gray-50 overflow-y-auto"
      >
        <ul>
          {messages.map((e, index) => (
            <li
              onMouseDown={() => setSelectedId(index)}
              onMouseUp={() => navigateToMessage(index, e.id)}
              onKeyDown={() => navigateToMessage(index, e.id)}
              className={`select-none py-5 border-b cursor-pointer px-3 transition ${
                index === selectedId
                  ? 'bg-indigo-600 text-white'
                  : 'hover:bg-indigo-100'
              }`}
            >
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">
                  {e.emitator.nume + ' ' + e.emitator.prenume}
                </h3>
                <p className="text-md text-gray-400">
                  {dayjs(e.etichetaTimp).format('DD.MM.YYYY HH:mm')}
                </p>
              </div>
              <div className="text-md italic text-gray-400">{e.subiect}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
