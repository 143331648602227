import { Typography } from '@mui/material';
import { FisierApi } from 'api/fisier.api';
import { HomeworkApi } from 'api/homework.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { DatePickerInput } from 'common/components/inputs/DatePickerInput';
import FileUploadComponent from 'common/components/inputs/FileInput';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { UserContext } from 'context/UserContext';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { IFile, IRI } from 'library/types/Common';
import { Homework, StudentHomeworkPayload } from 'library/types/Homework';
import { DownloadFile } from 'modules/SchoolAdmin/Tables/CellComponents/DownloadFile';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

dayjs.extend(isToday);

export const SubmitStudentHomeworkModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const state = location.state as {
    homeworkId: number;
  };
  const { homeworkId } = state || {};

  const { getHomework, submitStudentHomework } = HomeworkApi();
  const { uploadFile } = FisierApi();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [selectedSubject, setSelectedSubject] = useState<{
    '@id': IRI;
    numeMaterie: string;
  }>({
    '@id': '',
    numeMaterie: '',
  });

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [homeworkFile, setHomeworkFile] = useState<IFile | null>(null);

  useEffect(() => {
    if (!homeworkId) return navigate('/table-homework');
    (async () => {
      const homework = (await getHomework({
        id: homeworkId,
      })) as Homework;

      setSelectedSubject({
        '@id': homework.materie['@id'],
        numeMaterie: homework.materie.tipMaterie.tipMaterie,
      });

      setTitle(homework.titlu);
      setDescription(homework.descriere);

      setStartDate(dayjs(homework.startsAt));
      setEndDate(dayjs(homework.endsAt));

      setHomeworkFile(homework.fisier);
    })();
  }, []);

  const submitFormAction = async () => {
    if (!selectedFile)
      return toast.error(tModules('homework.submittedHomeworkFileError'));

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      try {
        var uploadedFile = await uploadFile(formData);
      } catch (e) {
        console.error(e);
        toast.error(tModules('homework.fileUploadError'));
        return;
      }
    }

    const studentHomeworkPayload: StudentHomeworkPayload = {
      fisier: `/api/fisiere/${uploadedFile.id}`,
      tema: `/api/teme/${homeworkId}`,
      user: '/api/users/' + userContext.state.account!.user.id,
    };

    await submitStudentHomework(studentHomeworkPayload);
    navigate('/table-student-homework/solved');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('homework.titleInput'),
              value: title,
              disabled: true,
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              rows: 7,
              multiline: true,
              label: tModules('homework.descriptionInput'),
              value: description,
              disabled: true,
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              rows: 7,
              multiline: true,
              label: tModules('homework.subjectInput'),
              value: selectedSubject.numeMaterie,
              disabled: true,
            }}
          />
          <DatePickerInput
            datePickerProps={{
              minDate: dayjs().startOf('day'),
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('homework.activePeriodStartDate'),
              value: startDate,
              disabled: true,
            }}
          />
          <DatePickerInput
            datePickerProps={{
              minDate: dayjs().startOf('day'),
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('homework.activePeriodEndDate'),
              value: endDate,
              disabled: true,
            }}
          />
          {homeworkFile && (
            <div className="flex items-center py-3 gap-2">
              <Typography fontSize={18} variant="bodyBold">
                {tModules('homework.homeworkRequirements')}
              </Typography>
              <DownloadFile fileUrl={homeworkFile.contentUrl} />
            </div>
          )}

          <FileUploadComponent
            buttonText="Incarca fisier raspuns tema"
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            containerClasses="py-3"
          />
        </div>
        <ButtonComp
          onButtonClick={submitFormAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Salveaza',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
