import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import { StatusUserClasa } from 'library/types/Class';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import { User } from 'library/types/User';
import { UserClass, UserClassPayload } from 'library/types/UserClass';

export function UserClassApi() {
  const { apiHandler } = JWTInterceptor();

  const createUserClass = async (
    userClassInfo: UserClassPayload
  ): Promise<UserClass> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/userClasa`,
      data: userClassInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'User was successfully allocated to a class!',
    });

    return response.data;
  };

  const getUnallocatedStudents = async (): Promise<Array<User>> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/elevi-neasociati`,
      axiosConfig: apiCallConfigTypes.get,
    });

    return response.data;
  };

  const editUserClass = async (
    id: number,
    data: Partial<UserClassPayload>
  ): Promise<UserClass> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/userClasa/${id}`,
      data: data,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The user - class pairing was successfully modified!',
    });

    return response.data;
  };

  const getUserClass = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<UserClass | CollectionApiResponse<UserClass>> => {
    if (config) var { id, params } = config;
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/userClasa/${id}` : `${serverUri()}/userClasa/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getStatusUserClasa = async (): Promise<
    CollectionApiResponse<StatusUserClasa>
  > => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/status-user-clasa`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
      },
    });

    return response.data;
  };

  return {
    createUserClass,
    getUnallocatedStudents,
    editUserClass,
    getUserClass,
    getStatusUserClasa,
  };
}
