import { useTranslation } from 'react-i18next';

export const StudentTeacher = (props: { isStudent: boolean }) => {
  const { t: tModules } = useTranslation('modules');
  const { isStudent } = props;

  return (
    <div>
      {tModules(
        `schoolAdmin.tables.genericTable.${isStudent ? 'student' : 'professor'}`
      )}
    </div>
  );
};
