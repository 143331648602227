import { Login } from 'modules/Auth/Login';
import { EvaluationConfigModule } from 'modules/Evaluations/EvaluationConfigModule';
import { EvaluationResponseModule } from 'modules/Evaluations/EvaluationResponseModule';
import { YearlyEvaluationResponseModule } from 'modules/Evaluations/YearlyEvaluationResponseModule';
import { HomeModule } from 'modules/Home/Home';
import { ReadMessageModule } from 'modules/Messages/ReadMessage';
import { SendMessageModule } from 'modules/Messages/SendMessage';
import { ViewMessagesInboxModule } from 'modules/Messages/ViewMessagesInbox';
import { ViewMessagesOutboxModule } from 'modules/Messages/ViewMessagesOutbox';
import { Profile } from 'modules/Profile/Profile';
import { CreateCatalogEntryModule } from 'modules/Catalog/CreateCatalogEntryModule';
import { IndividualClassModule } from 'modules/SchoolAdmin/Class/IndividualClass';
import { ClassProfileModule } from 'modules/SchoolAdmin/ClassProfile/ClassProfile';
import { ClassSpecializationModule } from 'modules/SchoolAdmin/ClassSpecialization/ClassSpecialization';
import { EvaluationQuestionModule } from 'modules/SchoolAdmin/Evaluations/EvaluationQuestionModule';
import { YearlyEvaluationQuestionModule } from 'modules/SchoolAdmin/Evaluations/YearlyEvaluationQuestionModule';
import { SchoolYearModule } from 'modules/SchoolAdmin/SchoolYear/SchoolYear';
import { IndividualSchoolModule } from 'modules/SchoolAdmin/Schools/IndividualSchool';
import { SubjectConfigModule } from 'modules/SchoolAdmin/Subject/SubjectConfig';
import { SubjectTypeModule } from 'modules/SchoolAdmin/Subject/SubjectType';
import GenericTableWithReactQueryProvider from 'modules/SchoolAdmin/Tables/GenericTable';
import { TablesConfig } from 'modules/SchoolAdmin/Tables/TablesConfig';
import { TermAkaModulModule } from 'modules/SchoolAdmin/TermAkaModul/TermAkaModul';
import { TimeSlotModule } from 'modules/SchoolAdmin/TimeSlot/TimeSlot';
import { ManageTimeTableModule } from 'modules/SchoolAdmin/TimeTable/ManageTimeTableModule';
import { SwapTimeTablesModule } from 'modules/SchoolAdmin/TimeTable/SwapTimeTablesModule';
import { ViewDetailedTimeTableModule } from 'modules/SchoolAdmin/TimeTable/ViewDetailedTimeTableModule';
import { ViewTimeTableModule } from 'modules/SchoolAdmin/TimeTable/ViewTimeTableModule';
import { UserModule } from 'modules/SchoolAdmin/User/User';
import { UserClassModule } from 'modules/SchoolAdmin/UserClass/UserClass';
import { CreateHomeworkModule } from 'modules/Homework/ManageHomeworkModule';
import { UserRoles } from 'library/types/User';
import { SubmitStudentHomeworkModule } from 'modules/Homework/SubmitStudentHomeworkModule';
import { SubmitCheckedStudentHomeworkModule } from 'modules/Homework/SubmitCheckedStudentHomeworkModule';
import { Title } from 'library/types/Common';
import { ManageLessonModule } from 'modules/Lesson/ManageLessonModule';
import { LessonDetailedTimetable } from 'modules/Lesson/LessonDetailedTimetable';
import { ManageTestQuestionModule } from 'modules/Tests/ManageTestQuestionModule';
import { ManageTestModule } from 'modules/Tests/ManageTestModule';
import { ManageTestQuestionToTestModule } from 'modules/Tests/ManageTestQuestionToTestModule';
import { AnswerTestModule } from 'modules/Tests/AnswerTestModule';
import { CatedraModule } from 'modules/SchoolAdmin/Catedra/CatedraModule';
import { ManageAverageModule } from 'modules/Average/ManageAverageModule';
import { ManageCorigentaModule } from 'modules/Average/ManageCorigentaModule';
import { ManageCloseSituationModule } from 'modules/Average/ManageCloseSituationModule';
import { ManageAcademicRecords } from 'modules/AcademicRecords/ManageAcademicRecords';
import { PrintAcademicRecord } from 'modules/AcademicRecords/PrintAcademicRecord';
import { ManageCatalogReport } from 'modules/Reports/CatalogReport';
import { PrintCatalogReport } from 'modules/Reports/PrintCatalogReport';

export const privateRoutes: Array<{
  path: string;
  element: JSX.Element;
  title?: Title;
  allowedRoles?: UserRoles[];
  key?: string;
  noPageContainer?: boolean;
}> = [
  {
    path: '/home',
    element: <HomeModule />,
  },

  {
    path: '*',
    element: <Login />,
    title: { default: 'Login' },
  },

  {
    path: '/profile',
    element: <Profile />,
    title: { default: 'burgerMenu.profileMenu' },
  },

  {
    path: '/user/:pageViewType/:_id?',
    element: <UserModule />,
    title: {
      default: 'Gestioneaza User',
      edit: 'burgerMenu.adminSubmenus.userEdit',
      create: 'burgerMenu.adminSubmenus.userCreate',
      view: 'burgerMenu.adminSubmenus.userView',
    },
    allowedRoles: [
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
      UserRoles.ROLE_SECRETAR,
    ],
  },

  {
    path: '/individual-school/:pageViewType',
    element: <IndividualSchoolModule />,
    title: { default: 'Scoala', edit: 'burgerMenu.adminSubmenus.schoolEdit' },
    allowedRoles: [
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
      UserRoles.ROLE_SECRETAR,
    ],
  },

  {
    path: '/school-year',
    element: <SchoolYearModule />,
    title: { default: 'burgerMenu.adminSubmenus.schoolYearCreate' },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/individual-class/:pageViewType/:_id?',
    element: <IndividualClassModule />,
    title: {
      default: 'Gestioneaza clasa',
      create: 'burgerMenu.adminSubmenus.classCreate',
      edit: 'burgerMenu.adminSubmenus.classEdit',
    },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/class-profile',
    element: <ClassProfileModule />,
    title: { default: 'burgerMenu.adminSubmenus.classProfileCreate' },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/class-specialization',
    element: <ClassSpecializationModule />,
    title: { default: 'burgerMenu.adminSubmenus.specializationCreate' },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/term',
    element: <TermAkaModulModule />,
    title: { default: 'burgerMenu.adminSubmenus.moduleCreate' },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/subject-type',
    element: <SubjectTypeModule />,
    title: { default: 'burgerMenu.adminSubmenus.subjectTypeCreate' },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/subject-config',
    element: <SubjectConfigModule />,
    title: { default: 'burgerMenu.adminSubmenus.subjectProfessor' },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/time-slot',
    element: <TimeSlotModule />,
    title: { default: 'burgerMenu.adminSubmenus.timeSlotCreate' },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/user-class',
    element: <UserClassModule />,
    title: { default: 'burgerMenu.adminSubmenus.classStudents' },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: TablesConfig().annualEvaluationQuestion.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().annualEvaluationQuestion.path}
        {...TablesConfig().annualEvaluationQuestion}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().evaluationConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().evaluationConfig.path}
        {...TablesConfig().evaluationConfig}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().evaluationQuestion.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().evaluationQuestion.path}
        {...TablesConfig().evaluationQuestion}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().class.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().class.path}
        {...TablesConfig().class}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_DIRIGINTE,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_DIRECTOR,
    ],
  },
  {
    path: TablesConfig().classProfile.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().classProfile.path}
        {...TablesConfig().classProfile}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  // {
  //   path: TablesConfig().school.path,
  //   element: (
  //     <GenericTableWithReactQueryProvider
  //       key={TablesConfig().school.path}
  //       {...TablesConfig().school}
  //     />
  //   ),
  // },
  {
    path: TablesConfig().schoolYear.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().schoolYear.path}
        {...TablesConfig().schoolYear}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().studyYear.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().studyYear.path}
        {...TablesConfig().studyYear}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().subjectConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().subjectConfig.path}
        {...TablesConfig().subjectConfig}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().subjectType.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().subjectType.path}
        {...TablesConfig().subjectType}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().termAkaModul.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().termAkaModul.path}
        {...TablesConfig().termAkaModul}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().timeSlot.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().timeSlot.path}
        {...TablesConfig().timeSlot}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().user.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().user.path}
        {...TablesConfig().user}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().evaluationForProfessorResponseConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().evaluationForProfessorResponseConfig.path}
        {...TablesConfig().evaluationForProfessorResponseConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_INVATATOR,
    ],
  },
  {
    path: TablesConfig().evaluationForStudentResponseConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().evaluationForStudentResponseConfig.path}
        {...TablesConfig().evaluationForStudentResponseConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PARINTE,
    ],
  },
  {
    path: TablesConfig().yearlyEvaluationResponseConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().yearlyEvaluationResponseConfig.path}
        {...TablesConfig().yearlyEvaluationResponseConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PARINTE,
    ],
  },
  {
    path: TablesConfig().catalogConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().catalogConfig.path}
        {...TablesConfig().catalogConfig}
      />
    ),
  },
  {
    path: TablesConfig().homeworkConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().homeworkConfig.path}
        {...TablesConfig().homeworkConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_PARINTE,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },
  {
    path: TablesConfig().studentHomeworkConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().studentHomeworkConfig.path}
        {...TablesConfig().studentHomeworkConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_PARINTE,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },
  {
    path: TablesConfig().solvedStudentHomeworkConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().solvedStudentHomeworkConfig.path}
        {...TablesConfig().solvedStudentHomeworkConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_PARINTE,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },
  {
    path: TablesConfig().reviewedStudentHomeworkConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().reviewedStudentHomeworkConfig.path}
        {...TablesConfig().reviewedStudentHomeworkConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_PARINTE,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },
  {
    path: TablesConfig().lessonConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().lessonConfig.path}
        {...TablesConfig().lessonConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_PARINTE,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },
  {
    path: TablesConfig().testProfessorsConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().testProfessorsConfig.path}
        {...TablesConfig().testProfessorsConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },
  {
    path: TablesConfig().testStudentsConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().testStudentsConfig.path}
        {...TablesConfig().testStudentsConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },
  {
    path: TablesConfig().testQuestionConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().testQuestionConfig.path}
        {...TablesConfig().testQuestionConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },
  {
    path: TablesConfig().testQuestionToTestConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().testQuestionToTestConfig.path}
        {...TablesConfig().testQuestionToTestConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },
  {
    path: TablesConfig().testQuestionAnswerConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().testQuestionAnswerConfig.path}
        {...TablesConfig().testQuestionAnswerConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },
  {
    path: TablesConfig().userClassConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={Date.now()}
        {...TablesConfig().userClassConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_DIRIGINTE,
      UserRoles.ROLE_DIRECTOR,
    ],
  },
  {
    path: TablesConfig().classSpecializationConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().classSpecializationConfig.path}
        {...TablesConfig().classSpecializationConfig}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  {
    path: TablesConfig().catedraConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().catedraConfig.path}
        {...TablesConfig().catedraConfig}
      />
    ),
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },
  // {
  //   path: TablesConfig().primaryAverageConfig.path,
  //   element: (
  //     <GenericTableWithReactQueryProvider
  //       key={TablesConfig().primaryAverageConfig.path}
  //       {...TablesConfig().primaryAverageConfig}
  //     />
  //   ),
  //   allowedRoles: [
  //     UserRoles.ROLE_SCHOOL_ADMIN,
  //     UserRoles.ROLE_INVATATOR,
  //     UserRoles.ROLE_SECRETAR,
  //   ],
  // },
  // {
  //   path: TablesConfig().nonPrimaryAverageConfig.path,
  //   element: (
  //     <GenericTableWithReactQueryProvider
  //       key={TablesConfig().nonPrimaryAverageConfig.path}
  //       {...TablesConfig().nonPrimaryAverageConfig}
  //     />
  //   ),
  //   allowedRoles: [
  //     UserRoles.ROLE_SCHOOL_ADMIN,
  //     UserRoles.ROLE_PROFESOR,
  //     UserRoles.ROLE_SECRETAR,
  //   ],
  // },
  {
    path: TablesConfig().averageConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().averageConfig.path}
        {...TablesConfig().averageConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PARINTE,
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_DIRIGINTE,
    ],
  },
  {
    path: TablesConfig().closedSituationTableConfig.path,
    element: (
      <GenericTableWithReactQueryProvider
        key={TablesConfig().closedSituationTableConfig.path}
        {...TablesConfig().closedSituationTableConfig}
      />
    ),
    allowedRoles: [
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PARINTE,
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_DIRIGINTE,
    ],
  },
  {
    path: '/messages/inbox',
    element: <ViewMessagesInboxModule />,
    title: { default: 'burgerMenu.messagesSubmenus.inbox' },
  },

  {
    path: '/messages/outbox',
    element: <ViewMessagesOutboxModule />,
    title: { default: 'burgerMenu.messagesSubmenus.outbox' },
  },

  {
    path: '/read-message/:id',
    element: <ReadMessageModule />,
    title: {
      default: 'otherRoutesTitles.readMessage',
    },
  },

  {
    path: '/send-message/:sendMessageType',
    element: <SendMessageModule />,
    title: {
      default: 'otherRoutesTitles.sendMessage',
    },
  },

  {
    path: '/evaluation-question',
    element: <EvaluationQuestionModule />,
    title: {
      default: 'otherRoutesTitles.evaluationModuleQuestionsCreate',
    },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/evaluation-question-config',
    element: <EvaluationConfigModule />,
    title: {
      default: 'otherRoutesTitles.evaluationModuleQuestionConfigCreate',
    },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/evaluation',
    element: <EvaluationResponseModule />,
    title: {
      default: 'otherRoutesTitles.evaluationModuleFillEvaluation',
    },
    allowedRoles: [
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_INVATATOR,
    ],
  },

  {
    path: '/yearly-evaluation-question',
    element: <YearlyEvaluationQuestionModule />,
    title: {
      default: 'otherRoutesTitles.evaluationAnnualQuestionsCreate',
    },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN],
  },

  {
    path: '/yearly-evaluation',
    element: <YearlyEvaluationResponseModule />,
    title: {
      default: 'otherRoutesTitles.evaluationAnnualFillEvaluation',
    },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },

  {
    path: '/view-timetable',
    element: <ViewTimeTableModule />,
    title: { default: 'burgerMenu.timetableMenu' },
    allowedRoles: [
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_DIRECTOR,
    ],
  },

  {
    path: '/view-detailed-timetable',
    element: <ViewDetailedTimeTableModule />,
    title: { default: 'burgerMenu.detailedTimetableMenu' },
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_PARINTE,
      UserRoles.ROLE_ELEV,
      UserRoles.ROLE_DIRIGINTE,
    ],
  },

  {
    path: '/manage-timetable',
    element: <ManageTimeTableModule />,
    title: { default: 'otherRoutesTitles.manageTimetable' },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN, UserRoles.ROLE_SECRETAR],
  },

  {
    path: '/swap-timetable',
    element: <SwapTimeTablesModule />,
    title: { default: 'otherRoutesTitles.swapTimetable' },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN, UserRoles.ROLE_SECRETAR],
  },

  {
    path: '/catalogue/absence',
    element: <CreateCatalogEntryModule />,
    title: { default: 'otherRoutesTitles.catalogueAbsence' },
  },
  {
    path: '/catalogue/grade',
    element: <CreateCatalogEntryModule />,
    title: { default: 'otherRoutesTitles.catalogueGrade' },
  },
  {
    path: '/catalogue/calificativ',
    element: <CreateCatalogEntryModule />,
    title: { default: 'otherRoutesTitles.catalogueCalificativ' },
  },

  {
    path: '/homework',
    element: <CreateHomeworkModule />,
    title: { default: 'burgerMenu.homeworkSubmenus.homeworkCreate' },
    allowedRoles: [
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_PROFESOR,
      UserRoles.ROLE_SCHOOL_ADMIN,
    ],
  },

  {
    path: '/answer-homework',
    element: <SubmitStudentHomeworkModule />,
    title: { default: 'otherRoutesTitles.submitHomework' },
    allowedRoles: [UserRoles.ROLE_ELEV],
  },

  {
    path: '/check-student-homework',
    element: <SubmitCheckedStudentHomeworkModule />,
    title: { default: 'otherRoutesTitles.reviewHomework' },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/lesson/create',
    element: <ManageLessonModule />,
    title: {
      default: 'burgerMenu.lessonsSubmenus.lessonCreate',
    },
  },
  {
    path: '/lesson/edit',
    element: <ManageLessonModule />,
    title: {
      default: 'otherRoutesTitles.lessonEdit',
    },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/lesson/detailed-timetable',
    element: <LessonDetailedTimetable />,
    title: {
      default: 'otherRoutesTitles.lessonDetailedTimeTable',
    },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/test-question/create',
    element: <ManageTestQuestionModule />,
    title: {
      default: 'burgerMenu.testsSubmenus.testQuestionCreate',
    },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/test-question/edit',
    element: <ManageTestQuestionModule />,
    title: {
      default: 'burgerMenu.testsSubmenus.testQuestionEdit',
    },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/test/create',
    element: <ManageTestModule />,
    title: {
      default: 'burgerMenu.testsSubmenus.testCreate',
    },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/test/edit',
    element: <ManageTestModule />,
    title: {
      default: 'burgerMenu.testsSubmenus.testEdit',
    },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/test-question-to-test',
    element: <ManageTestQuestionToTestModule />,
    title: {
      default: 'burgerMenu.testsSubmenus.testQuestionToTestEdit',
    },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/answer-test',
    element: <AnswerTestModule />,
    title: {
      default: 'otherRoutesTitles.answerTest',
    },
    allowedRoles: [UserRoles.ROLE_ELEV],
  },
  {
    path: '/catedra',
    element: <CatedraModule />,
    title: {
      default: 'burgerMenu.adminSubmenus.catedraCreate',
    },
    allowedRoles: [UserRoles.ROLE_SCHOOL_ADMIN, UserRoles.ROLE_SECRETAR],
  },
  {
    path: '/average',
    element: <ManageAverageModule />,
    title: {
      default: 'burgerMenu.averageSubmenus.closeAverage',
    },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/corigenta',
    element: <ManageCorigentaModule />,
    title: {
      default: 'otherRoutesTitles.manageCorigenta',
    },
    allowedRoles: [UserRoles.ROLE_PROFESOR, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/close-situation',
    element: <ManageCloseSituationModule />,
    title: {
      default: 'burgerMenu.closingSituationSubmenus.closeSituation',
    },
    allowedRoles: [UserRoles.ROLE_DIRIGINTE, UserRoles.ROLE_INVATATOR],
  },
  {
    path: '/academic-records',
    element: <ManageAcademicRecords />,
    title: {
      default: 'burgerMenu.academicRecords',
    },
    allowedRoles: [
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
    ],
  },
  {
    path: '/academic-records/print',
    element: <PrintAcademicRecord />,
    title: {
      default: 'burgerMenu.academicRecords',
    },
    noPageContainer: true,
    allowedRoles: [
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
    ],
  },
  {
    path: '/catalog-report',
    element: <ManageCatalogReport />,
    title: {
      default: 'burgerMenu.reportsSubmenus.catalogReport',
    },
    allowedRoles: [
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_DIRIGINTE,
    ],
  },
  {
    path: '/catalog-report/print',
    element: <PrintCatalogReport />,
    title: {
      default: 'burgerMenu.reportsSubmenus.catalogReport',
    },
    noPageContainer: true,
    allowedRoles: [
      UserRoles.ROLE_SECRETAR,
      UserRoles.ROLE_SCHOOL_ADMIN,
      UserRoles.ROLE_DIRECTOR,
      UserRoles.ROLE_INVATATOR,
      UserRoles.ROLE_DIRIGINTE,
    ],
  },
];
