import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import { Disciplina } from 'library/types/Disciplina';

export function DisciplineApi() {
  const { apiHandler } = JWTInterceptor();

  const getDiscipline = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Disciplina | CollectionApiResponse<Disciplina>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/discipline/${id}`
        : `${serverUri()}/discipline/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  return {
    getDiscipline,
  };
}
