import { Dayjs } from 'dayjs';
import { IRI } from './Common';
import { SchoolYear } from './SchoolYear';
import { SubjectType } from './Subject';
import { User } from './User';

export interface GradesAndAbsences {
  note: Array<GradeOrMark>;
  absente: Array<Absence>;
  isInchisa: boolean;
}

export interface MarksAndAbsences {
  calificative: Array<GradeOrMark>;
  absente: Array<Absence>;
  isInchisa: boolean;
}

export interface GradeOrMark {
  nota: string;
  data: Dayjs;
  cadru: string;
}
export interface Absence {
  data: Dayjs;
  cadru: string;
}

export interface PrimaryGradeOccurances {
  Bine: number;
  'Foarte bine': number;
  Insuficient: number;
  Suficient: number;
  Excelent: number;
}

export interface Average {
  anScolar: SchoolYear;
  subjectType: SubjectType;
  user: User;
  codClasa: string;
  incheiata: boolean;
  corigent: boolean;
  absentaCorigenta: boolean;
  updateddBy: User;
  createdAt: Dayjs;
}

export interface PrimaryAverage extends Average {
  medieCalificativ: string;
  calificativCorigenta: string;
  calificativFinal: string;
}

export interface NonPrimaryAverage extends Average {
  medieMaterie: string;
  notaCorigenta: string;
  medieFinala: string;
}

export interface FullAverage
  extends PrimaryAverage,
    NonPrimaryAverage,
    Average {}

export interface EleviCorigenta {
  ['@medie']: IRI;
  numeElev: string;
  medieMaterie: string;
  medieIncheiata: boolean;
}

export interface GradeDetails {
  medieId: number;
  '@id': IRI;
  materie: string;
  cadru: string;
  medieMaterie: number;
  calificativMaterie: null | string;
  medieIncheiata: boolean;
  corigenta: boolean;
  medieFinalaMaterie: number;
  calificativFinalMaterie: null | string;
  notaCorigenta: null | number;
  calificativCorigenta: null | string;
  absentaCorigenta: null | boolean;
  dataMedie: string;
  actiuniMedie: null | {
    [key: string]: string;
  };
  isAmanata: boolean;
}

export interface ActionSituation {
  actiuniSituatie: null | {
    [key: string]: string;
  };
}

export enum StudentSituationEnum {
  Promovat = 'Promovat',
  Amanat = 'Amanat',
  Repetent = 'Repetent',
}

export type ClosingSituationRecord = (GradeDetails | ActionSituation)[];

export interface ClosedSituationTableEntry {
  '@id': string;
  id: number;
  medieAn: number | null;
  calificativAn: number | null;
  user: User;
  anScolar: {
    anInceput: number;
  };
  anStudiu: {
    anStudiu: number;
  };
  situatie: {
    label: StudentSituationEnum;
  };
  comentarii: string;
  deletedAt: null;
  createdBy: User;
  updatedBy: User;
  created: Dayjs;
  updated: Dayjs;
}

export enum Calificative {
  Bine = 'Bine',
  FoarteBine = 'Foarte bine',
  Insuficient = 'Insuficient',
  Suficient = 'Suficient',
  Excelent = 'Excelent',
}

export interface Motiv {
  '@id': IRI;
  '@type': string;
  createdAt: string;
  createdBy: null;
  id: number;
  label: string;
  modifiedAt: string;
  modifiedBy: null;
}

export enum ManageCloseSituationActionTypes {
  SituatiePromovat = 'promovat',
  SituatiePromovatPrimar = 'promovat-primar',
  SituatieRepetenta = 'repetenta',
  SituatieSituatieAmanata = 'situatie-amanata',

  SetCorigentaMedie = 'setCorigenta',
  SetAmanataMedie = 'setAmanata',
}
