import { EvaluationApi } from 'api/evaluation.api';
import { SchoolYearApi } from 'api/schoolYear.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import { isObjectValid } from 'library/helpers/helper';
import { CollectionApiResponse } from 'library/types/Common';
import { YearlyEvaluationQuestionPayload } from 'library/types/Evaluation';
import { SchoolYear } from 'library/types/SchoolYear';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const YearlyEvaluationQuestionModule = (): JSX.Element => {
  const navigate = useNavigate();
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const userContext = useContext(UserContext);

  const { createYearlyEvaluationQuestion } = EvaluationApi();
  const { getSchoolYear } = SchoolYearApi();

  const [evaluationQuestionData, setEvaluationQuestionData] = useState<{
    intrebare: string;
    anScolar: string;
  }>({
    intrebare: '',
    anScolar: '',
  });

  const [schoolYears, setSchoolYears] = useState<SchoolYear[]>([]);

  useEffect(() => {
    getSchoolYearsAction();
  }, []);

  const getSchoolYearsAction = async () => {
    const schoolYears = (await getSchoolYear({
      params: {
        activ: 'true',
      },
    })) as CollectionApiResponse<SchoolYear>;

    setSchoolYears(schoolYears['hydra:member']);
  };

  const isActionValid = () => {
    if (!isObjectValid(evaluationQuestionData)) {
      toast.error(
        tModules(
          'schoolAdmin.evaluation.submitYearlyEvaluationQuestionValidationError'
        )
      );
      return false;
    }

    if (!userContext.state.account?.user.scoala.id) return false;

    return true;
  };

  const createYearlyEvaluationQuestionAction = async () => {
    if (!isActionValid()) return;

    const payloadObject: YearlyEvaluationQuestionPayload = {
      intrebare: evaluationQuestionData.intrebare,
      anScolar: evaluationQuestionData.anScolar,
    };

    await createYearlyEvaluationQuestion(payloadObject);
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules(
                'schoolAdmin.evaluation.yearlyEvaluationQuestion'
              ),
              value: evaluationQuestionData.intrebare,
              onChange: (e) => {
                setEvaluationQuestionData((ev) => ({
                  ...ev,
                  intrebare: e.target.value,
                }));
              },
            }}
          />
          <RegularSelect
            options={schoolYears.map((e) => ({
              label: e.anInceput.toString(),
              value: e['@id'],
            }))}
            onChange={(e) => {
              setEvaluationQuestionData((prevValue) => {
                return {
                  ...prevValue,
                  anScolar: schoolYears.find((el) => el['@id'] === e)!['@id'],
                };
              });
            }}
            selectProps={{
              label: tModules('schoolAdmin.evaluation.schoolYear'),
              value: evaluationQuestionData.anScolar,
              fullWidth: true,
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={createYearlyEvaluationQuestionAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
            defaultValue: 'Salveaza',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
