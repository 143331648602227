import { Typography } from '@mui/material';
import { FisierApi } from 'api/fisier.api';
import { HomeworkApi } from 'api/homework.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { DatePickerInput } from 'common/components/inputs/DatePickerInput';
import FileUploadComponent from 'common/components/inputs/FileInput';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { UserContext } from 'context/UserContext';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { IRI } from 'library/types/Common';
import {
  CheckedStudentHomeworkPayload,
  StudentHomework,
} from 'library/types/Homework';
import { DownloadFile } from 'modules/SchoolAdmin/Tables/CellComponents/DownloadFile';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

dayjs.extend(isToday);

export const SubmitCheckedStudentHomeworkModule = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const state = location.state as {
    studentHomework: StudentHomework;
  };
  const { studentHomework } = state || {};

  const homework = studentHomework?.tema;

  const { submitCheckedStudentHomework } = HomeworkApi();
  const { uploadFile } = FisierApi();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [selectedSubject, setSelectedSubject] = useState<{
    '@id': IRI;
    numeMaterie: string;
  }>({
    '@id': '',
    numeMaterie: '',
  });

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [homeworkCheckComment, setHomeworkCheckComment] = useState('');

  useEffect(() => {
    if (!homework) return navigate('/table-student-homework');

    setSelectedSubject({
      '@id': '',
      numeMaterie: homework.materie.tipMaterie.tipMaterie,
    });

    setTitle(homework.titlu);
    setDescription(homework.descriere);

    setStartDate(dayjs(homework.startsAt));
    setEndDate(dayjs(homework.endsAt));
  }, []);

  const submitFormAction = async () => {
    if (!selectedFile)
      return toast.error(tModules('homework.reviewedHomeworkFileError'));

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      try {
        var uploadedFile = await uploadFile(formData);
      } catch (e) {
        console.error(e);
        return toast.error(tModules('homework.fileUploadError'));
      }
    }

    const checkedStudentHomeworkPayload: CheckedStudentHomeworkPayload = {
      fisierTemaCorectata: `/api/fisiere/${uploadedFile.id}`,
      tema: `/api/teme/${homework.id}`,
      user: studentHomework.user['@id'],
      comentariu: homeworkCheckComment,
    };

    await submitCheckedStudentHomework(checkedStudentHomeworkPayload);
    navigate('/table-student-homework/reviewed');
  };

  if (!homework) return <></>;

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: 'Titlu',
              value: title,
              disabled: true,
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              rows: 7,
              multiline: true,
              label: tModules('homework.description'),
              value: description,
              disabled: true,
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              rows: 7,
              multiline: true,
              label: tModules('homework.subject'),
              value: selectedSubject.numeMaterie,
              disabled: true,
            }}
          />
          <DatePickerInput
            datePickerProps={{
              minDate: dayjs().startOf('day'),
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('homework.activePeriodStartDate'),
              value: startDate,
              disabled: true,
            }}
          />
          <DatePickerInput
            datePickerProps={{
              minDate: dayjs().startOf('day'),
              slotProps: {
                textField: { fullWidth: true },
              },
              label: tModules('homework.activePeriodEndDate'),
              value: endDate,
              disabled: true,
            }}
          />
          {homework && homework.fisier && (
            <div className="flex items-center py-3 gap-2">
              <Typography fontSize={18} variant="bodyBold">
                {tModules('homework.homeworkRequirements')}
              </Typography>
              <DownloadFile fileUrl={homework.fisier.contentUrl} />
            </div>
          )}

          {studentHomework && (
            <div className="flex items-center py-3 gap-2">
              <Typography fontSize={18} variant="bodyBold">
                {tModules('homework.submittedHomework')}
              </Typography>
              <DownloadFile fileUrl={studentHomework.fisier.contentUrl} />
            </div>
          )}

          <RegularInput
            isOptional={true}
            textFieldProps={{
              fullWidth: true,
              rows: 7,
              multiline: true,
              label: tModules('homework.optionalNote'),
              value: homeworkCheckComment,
              onChange: (e) => {
                setHomeworkCheckComment(e.target.value);
              },
            }}
          />

          <FileUploadComponent
            buttonText={tModules('homework.uploadReviewedHomework')}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            containerClasses="py-3"
          />
        </div>
        <ButtonComp
          onButtonClick={submitFormAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
