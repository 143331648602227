import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

import { Dayjs } from 'dayjs';

import { roRO } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface DatePickerInputProps {
  datePickerProps?: Partial<DatePickerProps<Dayjs>>;
  tailwindContainerClasses?: string;
  isOptional?: boolean;
}

export const DatePickerInput = (props: DatePickerInputProps): JSX.Element => {
  const { datePickerProps, tailwindContainerClasses, isOptional } = props;
  return (
    <div className={`py-3 ${tailwindContainerClasses || ''}`}>
      <LocalizationProvider
        localeText={
          roRO.components.MuiLocalizationProvider.defaultProps.localeText
        }
        dateAdapter={AdapterDayjs}
        adapterLocale="ro"
      >
        <DatePicker
          format="DD.MM.YYYY"
          {...datePickerProps}
          label={
            datePickerProps?.label
              ? datePickerProps.label + (isOptional ? '(optional)' : '*')
              : ''
          }
          sx={{
            '& .Mui-disabled': {
              '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.85) !important',
              '&.MuiInputBase-input': {
                backgroundColor: 'rgba(0,0,0,0.05)',
              },
            },
          }}
        ></DatePicker>
      </LocalizationProvider>
    </div>
  );
};
