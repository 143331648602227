import { useTranslation } from 'react-i18next';

export const YesNo = (props: { isYes: boolean; shouldBeNull?: boolean }) => {
  const { t: tCommon } = useTranslation('common');
  const { isYes, shouldBeNull } = props;

  return (
    <div>{shouldBeNull ? '' : tCommon(`basic.${isYes ? 'yes' : 'no'}`)}</div>
  );
};
