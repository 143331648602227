import { Class, ClassProfile, Specialization } from './Class';
import { School, SchoolProfile } from './School';
import { SchoolYear } from './SchoolYear';
import { StudyYear } from './StudyYear';
import { SubjectType } from './Subject';
import { User } from './User';

export interface Title {
  default?: string;
  create?: string;
  view?: string;
  edit?: string;
}
export interface Country {
  id: number;
  nume: string;
}

export interface County {
  id: number;
  nume: string;
}

export interface City {
  id: number;
  nume: string;
}

export interface CountyWithCities extends County {
  localitati: City[];
}

export interface CountryWithCounties extends Country {
  judete: CountyWithCities[];
}

export enum SchoolPageViewTypes {
  View = 'view',
  Edit = 'edit',
}

export enum PageViewTypes {
  Create = 'create',
  View = 'view',
  Edit = 'edit',
}

export const apiCallConfigTypes = {
  get: {
    headers: {
      'Content-Type': 'application/json',
    },
  },
  post: {
    headers: {
      'Content-Type': 'application/json',
    },
  },
  delete: {
    headers: {
      'Content-Type': 'application/json',
    },
  },
  patch: {
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
  },
  file: {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
};

export interface SelectOption {
  value: string | number;
  label: string;
}

export interface IriEntity {
  '@id': string;
}

export interface AutocompleteOption {
  // to do - de trimis si numele pe back-end
  id: number;
  nume: string;
}

export interface GenericResources {
  tari?: CountryWithCounties[];
  profileScoala?: SchoolProfile[];
  scoli: School[];
  specializari: Specialization[];
  profileClasa: ClassProfile[];
  profesori: User[];
  aniScolari: SchoolYear[];
  aniStudiu: StudyYear[];
  tipMaterie: SubjectType[];
  studenti: User[];
  clase: Class[];
}

export enum RequiredResources {
  Tari = 'tari',
  ProfileScoala = 'profileScoala',
  Scoli = 'scoli',
  ProfileClasa = 'profileClasa',
  AniStudiu = 'aniStudiu',
  AniScolari = 'aniScolari',
  Specializari = 'specializari',
  Profesori = 'profesori',
  Studenti = 'studenti',
  TipMaterie = 'tipMaterie',
  Clase = 'clase',
}

export type CollectionApiResponse<T> = {
  ['hydra:member']: Array<T>;
  ['hydra:totalItems']: number;
};

export type ParamsType = {
  [key: string]: string;
};

export type IRI = string;

export interface IFile {
  id?: number;
  urlPath: string;
  contentUrl: string;
}
