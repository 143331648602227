import { Account } from 'library/types/User';
import { createContext, Dispatch, useReducer } from 'react';

export const LOCAL_STORAGE_CONTEXT = 'smart-edu';

export enum UserContextActionTypes {
  SetAccount = 'SET_ACCOUNT',
  SetSelectedView = 'SET_SELECTED_VIEW',
  SetTheme = 'SET_THEME',
  LogOut = 'LOG_OUT',
}

// needs changing
export enum Theme {
  blue = 'blue',
  lightblue = 'lightblue',
  orange = 'orange',
  purple = 'purple',
}

export enum ViewTypes {
  SysAdm = 'SysAdm',
  ScAdm = 'ScAdm',
  Director = 'Director',
  Diriginte = 'Diriginte',
  Profesor = 'Profesor',
  Invatator = 'Invatator',
  Parinte = 'Parinte',
  Elev = 'Elev',
}

export interface UserContextState {
  theme: Theme;
  account: Account | undefined;
}

export interface ContextAction {
  type: UserContextActionTypes;
  value?: any;
}

const userContextReducer = (
  state: UserContextState,
  action: ContextAction
): UserContextState => {
  let newState = state;
  switch (action.type) {
    case UserContextActionTypes.SetTheme:
      newState = {
        ...state,
        theme: action.value,
      };
      break;
    case UserContextActionTypes.SetAccount:
      newState = {
        ...state,
        account: {
          accessToken: action.value.accessToken,
          user: action.value.user,
          selectedView: action.value.selectedView,
          clasa: action.value.clasa,
        },
      };
      break;
    case UserContextActionTypes.LogOut:
      newState = {
        ...state,
        account: undefined,
      };
      break;
    case UserContextActionTypes.SetSelectedView:
      newState = {
        ...state,
        account: {
          ...state.account!,
          selectedView: action.value,
        },
      };
  }
  localStorage.setItem(LOCAL_STORAGE_CONTEXT, JSON.stringify(newState));

  return { ...newState };
};

const initialState = {
  theme: Theme.purple,
  account: undefined,
};

const getInitialContext = (): UserContextState => {
  let context = initialState;
  const storedContextString = localStorage.getItem(LOCAL_STORAGE_CONTEXT);

  if (storedContextString) {
    const storedContext = JSON.parse(storedContextString);
    context = {
      ...context,
      ...storedContext,
    };
  }

  return context;
};

export const UserContext = createContext<{
  state: UserContextState;
  dispatch: Dispatch<ContextAction>;
}>({
  state: getInitialContext(),
  dispatch: () => null,
});

export const UserContextProvider = (props: any): JSX.Element => {
  const [state, dispatch] = useReducer(userContextReducer, getInitialContext());

  return (
    <UserContext.Provider value={{ state: state, dispatch: dispatch }}>
      {props.children}
    </UserContext.Provider>
  );
};
