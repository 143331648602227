import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import { apiCallConfigTypes } from 'library/types/Common';
export function FisierApi() {
  const { apiHandler } = JWTInterceptor();

  const uploadFile = async (formData: FormData): Promise<any> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/fisiere`,
      data: formData,
      axiosConfig: apiCallConfigTypes.file,
    });

    return response.data;
  };

  return {
    uploadFile,
  };
}
