import { Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { HomeworkApi } from 'api/homework.api';
import { UserContext } from 'context/UserContext';
import { UserRoles } from 'library/types/User';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from 'resources/images';

export const HomeworkActions = ({
  homeworkId,
  isActive,
  canEdit,
  canSubmitHomework,
}: {
  homeworkId: number;
  canEdit: boolean;
  isActive: boolean;
  canSubmitHomework: boolean;
}) => {
  const { activateOrDeactivateHomework } = HomeworkApi();
  const userContext = useContext(UserContext);
  const userRole = userContext.state.account!.selectedView;
  const navigate = useNavigate();
  return (
    <div className="flex gap-2">
      {/* <Tooltip arrow title="Vezi temele">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate('/table-student-homework', {
              state: {
                queryParams: {
                  'tema.id': homeworkId,
                },
              },
            });
          }}
        >
          <img src={icons.general.bookWithPencil} width={25} />
        </div>
      </Tooltip> */}
      {[UserRoles.ROLE_ELEV].includes(userRole) && canSubmitHomework && (
        <Tooltip arrow title="Trimite tema">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate('/answer-homework', {
                state: {
                  homeworkId,
                },
              });
            }}
          >
            <img src={icons.general.penIcon} width={25} />
          </div>
        </Tooltip>
      )}
      {[UserRoles.ROLE_INVATATOR, UserRoles.ROLE_PROFESOR].includes(
        userRole
      ) && (
        <Tooltip arrow title="Activeaza / Deactiveaza tema">
          <div
            className="cursor-pointer"
            onClick={async () => {
              await activateOrDeactivateHomework(homeworkId, !isActive);
            }}
          >
            {!isActive ? (
              <img src={icons.general.greenMark} width={25} />
            ) : (
              <img src={icons.general.redMark} width={25} />
            )}
          </div>
        </Tooltip>
      )}
      {[UserRoles.ROLE_INVATATOR, UserRoles.ROLE_PROFESOR].includes(userRole) &&
        canEdit && (
          <Tooltip arrow title="Editeaza tema">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate('/homework', {
                  state: {
                    homeworkId,
                  },
                });
              }}
            >
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <IconButton color="secondary">
                  <Edit />
                </IconButton>
              </Box>
            </div>
          </Tooltip>
        )}
    </div>
  );
};
