import { CircularProgress, ThemeProvider } from '@mui/material';
import { UserApi } from 'api/user.api';
import { Header } from 'common/Header/Header';
import { RequireAuthAndRole } from 'common/components/auth/RequireAuth';
import { RouteWrapper } from 'common/components/auth/RouteWrapper';
import { UserContext, UserContextActionTypes } from 'context/UserContext';
import { UtilityContext } from 'context/UtilityContext';
import { Login } from 'modules/Auth/Login';
// import { ResetPassword } from 'modules/Auth/RecoverPassword';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc'; // Import the UTC plugin
import { useContext, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { privateRoutes } from 'routes';
import './App.css';
import { Themes } from './CustomStyles';
import {
  copyToClipboard,
  generateMockDataReportCatalog,
} from 'library/helpers/helper';

function App() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Europe/Bucharest');
  const utilityContext = useContext(UtilityContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading } = utilityContext.state;

  const userContext = useContext(UserContext);
  const { getMe } = UserApi();

  const getMeAction = async () => {
    const response = await getMe();
    if (!response.data || !response.data.user) {
      userContext.dispatch({ type: UserContextActionTypes.LogOut });
      return;
    }
    userContext.dispatch({
      type: UserContextActionTypes.SetAccount,
      value: {
        user: response.data.user,
        clasa: response.data.clasa,
        accessToken: userContext.state.account!.accessToken,
        selectedView: response.data.user.rolActiv,
      },
    });
  };

  const isNoHeaderLocation = (pathName: string) => {
    return pathName.includes('print');
  };

  useEffect(() => {
    if (!userContext.state.account) {
      return navigate('/login');
    }
    getMeAction();
    // copyToClipboard(JSON.stringify(generateMockDataReportCatalog()));
  }, []);

  useEffect(() => {
    if (!userContext.state.account) return navigate('/login');
  }, [userContext.state]);

  return (
    <ThemeProvider theme={Themes[userContext.state.theme]}>
      <div id="big-wrapper" className="relative">
        {isLoading && (
          <div
            style={{ zIndex: '9999999' }}
            className="absolute w-full h-full flex justify-center items-center"
          >
            <CircularProgress className="" size={100} />
          </div>
        )}
        <div className="app w-full flex flex-col">
          {!isNoHeaderLocation(location.pathname) && <Header />}
          <Routes>
            {privateRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <div>
                      <RequireAuthAndRole
                        allowedRoles={route.allowedRoles}
                        redirectTo="/login"
                      >
                        <RouteWrapper
                          path={route.path}
                          title={route.element.props.title ?? route.title ?? ''}
                          noPageContainer={route.noPageContainer}
                        >
                          {route.element!}
                        </RouteWrapper>
                      </RequireAuthAndRole>
                    </div>
                  }
                />
              );
            })}
            <Route
              path="/login"
              element={
                <RouteWrapper path="/login" title={'Login'} isCentered>
                  <Login />
                </RouteWrapper>
              }
            />
            {/* <Route
              path="/reset-password"
              element={
                <RouteWrapper
                  path="/reset-password"
                  title={'Reseteaza parola'}
                  isCentered
                >
                  <ResetPassword />
                </RouteWrapper>
              }
            /> */}
          </Routes>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
