import { Button } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface FileUploadProps {
  buttonText: string;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
  selectedFile: File | null;
  containerClasses?: string;
  isOptional?: boolean;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({
  selectedFile,
  buttonText,
  setSelectedFile,
  containerClasses,
  isOptional,
}) => {
  const { t } = useTranslation('common');
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file: File | null = event.target.files ? event.target.files[0] : null;
    if (!file) return;
    if (file.size > 10 * 1024 * 1024)
      return toast.error(t('fileInput.fileSizeExceeded'));
    setSelectedFile(file);
  };

  return (
    <div className={`flex gap-2 items-center ${containerClasses}`}>
      <input
        className="hidden"
        id="raised-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span">
          {buttonText ?? 'Upload'}
          {isOptional ? '(optional)' : '*'}
        </Button>
      </label>
      <div>{selectedFile?.name}</div>
    </div>
  );
};

export default FileUploadComponent;
