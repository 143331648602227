import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';

interface RegularSelectProps {
  selectProps?: Partial<SelectProps>;
  options: Array<{
    value: any;
    label: string;
  }>;
  tailwindContainerClasses?: string;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  selectValues?: string[];
  noOptionalOrMandatory?: boolean;
  isOptional?: boolean;
}

export const RegularSelect = (props: RegularSelectProps): JSX.Element => {
  const {
    selectProps,
    tailwindContainerClasses,
    onChange,
    options,
    selectValues,
    isOptional,
    noOptionalOrMandatory,
  } = props;

  return (
    <div className={`py-3 ${tailwindContainerClasses || ''}`}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          {selectProps?.label
            ? selectProps.label +
              (!noOptionalOrMandatory ? (isOptional ? '(optional)' : '*') : '')
            : ''}
        </InputLabel>
        <Select
          MenuProps={{ disableScrollLock: true }}
          sx={{
            '& .Mui-disabled': {
              '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.85) !important',
              '&.MuiInputBase-input': {
                backgroundColor: 'rgba(0,0,0,0.05)',
              },
            },
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          {...selectProps}
        >
          {options.map((e, index) => (
            <MenuItem key={e.label + '-' + index} value={e.value}>
              {selectProps?.multiple && selectValues ? (
                <div className="flex items-center">
                  <Checkbox checked={selectValues.indexOf(e.value) > -1} />
                  <ListItemText primary={e.label} />
                </div>
              ) : (
                e.label
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
