// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations_ro from 'locales/ro/translation.json';
import translations_en from 'locales/en/translation.json';

const resources = {
  ro: {
    common: translations_ro.common,
    library: translations_ro.libary,
    modules: translations_ro.modules,
  },
  en: {
    common: translations_en.common,
    library: translations_en.libary,
    modules: translations_en.modules,
  },
};

i18n.use(initReactI18next).init({
  resources,
  ns: ['common', 'library', 'modules'],
  lng: 'ro',
});

export default i18n;
