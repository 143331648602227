import { Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { UserContext } from 'context/UserContext';
import { StudentHomework } from 'library/types/Homework';
import { UserRoles } from 'library/types/User';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from 'resources/images';

export const LessonActions = ({ lessonId }: { lessonId: number }) => {
  const userContext = useContext(UserContext);
  const userRole = userContext.state.account!.selectedView;
  const navigate = useNavigate();

  return (
    <div className="flex gap-2">
      {[UserRoles.ROLE_INVATATOR, UserRoles.ROLE_PROFESOR].includes(
        userRole
      ) && (
        <Tooltip arrow title="Editeaza">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(`/lesson/edit`, {
                state: {
                  lessonId,
                },
              });
            }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <IconButton color="secondary">
                <Edit />
              </IconButton>
            </Box>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
