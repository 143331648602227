import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import {
  DateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { Dayjs } from 'dayjs';

import { roRO } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface TimePickerInputProps {
  timePickerProps?: Partial<TimePickerProps<Dayjs>>;
  tailwindContainerClasses?: string;
  isDateTimePicker?: boolean;
  dateTimePickerProps?: Partial<DateTimePickerProps<Dayjs>>;
  isOptional?: boolean;
}

export const TimePickerInput = (props: TimePickerInputProps): JSX.Element => {
  const {
    dateTimePickerProps,
    isDateTimePicker,
    timePickerProps,
    tailwindContainerClasses,
    isOptional,
  } = props;
  return (
    <div className={`py-3 ${tailwindContainerClasses || ''}`}>
      <LocalizationProvider
        localeText={
          roRO.components.MuiLocalizationProvider.defaultProps.localeText
        }
        dateAdapter={AdapterDayjs}
        adapterLocale="ro"
      >
        {isDateTimePicker ? (
          <DateTimePicker
            {...dateTimePickerProps}
            label={
              dateTimePickerProps?.label
                ? dateTimePickerProps.label + (isOptional ? '(optional)' : '*')
                : ''
            }
            ampm={false}
            sx={{
              '& .Mui-disabled': {
                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.85) !important',
                '&.MuiInputBase-input': {
                  backgroundColor: 'rgba(0,0,0,0.05)',
                },
              },
            }}
          ></DateTimePicker>
        ) : (
          <TimePicker
            {...timePickerProps}
            ampm={false}
            sx={{
              '& .Mui-disabled': {
                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.85) !important',
                '&.MuiInputBase-input': {
                  backgroundColor: 'rgba(0,0,0,0.05)',
                },
              },
            }}
          ></TimePicker>
        )}
      </LocalizationProvider>
    </div>
  );
};
