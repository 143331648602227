import { IRI } from 'library/types/Common';

export const EvaluationsConfigQuestions = (props: {
  intrebari: {
    [key: string]: string;
  };
}) => {
  const { intrebari } = props;
  return (
    <div className="flex flex-col">
      {Object.values(intrebari).map((e, index) => (
        <li key={e + index}>{e}</li>
      ))}
    </div>
  );
};

export const EvaluationsConfigResponses = (props: {
  raspunsuri: {
    [questionIri: IRI]: {
      raspuns: number;
      textIntrebare: string;
    };
  };
}) => {
  const { raspunsuri } = props;
  return (
    <div className="flex flex-col">
      {Object.values(raspunsuri).map((e, index) => (
        <div className="flex flex-col">
          <li key={e.raspuns + '-' + index}>
            {e.textIntrebare} - {e.raspuns} / 5
          </li>
        </div>
      ))}
    </div>
  );
};
