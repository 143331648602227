import { CatalogApi } from 'api/catalog.api';
import { UserContext } from 'context/UserContext';
import { UserRoles } from 'library/types/User';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const CatalogActions = ({ catalogId }: { catalogId: number | null }) => {
  const { motiveazaAbsenta } = CatalogApi();
  const userContext = useContext(UserContext);
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  if (
    !userContext.state.account ||
    !(UserRoles.ROLE_DIRIGINTE, UserRoles.ROLE_INVATATOR).includes(userContext.state.account.selectedView) ||
    !catalogId
  ) {
    return <></>;
  }

  return (
    <div
      className="cursor-pointer text-blue-500 hover:underline focus:outline-none"
      onClick={async () => {
        await motiveazaAbsenta(catalogId);
      }}
    >
      {tModules('schoolAdmin.tables.cellComponents.motivateAbsence')}
    </div>
  );
};
