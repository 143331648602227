import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  apiCallConfigTypes,
} from 'library/types/Common';
import { TimeSlot, TimeSlotPayload } from 'library/types/TimeSlot';
export function TimeSlotApi() {
  const { apiHandler } = JWTInterceptor();

  const getTimeSlot = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<TimeSlot | CollectionApiResponse<TimeSlot>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id
        ? `${serverUri()}/intervaleOrar/${id}`
        : `${serverUri()}/intervaleOrar`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const createTimeSlot = async (
    timeSlotInfo: TimeSlotPayload
  ): Promise<TimeSlot> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/intervaleOrar`,
      data: timeSlotInfo,
      axiosConfig: apiCallConfigTypes.post,
      successMessage: 'Time interval was successfully created!',
    });

    return response.data;
  };

  const editTimeSlot = async (
    id: number,
    data: Partial<TimeSlotPayload>
  ): Promise<TimeSlot> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/intervaleOrar/${id}`,
      data: data,
      axiosConfig: {
        ...apiCallConfigTypes.patch,
      },
      successMessage: 'The time slot was successfully modified!',
    });

    return response.data;
  };

  return {
    getTimeSlot,
    createTimeSlot,
    editTimeSlot,
  };
}
