import { UserRoles } from 'library/types/User';
import { useUserRoles } from 'library/hooks/useUserRoles';

export const UserRolesCell = ({
  userRoles,
}: {
  userRoles: Array<UserRoles>;
}) => {
  const userRolesTranslations = useUserRoles();
  return (
    <div>
      {userRoles.map((role) => (
        <div key={role as keyof typeof userRolesTranslations}>
          {userRolesTranslations[role as keyof typeof userRolesTranslations]}
        </div>
      ))}
    </div>
  );
};
