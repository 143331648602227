import { publicServerUri, serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import {
  CollectionApiResponse,
  ParamsType,
  SelectOption,
  apiCallConfigTypes,
} from 'library/types/Common';
import {
  Catedra,
  CollectionSchool,
  School,
  SchoolPayload,
} from 'library/types/School';

export function SchoolApi() {
  const { apiHandler } = JWTInterceptor();

  const getAvailableSchools = async (): Promise<Array<SelectOption>> => {
    const response = await apiHandler({
      method: 'get',
      url: `${publicServerUri()}/scoli-disponibile`,
    });

    return response.data?.scoli?.map(
      (e: { id: number; numeScoala: string }) => ({
        value: e.id,
        label: e.numeScoala,
      })
    );
  };

  const getSchool = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<School | CollectionApiResponse<CollectionSchool>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/scoli/${id}` : `${serverUri()}/scoli/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const getCatedra = async (config?: {
    id?: number;
    params?: ParamsType;
  }): Promise<Catedra | CollectionApiResponse<Catedra>> => {
    if (config) {
      var { id, params } = config;
    }
    const response = await apiHandler({
      method: 'get',
      url: id ? `${serverUri()}/catedre/${id}` : `${serverUri()}/catedre/`,
      axiosConfig: {
        ...apiCallConfigTypes.get,
        params,
      },
    });

    return response.data;
  };

  const editSchool = async (
    id: number,
    schoolInfo: SchoolPayload
  ): Promise<School> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/scoli/${id}`,
      data: schoolInfo,
      axiosConfig: {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
      successMessage: 'School successfully updated!',
    });

    return response.data;
  };

  const createSchool = async (schoolInfo: SchoolPayload): Promise<School> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/scoli`,
      data: schoolInfo,
      axiosConfig: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      successMessage: 'School successfully created!',
    });

    return response.data;
  };

  return {
    getAvailableSchools,
    getSchool,
    editSchool,
    createSchool,
    getCatedra,
  };
}
