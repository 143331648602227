import { ClassApi } from 'api/class.api';
import { SchoolYearApi } from 'api/schoolYear.api';
import { StudyYearApi } from 'api/studyYear.api';
import { UserApi } from 'api/user.api';
import { AutocompleteInput } from 'common/components/inputs/AutocompleteInput';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import {
  UtilityContext,
  UtilityContextActionTypes,
} from 'context/UtilityContext';
import { getStringValuesOfEnum, isObjectValid } from 'library/helpers/helper';
import {
  Class,
  ClassModuleData,
  ClassPayload,
  ClassProfile,
  ClassSpecialization,
} from 'library/types/Class';
import { CollectionApiResponse, PageViewTypes } from 'library/types/Common';
import { CollectionSchoolYear } from 'library/types/SchoolYear';
import { StudyYear } from 'library/types/StudyYear';
import { InvatoriDirigintiClase } from 'library/types/User';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const IndividualClassModule = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const params = useParams();
  const navigate = useNavigate();
  const utilityContext = useContext(UtilityContext);
  const { pageViewType } = params;
  const isView = pageViewType === PageViewTypes.View;
  const location = useLocation();
  if (location && location.state) {
    var { editId: _id } = location?.state;
  }
  const {
    getClass,
    editClass,
    createClass,
    getClassProfile,
    getClassSpecialization,
  } = ClassApi();
  const { getAllDiriginti, getAllInvatatori } = UserApi();
  const { getStudyYear } = StudyYearApi();
  const { getSchoolYear } = SchoolYearApi();

  const userContext = useContext(UserContext);
  const [classData, setClassData] = useState<ClassModuleData | undefined>();

  const [availableInvatatori, setAvailableInvatatori] = useState<
    Array<InvatoriDirigintiClase>
  >([]);
  const [availableDiriginti, setAvailableDiriginti] = useState<
    Array<InvatoriDirigintiClase>
  >([]);

  const [schoolYears, setSchoolYears] = useState<Array<CollectionSchoolYear>>(
    []
  );
  const [studyYears, setStudyYears] = useState<Array<StudyYear>>([]);
  const [classSpecializations, setClassSpecializations] = useState<
    Array<ClassSpecialization>
  >([]);
  const [classProfiles, setClassProfiles] = useState<Array<ClassProfile>>([]);

  const [autocompleteInputValues, setAutocompleteInputValues] = useState<{
    cadruDidacticResponsabil: string;
  }>({
    cadruDidacticResponsabil: '',
  });

  const getIndividualClass = async () => {
    if (!_id || isNaN(parseInt(_id))) return;
    try {
      var individualClass = (await getClass({ id: parseInt(_id) })) as Class;
    } catch (e) {
      return navigate('/home');
    }
    setClassData({
      ...individualClass,
      codClasa: individualClass.codClasa.slice(-1),
    } as Class);
  };

  useEffect(() => {
    if (!getStringValuesOfEnum(PageViewTypes).includes(pageViewType)) {
      navigate(`/individual-class/view/${_id}`);
    }
  }, []);

  useEffect(() => {
    if (!userContext.state.account) return;

    (async () => {
      const diriginti = await getAllDiriginti();
      const invatatori = await getAllInvatatori();
      const schoolYears =
        (await getSchoolYear()) as CollectionApiResponse<CollectionSchoolYear>;
      const studyYears =
        (await getStudyYear()) as CollectionApiResponse<StudyYear>;
      const classSpecializations =
        (await getClassSpecialization()) as CollectionApiResponse<ClassSpecialization>;
      const classProfiles =
        (await getClassProfile()) as CollectionApiResponse<ClassProfile>;

      setAvailableDiriginti(diriginti.filter((e) => !e.clasaCurenta));
      setAvailableInvatatori(invatatori.filter((e) => !e.clasaCurenta));
      setSchoolYears(schoolYears['hydra:member']);
      setStudyYears(studyYears['hydra:member']);
      setClassSpecializations(classSpecializations['hydra:member']);
      setClassProfiles(classProfiles['hydra:member']);

      if (pageViewType === 'create') {
        return setClassData({
          id: null,
          anScolar: null,
          anStudiu: null,
          cadruDidacticResponsabil: null,
          codClasa: null,
          profilClasa: null,
          specializare: null,
          maxOreSaptamana: null,
        });
      } else {
        await getIndividualClass();
      }
      utilityContext.dispatch({
        type: UtilityContextActionTypes.ApiLoaded,
      });
    })();
  }, []);

  const isActionValid = () => {
    if (isView || (pageViewType !== 'create' && !_id) || !classData) {
      return false;
    }
    if (
      !isObjectValid(classData, ['profilClasa', 'specializare', 'catalogInfo'])
    ) {
      toast.error(tModules('schoolAdmin.class.submitClassValidationError'));
      return false;
    }
    return true;
  };

  const classAction = async (id?: number) => {
    if (!isActionValid()) return;

    const {
      anScolar,
      anStudiu,
      cadruDidacticResponsabil,
      codClasa,
      profilClasa,
      specializare,
      maxOreSaptamana,
    } = classData!;

    const payloadObject: ClassPayload = {
      anScolar: `/api/aniScolari/${anScolar!.id}`,
      anStudiu: `/api/aniStudiu/${anStudiu!.id}`,
      cadruDidacticResponsabil: `/api/users/${cadruDidacticResponsabil!.id}`,
      codClasa: anStudiu!.anStudiu + codClasa!,
      profilClasa: profilClasa ? `/api/profileClasa/${profilClasa!.id}` : null,
      specializare: specializare
        ? `/api/specializari/${specializare!.id}`
        : null,
      maxOreSaptamana: maxOreSaptamana!,
      scoala: `/api/scoli/${userContext.state.account!.user.scoala.id}`,
    };

    if (id) {
      await editClass(id, payloadObject);
    } else {
      await createClass(payloadObject);
    }

    navigate('/classes');
  };

  return (
    <div className="page-container">
      {classData && (
        <div className="flex items-center flex-col">
          <div
            style={{
              width: '600px',
            }}
            className="flex flex-col"
          >
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.class.schoolYear'),
                  value: classData.anScolar
                    ? classData.anScolar?.anInceput + 1
                    : '',
                  disabled: true,
                }}
              />
            ) : (
              <RegularSelect
                options={schoolYears.map((e) => ({
                  label: e.anInceput.toString(),
                  value: e.id,
                }))}
                onChange={(e) => {
                  setClassData((prevValue) => {
                    if (!prevValue) return;
                    return {
                      ...prevValue,
                      anScolar: schoolYears.find((el) => el.id === e)!,
                    };
                  });
                }}
                selectProps={{
                  label: tModules('schoolAdmin.class.schoolYear'),
                  value: classData.anScolar?.id || '',
                  fullWidth: true,
                }}
              />
            )}
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.class.studyYear'),
                  value: classData.anStudiu?.anStudiu || '',
                  disabled: true,
                }}
              />
            ) : (
              <RegularSelect
                options={studyYears.map((e) => ({
                  label: e.anStudiu.toString(),
                  value: e.id,
                }))}
                onChange={(e) => {
                  setClassData((prevValue) => {
                    if (!prevValue) return;
                    return {
                      ...prevValue,
                      anStudiu: studyYears.find((el) => el.id === e)!,
                    };
                  });
                }}
                selectProps={{
                  label: tModules('schoolAdmin.class.studyYear'),
                  value: classData.anStudiu?.id || '',
                  fullWidth: true,
                }}
              />
            )}
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.class.classCode'),
                  value: classData.anStudiu?.anStudiu || '',
                  disabled: true,
                }}
              />
            ) : (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.class.classCode'),
                  value: classData.codClasa || '',
                  onChange: (e) => {
                    const letter = e.target.value.length
                      ? e.target.value[e.target.value.length - 1]
                      : '';
                    if (!letter.match(/[a-z]/i) && letter !== '') return;
                    setClassData((prevValue) => {
                      if (!prevValue) return;
                      return {
                        ...prevValue,
                        codClasa: letter,
                      };
                    });
                  },
                }}
              />
            )}
            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.class.maximumNumberOfHours'),
                  value: classData.maxOreSaptamana || '',
                  disabled: true,
                }}
              />
            ) : (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.class.maximumNumberOfHours'),
                  value: classData.maxOreSaptamana || '',
                  onChange: (e) => {
                    if (
                      e.target.value === '' ||
                      /^[0-9]*$/.test(e.target.value)
                    )
                      setClassData((prevValue) => {
                        if (!prevValue) return;
                        return {
                          ...prevValue,
                          maxOreSaptamana: parseInt(
                            e.target.value.replace('e', '')
                          ),
                        };
                      });
                  },
                }}
              />
            )}
            {classData.anStudiu && classData.anStudiu.anStudiu > 8 && (
              <div>
                {isView ? (
                  <RegularInput
                    textFieldProps={{
                      fullWidth: true,
                      label: tModules('schoolAdmin.class.specialization'),
                      value: classData.specializare?.tipSpecializare || '',
                      disabled: true,
                    }}
                  />
                ) : (
                  <RegularSelect
                    options={classSpecializations.map((e) => ({
                      label: e.tipSpecializare.toString(),
                      value: e.id,
                    }))}
                    onChange={(e) => {
                      setClassData((prevValue) => {
                        if (!prevValue) return;
                        return {
                          ...prevValue,
                          specializare: classSpecializations.find(
                            (el) => el.id === e
                          )!,
                          profilClasa: classSpecializations.find(
                            (el) => el.id === e
                          )!.profilClasa,
                        };
                      });
                    }}
                    selectProps={{
                      label: tModules('schoolAdmin.class.specialization'),
                      value: classData.specializare?.id || '',
                      fullWidth: true,
                      disabled: isView,
                    }}
                  />
                )}
                <RegularInput
                  textFieldProps={{
                    fullWidth: true,
                    label: tModules('schoolAdmin.class.classProfile'),
                    value: classData.profilClasa?.tipProfilClasa || '',
                    disabled: true,
                  }}
                />
              </div>
            )}

            {isView ? (
              <RegularInput
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.class.classHeadmaster'),
                  value: classData.cadruDidacticResponsabil?.nume || '',
                  disabled: true,
                }}
              />
            ) : (
              <AutocompleteInput
                autocompleteProps={{
                  disabled: isView || classData.anStudiu === null,
                  options:
                    classData.anStudiu === null
                      ? []
                      : classData.anStudiu.anStudiu < 5
                      ? availableInvatatori.map((e) => ({
                          nume: e.nume + ' ' + e.prenume,
                          id: e.id,
                        }))
                      : availableDiriginti.map((e) => ({
                          nume: e.nume + ' ' + e.prenume,
                          id: e.id,
                        })),
                  value: classData.cadruDidacticResponsabil,
                  onChange: (
                    event: any,
                    newValue: {
                      nume: string;
                      id: number;
                    } | null
                  ) => {
                    setClassData((prevValue) => {
                      if (!prevValue) return;
                      return {
                        ...prevValue,
                        cadruDidacticResponsabil: newValue
                          ? {
                              nume: newValue.nume,
                              prenume: '',
                              id: newValue.id,
                            }
                          : null,
                      };
                    });
                  },
                  inputValue: autocompleteInputValues.cadruDidacticResponsabil,
                  onInputChange: (event, newInputValue) => {
                    setAutocompleteInputValues((prevValue) => {
                      return {
                        ...prevValue,
                        cadruDidacticResponsabil: newInputValue,
                      };
                    });
                  },
                }}
                textFieldProps={{
                  fullWidth: true,
                  label: tModules('schoolAdmin.class.classHeadmaster'),
                }}
              />
            )}
          </div>
          {!isView && (
            <ButtonComp
              onButtonClick={() => {
                if (
                  !getStringValuesOfEnum(PageViewTypes).includes(
                    pageViewType
                  ) ||
                  (pageViewType !== 'create' && !_id)
                )
                  return;
                pageViewType === 'create'
                  ? classAction()
                  : classAction(parseInt(_id!));
              }}
              buttonText={tCommon('inputs.saveButton')}
              buttonProps={{
                variant: 'contained',
                defaultValue: 'Login',
              }}
              tailwindContainerClasses="flex justify-center"
            />
          )}
        </div>
      )}
    </div>
  );
};
