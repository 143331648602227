import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat'; // For formatting as "YYYY-MM-DD - YYYY-MM-DD"
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {
  CollectionDetailedTimeTable,
  DaySchedule,
  Days,
  TimeSlotForDaySchedule,
  WeeklySchedule,
} from 'library/types/TimeTable';
import {
  changeDayNumberToDayString,
  parseHourToTimezone,
} from 'library/helpers/helper';

dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

function getFirstAndLastDates(arr: string[]) {
  let firstDate = dayjs(arr[0]);
  let lastDate = dayjs(arr[0]);

  arr.forEach((e) => {
    const currentDate = dayjs(e);
    if (currentDate.isBefore(firstDate)) {
      firstDate = currentDate;
    }
    if (currentDate.isAfter(lastDate)) {
      lastDate = currentDate;
    }
  });

  return { start: firstDate, end: lastDate };
}

export function populateSchedule(data: Array<CollectionDetailedTimeTable>): {
  weeks: {
    [key: string]: {
      [key in Days]: {
        date: string;
        schedule: DaySchedule;
      };
    };
  };
  currentWeek: string;
} {
  const { start, end } = getFirstAndLastDates(data.map((e) => e.data));
  let current = start.clone();
  const today = dayjs();

  const weeks: {
    [key: string]: {
      [key in Days]: {
        date: string;
        schedule: DaySchedule;
      };
    };
  } = {};

  let currentWeek = '';

  while (current.isBefore(end)) {
    const weekStart = current.clone().startOf('isoWeek');
    const weekEnd = weekStart.clone().endOf('isoWeek');
    const weekKey = `${weekStart.format('DD.MM.YYYY')} - ${weekEnd.format(
      'DD.MM.YYYY'
    )}`;

    if (!currentWeek) {
      const isCurrentWeek =
        today.isSameOrAfter(weekStart) && today.isSameOrBefore(weekEnd);
      if (isCurrentWeek) {
        currentWeek = weekKey;
      }
    }

    const week: {
      [key in Days]?: {
        date: string;
        schedule: DaySchedule;
      };
    } = Object.keys(Days).reduce((prev, curr, i) => {
      const currentDay = weekStart.clone().isoWeekday(i + 1);
      return {
        ...prev,
        [curr]: {
          date: currentDay.format('YYYY-MM-DD'),
          schedule: {},
        },
      };
    }, {});

    data.forEach((item, index) => {
      const itemDate = dayjs(item.data);
      if (
        itemDate.isSameOrAfter(weekStart) &&
        itemDate.isSameOrAfter(start) &&
        itemDate.isSameOrBefore(weekEnd) &&
        itemDate.isSameOrBefore(end)
      ) {
        const dayKey = changeDayNumberToDayString(item.orar.zi);
        week[dayKey as Days]!.schedule = {
          ...week[dayKey as Days]!.schedule,
          [item['interval_orar'].id.toString()]: {
            intervalOrarIri: item['interval_orar']['@id'],
            oraStart: item['interval_orar'].oraStart,
            oraSfarsit: item['interval_orar'].oraSfarsit,
            orarId: item.orar.id,
            orarIri: item.orar['@id'],
            materie: {
              tipMaterie: item.orar.materie.tipMaterie.tipMaterie,
              '@id': item.orar.materie['@id'],
              tipMaterieIri: item.orar.materie.tipMaterie['@id'],
            },
            anStudiu: item.orar.materie.tipMaterie.anStudiu.anStudiu,
            clasa: item.orar.clasa,
            profesor: item.orar.materie.user,
            orarDetaliatIri: item['@id'],
            orarDetaliatId: item.id,
            modulIri: item.orar.modul['@id'],
            linkZoom: item.linkZoom,
            lectie: item.lectie
              ? {
                  fisier: {
                    urlPath: item.lectie.fisier.urlPath,
                    contentUrl: item.lectie.fisier.contentUrl,
                  },
                  subiect: item.lectie.subiect,
                }
              : undefined,
          },
        };
      }
    });

    weeks[weekKey] = week as {
      [key in Days]: {
        date: string;
        schedule: DaySchedule;
      };
    };

    current = weekEnd.add(1, 'day');
  }

  return { weeks, currentWeek };
}

export const parseWeeklySchedule = (
  weeklySchedule: WeeklySchedule,
  keepIntervalOrarIri: string
): { [key: string]: { [timeSlot: number]: TimeSlotForDaySchedule } } => {
  const parsedSchedule: {
    [key: string]: { [timeSlot: string]: TimeSlotForDaySchedule };
  } = {};

  Object.keys(weeklySchedule).forEach((day) => {
    const daySchedule: { [timeSlot: string]: TimeSlotForDaySchedule } =
      weeklySchedule[day as Days];

    const filteredDaySchedule = Object.keys(daySchedule).reduce(
      (
        accumulator: { [timeSlot: string]: TimeSlotForDaySchedule },
        timeSlot
      ) => {
        const slot: TimeSlotForDaySchedule = daySchedule[timeSlot];
        // Keep the slot if it does not have an 'orarIri' or if it has the specific 'intervalOrarIri' we want to keep
        if (!slot.orarIri || slot.intervalOrarIri === keepIntervalOrarIri) {
          accumulator[timeSlot] = slot;
        }
        return accumulator;
      },
      {}
    );

    if (Object.keys(filteredDaySchedule).length > 0) {
      parsedSchedule[`${day}`] = filteredDaySchedule;
    }
  });

  return parsedSchedule;
};
