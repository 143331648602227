import { TestApi } from 'api/test.api';
import { UserApi } from 'api/user.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { SelectOption } from 'library/types/Common';
import { TestQuestion, TestQuestionPayload } from 'library/types/Test';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ManageTestQuestionModule = () => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const { getMe } = UserApi();
  const { editTestQuestion, createTestQuestion, getTestQuestion } = TestApi();

  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as {
    editId: number;
  };

  const { editId: testQuestionId } = state || {};

  const [testQuestionData, setTestQuestionData] = useState<TestQuestionPayload>(
    {
      materie: '',
      intrebare: '',
      raspuns1: '',
      raspuns2: '',
      raspuns3: '',
      raspuns4: '',
      raspuns5: '',
      raspuns6: '',
      raspunsCorect: 1,
    }
  );
  const [subjects, setSubjects] = useState<Array<SelectOption>>([]);

  useEffect(() => {
    (async () => {
      const response = await getMe();

      setSubjects(
        response.data!.user.materii.map((e) => {
          return {
            value: `/api/materii/${e.id}`,
            label: e.tipMaterie.tipMaterie,
          };
        })
      );

      if (testQuestionId) {
        const testQuestion = (await getTestQuestion({
          id: testQuestionId,
        })) as TestQuestion;

        setTestQuestionData({
          materie: testQuestion.materie['@id'],
          intrebare: testQuestion.intrebare,
          raspuns1: testQuestion.raspuns1,
          raspuns2: testQuestion.raspuns2,
          raspuns3: testQuestion.raspuns3,
          raspuns4: testQuestion.raspuns4,
          raspuns5: testQuestion.raspuns5,
          raspuns6: testQuestion.raspuns6,
          raspunsCorect: testQuestion.raspunsCorect,
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (!testQuestionData.raspuns2) {
      setTestQuestionData((prev) => ({
        ...prev,
        raspuns2: testQuestionData.raspuns3,
        raspuns3: testQuestionData.raspuns4,
        raspuns4: testQuestionData.raspuns5,
        raspuns5: testQuestionData.raspuns6,
      }));
    }
    if (!testQuestionData.raspuns3) {
      setTestQuestionData((prev) => ({
        ...prev,
        raspuns3: testQuestionData.raspuns4,
        raspuns4: testQuestionData.raspuns5,
        raspuns5: testQuestionData.raspuns6,
      }));
    }
    if (!testQuestionData.raspuns4) {
      setTestQuestionData((prev) => ({
        ...prev,
        raspuns4: testQuestionData.raspuns5,
        raspuns5: testQuestionData.raspuns6,
      }));
    }
    if (!testQuestionData.raspuns5) {
      setTestQuestionData((prev) => ({
        ...prev,
        raspuns5: testQuestionData.raspuns6,
      }));
    }
  }, [
    testQuestionData.raspuns1,
    testQuestionData.raspuns2,
    testQuestionData.raspuns3,
    testQuestionData.raspuns4,
    testQuestionData.raspuns5,
    testQuestionData.raspuns6,
  ]);

  const submitFormAction = async () => {
    const {
      intrebare,
      raspuns1,
      raspuns2,
      raspuns3,
      raspuns4,
      raspuns5,
      raspuns6,
      raspunsCorect,
      materie,
    } = testQuestionData;

    if (
      !materie ||
      !intrebare ||
      !raspunsCorect ||
      [raspuns1, raspuns2, raspuns3, raspuns4, raspuns5, raspuns6].filter(
        Boolean
      ).length < 2
    )
      return toast.error(tModules('test.createEditTestQuestion'));

    const testQuestionPayload: TestQuestionPayload = {
      materie,
      intrebare,
      raspuns1,
      raspuns2,
      raspuns3,
      raspuns4,
      raspuns5,
      raspuns6,
      raspunsCorect,
    };
    testQuestionId
      ? await editTestQuestion(testQuestionId, testQuestionPayload)
      : await createTestQuestion(testQuestionPayload);
    navigate('/table-test-question');
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        <div
          style={{
            width: '600px',
          }}
          className="flex flex-col"
        >
          <RegularSelect
            options={subjects}
            onChange={(e) => {
              setTestQuestionData((prev) => ({
                ...prev,
                materie: e,
              }));
            }}
            selectProps={{
              label: tModules('test.subjectSelect'),
              value: testQuestionData.materie,
              fullWidth: true,
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('test.question'),
              value: testQuestionData.intrebare,
              onChange: (e) => {
                setTestQuestionData((prev) => {
                  return { ...prev, intrebare: e.target.value };
                });
              },
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('test.answer1'),
              value: testQuestionData.raspuns1,
              onChange: (e) => {
                setTestQuestionData((prev) => {
                  return { ...prev, raspuns1: e.target.value };
                });
              },
            }}
          />
          <RegularInput
            textFieldProps={{
              fullWidth: true,
              label: tModules('test.answer2'),
              value: testQuestionData.raspuns2,
              onChange: (e) => {
                setTestQuestionData((prev) => {
                  return { ...prev, raspuns2: e.target.value };
                });
              },
            }}
          />
          <RegularInput
            isOptional={true}
            textFieldProps={{
              disabled: !testQuestionData.raspuns2,
              fullWidth: true,
              label: tModules('test.answer3'),
              value: testQuestionData.raspuns3,
              onChange: (e) => {
                setTestQuestionData((prev) => {
                  return { ...prev, raspuns3: e.target.value };
                });
              },
            }}
          />
          <RegularInput
            isOptional={true}
            textFieldProps={{
              disabled: !testQuestionData.raspuns3,
              fullWidth: true,
              label: tModules('test.answer4'),
              value: testQuestionData.raspuns4,
              onChange: (e) => {
                setTestQuestionData((prev) => {
                  return { ...prev, raspuns4: e.target.value };
                });
              },
            }}
          />
          <RegularInput
            isOptional={true}
            textFieldProps={{
              disabled: !testQuestionData.raspuns4,
              fullWidth: true,
              label: tModules('test.answer5'),
              value: testQuestionData.raspuns5,
              onChange: (e) => {
                setTestQuestionData((prev) => {
                  return { ...prev, raspuns5: e.target.value };
                });
              },
            }}
          />
          <RegularInput
            isOptional={true}
            textFieldProps={{
              disabled: !testQuestionData.raspuns5,
              fullWidth: true,
              label: tModules('test.answer6'),
              value: testQuestionData.raspuns6,
              onChange: (e) => {
                setTestQuestionData((prev) => {
                  return { ...prev, raspuns6: e.target.value };
                });
              },
            }}
          />
          <RegularSelect
            options={[
              testQuestionData.raspuns1,
              testQuestionData.raspuns2,
              testQuestionData.raspuns3,
              testQuestionData.raspuns4,
              testQuestionData.raspuns5,
              testQuestionData.raspuns6,
            ]
              .filter(Boolean)
              .map((e, index) => ({
                value: index + 1,
                label: index + 1 + '',
              }))}
            onChange={(e) => {
              setTestQuestionData((prev) => ({
                ...prev,
                raspunsCorect: e,
              }));
            }}
            selectProps={{
              label: tModules('test.correctAnswer'),
              value: testQuestionData.raspunsCorect,
              fullWidth: true,
            }}
          />
        </div>
        <ButtonComp
          onButtonClick={submitFormAction}
          buttonText={tCommon('inputs.saveButton')}
          buttonProps={{
            variant: 'contained',
          }}
          tailwindContainerClasses="flex justify-center"
        />
      </div>
    </div>
  );
};
