import { Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ClassApi } from 'api/class.api';
import { TermAkaModulApi } from 'api/termAkaModul.api';
import { TimeTableApi } from 'api/timetable.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import { UserContext } from 'context/UserContext';
import dayjs from 'dayjs';
import {
  adjustWeeklyScheduleTimes,
  changeDayStringToDayNumber,
  parseHourToTimezone,
} from 'library/helpers/helper';
import { parseWeeklySchedule } from 'library/services/ViewTimeTable.service';
import { IRI } from 'library/types/Common';
import {
  Days,
  SlotDetails,
  TimeSlotForDaySchedule,
  TimeslotsWithTimeTableData,
  WeeklySchedule,
} from 'library/types/TimeTable';
import { UserRoles } from 'library/types/User';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const ViewTimeTableModule = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const account = userContext.state.account!;
  const userRole = account.selectedView;
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');
  const [hoveredElement, setHoveredElement] = useState<IRI | null>(null);
  const theme = useTheme();

  const location = useLocation();
  const state = location.state as {
    moduleIri?: IRI;
    classIri: IRI;
  };

  const { moduleIri, classIri } = state || {};

  const {
    getTimeslotsWithTimeTable,
    deleteTimeTable,
    generateAllDetailedTimeTable,
  } = TimeTableApi();
  const { getAvailableModules } = TermAkaModulApi();
  const { getAvailableClasses } = ClassApi();

  const [shouldRefreshData, setShouldRefreshData] = useState<boolean>(false);

  const [classes, setClasses] = useState<
    Array<{
      id: number;
      classCode: string;
      studyYear: number;
    }>
  >([]);
  const [selectedClass, setSelectedClass] = useState<{
    id: number;
    classCode: string;
    studyYear: number;
  }>({
    id: -1,
    classCode: '',
    studyYear: -1,
  });

  const [modules, setModules] = useState<
    Array<{
      id: number;
      details: string;
    }>
  >([]);
  const [selectedModule, setSelectedModule] = useState<{
    id: number;
    details: string;
  }>({
    id: -1,
    details: '',
  });

  const [timeslotsWithTimeTable, setTimeslotsWithTimeTable] =
    useState<WeeklySchedule>();

  useEffect(() => {
    if (
      [
        UserRoles.ROLE_DIRECTOR,
        UserRoles.ROLE_PARINTE,
        UserRoles.ROLE_SCHOOL_ADMIN,
      ].includes(userRole)
    ) {
      let classArray: typeof classes;
      (async () => {
        const availableClasses = await getAvailableClasses();
        classArray = availableClasses
          .filter((e) => !Array.isArray(e))
          .map((e) => ({
            id: e.id,
            classCode: e.codClasa,
            studyYear: parseInt(
              e.anStudiu.split('/')[e.anStudiu.split('/').length - 1]
            ),
          }));
        setClasses(classArray);

        if (classIri) {
          setSelectedClass(
            classArray.find(
              (e) =>
                e.id ===
                parseInt(classIri.split('/')[classIri.split('/').length - 1])
            )!
          );
        }
      })();
      let modulesArray: typeof modules;
      if (userRole !== UserRoles.ROLE_PARINTE) {
        (async () => {
          const availableModules = await getAvailableModules();
          modulesArray = availableModules.map((e) => ({
            id: e.id,
            details: `${tModules('schoolAdmin.timeTable.orderAndDatePeriod')} ${
              e.ordine
            }, ${dayjs(e.dataInceput).format('DD.MM.YYYY')} - ${dayjs(
              e.dataSfarsit
            ).format('DD.MM.YYYY')}`,
          }));

          setModules(modulesArray);

          if (moduleIri) {
            setSelectedModule(
              modulesArray.find(
                (e) =>
                  e.id ===
                  parseInt(
                    moduleIri.split('/')[moduleIri.split('/').length - 1]
                  )
              )!
            );
          }
        })();
      }
    } else {
      getViewTimeTableResources();
    }
  }, []);

  useEffect(() => {
    console.log(parseHourToTimezone('04:00'));
  }, []);

  useEffect(() => {
    fetchTimeTableResources();
  }, [selectedClass.id, selectedModule.id]);

  useEffect(() => {
    if (!shouldRefreshData) return;
    setShouldRefreshData(false);
    fetchTimeTableResources();
  }, [shouldRefreshData]);

  const fetchTimeTableResources = () => {
    if (selectedClass.id === -1) return;
    if (
      [UserRoles.ROLE_DIRECTOR, UserRoles.ROLE_SCHOOL_ADMIN].includes(
        userRole
      ) &&
      selectedModule.id === -1
    )
      return;
    getViewTimeTableResources({
      classId: selectedClass.id,
      moduleId: selectedModule.id,
    });
  };

  const getViewTimeTableResources = async (
    timeslotsWithTimeTableData?: TimeslotsWithTimeTableData
  ) => {
    const timeslotsWithTimeTable = await getTimeslotsWithTimeTable(
      timeslotsWithTimeTableData
    );

    setTimeslotsWithTimeTable(
      adjustWeeklyScheduleTimes(timeslotsWithTimeTable)
    );
  };

  const redirectToCreateOrEdit = (slot: TimeSlotForDaySchedule, day: Days) => {
    const isEdit = !!slot?.orarIri;

    const stateObject = {
      state: {
        isEdit,
        availableTimeSlots: isEdit
          ? parseWeeklySchedule(timeslotsWithTimeTable!, slot.intervalOrarIri)
          : undefined,
        slot,
        intervalOrar: {
          '@id': slot.intervalOrarIri,
          start: slot.oraStart,
          end: slot.oraSfarsit,
        },
        zi: changeDayStringToDayNumber(day),
        anStudiu: selectedClass.studyYear,
        selectedModule: selectedModule.id,
        classCode: selectedClass.classCode,
        classIri: '/api/clase/' + selectedClass.id,
      },
    };

    navigate('/manage-timetable', stateObject);
  };

  const [selectedSlot, setSelectedSlot] = useState<SlotDetails | null>(null);

  const handleOpenDialog = ({
    slot,
    day,
    date,
  }: {
    slot: TimeSlotForDaySchedule;
    day: Days;
    date?: string;
  }): void => {
    if (!slot) return;
    setSelectedSlot({
      day,
      slot,
      date,
    });
  };

  const handleCloseDialog = (): void => {
    setSelectedSlot(null);
  };

  const deleteEntry = async () => {
    await deleteTimeTable(selectedSlot!.slot.orarId!);
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    await deleteEntry();
    setIsDeleteDialogOpen(false);
    setSelectedSlot(null);
    setShouldRefreshData(true);
  };

  const hasMultipleEntriesWithOrarIri = () => {
    if (!timeslotsWithTimeTable) return false;
    let orarIriCount = 0;

    for (const day of Object.values(timeslotsWithTimeTable)) {
      for (const timeSlot of Object.values(day)) {
        if (timeSlot.orarIri) {
          orarIriCount += 1;
          if (orarIriCount > 1) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const generateDetailedTimetables = async () => {
    if (!userContext.state.account) return;

    await generateAllDetailedTimeTable(
      userContext.state.account.user.scoala.id!
    );
  };

  const days = Object.values(Days).filter((day) => day !== Days.Duminica);

  const daysCount = days.length;
  const exampleDay = timeslotsWithTimeTable
    ? timeslotsWithTimeTable[days[0]]
    : {};
  // Transform timeSlots into an array of [slotId, oraStart]
  const timeSlots = Object.entries(exampleDay).map(([key, value]) => ({
    id: key,
    start: value.oraStart,
    end: value.oraSfarsit,
    iri: value.intervalOrarIri,
  }));

  // Sort the time slots by the start time
  timeSlots.sort((a, b) => {
    const [hoursA, minutesA] = a.start.split(':').map(Number);
    const [hoursB, minutesB] = b.start.split(':').map(Number);
    return hoursA * 60 + minutesA - (hoursB * 60 + minutesB);
  });
  const columnWidth = daysCount > 0 ? 90 / daysCount : 100;

  if (modules.length === 0 || classes.length === 0)
    return (
      <div className="flex w-full h-full justify-center items-center">
        <Typography variant="h2" color="primary">
          Nu exista {modules.length === 0 ? 'module' : 'clase'} disponibile.
        </Typography>
      </div>
    );

  return (
    <div className="overflow-x-auto w-full">
      <div className="flex justify-between">
        <div className="flex gap-3">
          {!!classes.length && (
            <RegularSelect
              tailwindContainerClasses="w-48"
              options={classes.map((e) => ({
                label: e.classCode.toString(),
                value: e.id,
              }))}
              onChange={(e) => {
                setSelectedClass(classes.find((el) => el.id === e)!);
              }}
              selectProps={{
                label: tModules('schoolAdmin.timeTable.classSelect'),
                value: selectedClass.id === -1 ? '' : selectedClass.id,
                fullWidth: true,
              }}
            />
          )}
          {!!modules.length && (
            <RegularSelect
              tailwindContainerClasses="w-80"
              options={modules.map((e) => ({
                label: e.details.toString(),
                value: e.id,
              }))}
              onChange={(e) => {
                setSelectedModule(modules.find((el) => el.id === e)!);
              }}
              selectProps={{
                label: tModules('schoolAdmin.timeTable.moduleSelect'),
                value: selectedModule.id === -1 ? '' : selectedModule.id,
                fullWidth: true,
              }}
            />
          )}
        </div>
        <div className="">
          {userContext.state.account?.selectedView !==
            UserRoles.ROLE_DIRECTOR && (
            <ButtonComp
              onButtonClick={generateDetailedTimetables}
              buttonText={tCommon('inputs.generateDetailedTimetables')}
              buttonProps={{
                variant: 'contained',
                defaultValue: 'Genereaza orare detaliate',
              }}
              tailwindContainerClasses="flex justify-center"
            />
          )}
        </div>
      </div>
      {timeslotsWithTimeTable && (
        <table
          style={{ backgroundColor: 'rgb(241, 245, 248)' }}
          className="min-w-full bg-white border-collapse select-none"
        >
          <thead className="text-white">
            <tr>
              <th
                style={{ width: `10%` }}
                className="bg-gray-800 w-24 py-3 px-2 uppercase font-semibold text-sm text-center border border-gray-300"
              >
                {tModules('schoolAdmin.timeTable.time')}
              </th>
              {days.map((day) => {
                return (
                  <th
                    key={day}
                    style={{ width: `${columnWidth}%` }}
                    className="bg-gray-800 py-3 px-4 uppercase font-semibold text-sm text-center border border-gray-300"
                  >
                    {day}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-gray-700 bg-white">
            {timeSlots.map(({ id, iri, start, end }) => (
              <tr key={id} className="align-middle">
                <td
                  style={{ width: `10%`, height: '100px' }}
                  className="py-2 px-2 border-b border-r border-gray-200 text-center"
                >
                  {exampleDay[id].oraStart} - {exampleDay[id].oraSfarsit}
                </td>
                {days.map((day) => {
                  const slot = timeslotsWithTimeTable[day]?.[id];
                  return (
                    <td
                      onMouseEnter={() => {
                        if (slot && slot.intervalOrarIri)
                          setHoveredElement(
                            day.toString() + slot.intervalOrarIri
                          );
                      }} // Set hover state to true
                      onMouseLeave={() => setHoveredElement(null)} // Reset hover state to false
                      style={{
                        width: `${columnWidth}%`,
                        height: '100px',
                        backgroundColor:
                          slot &&
                          day.toString() + slot.intervalOrarIri ===
                            hoveredElement
                            ? theme.palette.primary.light
                            : '',
                      }}
                      key={day}
                      className={`${
                        selectedClass.id === -1 && !slot?.clasa
                          ? 'cursor-default'
                          : 'cursor-pointer'
                      } py-2 px-4 border-b border-r border-gray-200 text-center align-middle`}
                      onClick={() => {
                        if (selectedClass.id === -1 && !slot?.clasa) return;
                        if (
                          userRole === UserRoles.ROLE_SCHOOL_ADMIN &&
                          !slot?.orarId
                        )
                          redirectToCreateOrEdit(slot, day);
                        else if (
                          [
                            UserRoles.ROLE_ELEV,
                            UserRoles.ROLE_PROFESOR,
                            UserRoles.ROLE_SCHOOL_ADMIN,
                          ].includes(userRole)
                        ) {
                          handleOpenDialog({
                            slot,
                            day,
                          });
                        }
                      }}
                    >
                      {slot && slot.orarIri ? (
                        <>
                          <div className="font-semibold">
                            {slot.materie?.tipMaterie}
                          </div>
                          <div>{slot.profesor?.numeComplet}</div>
                          <div className="text-gray-600">
                            {slot.clasa?.codClasa}
                          </div>
                        </>
                      ) : userRole === UserRoles.ROLE_SCHOOL_ADMIN ? (
                        <div>
                          {tModules('schoolAdmin.timeTable.addTimeTable')}
                        </div>
                      ) : (
                        <div className="cursor-default">
                          {tModules('schoolAdmin.timeTable.N/A')}
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {selectedSlot && (
        <Dialog fullWidth open={!!selectedSlot} onClose={handleCloseDialog}>
          <DialogTitle className="flex justify-center">
            <div className="text-2xl font-bold">
              {tModules('schoolAdmin.timeTable.timeTableDetails')}
            </div>
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="flex flex-col">
                {/* <div className="flex flex-col items-start"> */}
                <div>
                  <span className="font-semibold">
                    {' '}
                    {tModules('schoolAdmin.timeTable.class')}:
                  </span>{' '}
                  {selectedSlot.slot.clasa?.codClasa}
                </div>
                <div>
                  <span className="font-semibold">
                    {' '}
                    {tModules('schoolAdmin.timeTable.day')}:
                  </span>{' '}
                  {selectedSlot.day}
                </div>
                <div>
                  <span className="font-semibold">
                    {' '}
                    {tModules('schoolAdmin.timeTable.timeSlot')}:
                  </span>{' '}
                  {selectedSlot.slot.oraStart +
                    ' - ' +
                    selectedSlot.slot.oraSfarsit}
                </div>
                {selectedSlot.date && (
                  <div>
                    <span className="font-semibold">
                      {' '}
                      {tModules('schoolAdmin.timeTable.date')}:
                    </span>{' '}
                    {selectedSlot.date}
                  </div>
                )}
                {selectedSlot.slot.profesor?.numeComplet && (
                  <div>
                    <div>
                      <span className="font-semibold">
                        {' '}
                        {tModules('schoolAdmin.timeTable.professor')}:
                      </span>{' '}
                      {selectedSlot.slot.profesor?.numeComplet}
                    </div>
                    <div>
                      <span className="font-semibold">
                        {' '}
                        {tModules('schoolAdmin.timeTable.subject')}:
                      </span>{' '}
                      {selectedSlot.slot.materie?.tipMaterie}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>
              {tCommon('inputs.closeButton')}
            </Button>
            {userRole === UserRoles.ROLE_SCHOOL_ADMIN && (
              <Button
                onClick={() =>
                  redirectToCreateOrEdit(
                    selectedSlot?.slot,
                    selectedSlot.day as Days
                  )
                }
              >
                {tCommon('inputs.editButton')}
              </Button>
            )}
            {userRole === UserRoles.ROLE_SCHOOL_ADMIN &&
              hasMultipleEntriesWithOrarIri() && (
                <Button
                  onClick={() =>
                    navigate('/swap-timetable', {
                      state: {
                        moduleId: selectedModule.id,
                        classId: selectedClass.id,
                        classCode: selectedClass.classCode,
                        orarId: selectedSlot.slot.orarId,
                        orarIri: selectedSlot.slot.orarIri,
                        orarDetails:
                          selectedSlot.slot.oraStart +
                          ' - ' +
                          selectedSlot.slot.oraSfarsit +
                          ' | ' +
                          selectedSlot.day +
                          ' - ' +
                          selectedSlot.slot.materie?.tipMaterie +
                          ' - ' +
                          selectedSlot.slot.profesor?.numeComplet,
                      },
                    })
                  }
                >
                  {tModules('schoolAdmin.timeTable.swapWithTimeTable')}
                </Button>
              )}
            {userRole === UserRoles.ROLE_SCHOOL_ADMIN && (
              <Button onClick={() => setIsDeleteDialogOpen(true)}>
                {tModules('schoolAdmin.timeTable.deleteTimeTable')}
              </Button>
            )}
          </DialogActions>
          <Dialog open={isDeleteDialogOpen} onClose={handleDeleteDialogClose}>
            <DialogTitle>
              {tModules('schoolAdmin.timeTable.deleteConfirmTitle')}
            </DialogTitle>
            <DialogContent>
              {tModules('schoolAdmin.timeTable.deleteConfirmBody')}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmDelete}>
                {' '}
                {tCommon('basic.yes')}
              </Button>
              <Button onClick={handleDeleteDialogClose}>
                {tCommon('basic.no')}
              </Button>
            </DialogActions>
          </Dialog>
        </Dialog>
      )}
    </div>
  );
};
